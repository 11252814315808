import React from 'react';

import { instrumentActionTypes } from './actions'
import moment from 'moment'


export function instruments(state = {
    isFetching: false,
    key: null,
    data: [],
}, action) {

    switch (action.type) {
        case instrumentActionTypes.REQUEST:
            
            state = { ...state }
            state.isFetching = true
            return state

        case instrumentActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data.results

            return state

        default:
            return state
    }

}