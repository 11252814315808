import React from 'react';
import {QRCodeSVG} from 'qrcode.react';
import GuitarpartyModal from '../modal'
import { openModal, closeModal } from '../../api/modals/actions'


import { store } from '../../store'
import { connectWithStore, stepIndicator } from '../../utils'


class ModalQR extends React.Component {
    constructor() {
        super();
    }

    render() {
        return (

            <GuitarpartyModal extraClasses="modal-qr" modalKey="modal-qr" {...this.props}>
                <div className="header-container">
                    {/* <h2>{gettext('Scan code')}</h2> */}
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal("modal-qr"))}>
                        <div className="close-icon">
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                </div>
                <div className="qr-container">
                    <div className="qr-inner-container">
                    { this.props.songbook && this.props.songbook.data && 
                        <QRCodeSVG 
                            value={this.props.songbook.data.play_url} 
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        />
                    }
                    </div>
                    <div className="figure-text">{gettext("Scan this QR code on another device to open")}</div>
                </div>
            </GuitarpartyModal>
        )
    }
}

// export default withModal(ModalSongbook);

function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
        song: {},
        songbook: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user
    f.song = (state.song === undefined) ? [] : state.song
    f.songbook = (state.songbook === undefined) ? [] : state.songbook

    return f;
}

export default connectWithStore(store, ModalQR, mapStateToProps);