import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'

import { composeWithDevTools } from 'redux-devtools-extension';
import TokenStorage from './storages/token-storage'
import { userLoginSuccess, userActionTypes } from './api/user/actions'

import { song } from './api/song/reducers'
import { search } from './api/search/reducers'
import { instruments } from './api/instrument/reducers'
import { profile } from './api/profile/reducers'
import { songbooks, songbook } from './api/songbooks/reducers'
import { favourites } from './api/favourite/reducers'
import { user } from './api/user/reducers'
import { collectionRelation } from './api/collection-relation/reducers'
import { songSubmition } from './api/submit-song/reducers'
import { songLang } from './api/song-languages/reducers'
import { passwordUpdate } from './api/password/reducers'
import { songbookPDF } from './api/songbook-pdf/reducers'
import { stepRenderer } from './api/step-renderer/reducers'
import { subscription } from './api/subscription/reducers'
import { modals } from './api/modals/reducers'
import { plans } from './api/plans/reducers'
import { wishlist } from './api/wishlist/reducers'
import { toplist } from './api/toplist/reducers'
import { artist } from './api/artist/reducers'
import { chords } from './api/chords/reducers'
import { relatedSongs } from './api/related-songs/reducers'
import { lessons } from './api/lessons/reducers'

const loggerMiddleware = createLogger()

const reducers = combineReducers({
    song,
    songbooks,
    songbook,
    songbookPDF,
    favourites,
    instruments,
    profile,
    user,
    search,
    collectionRelation,
    songSubmition,
    songLang,
    passwordUpdate,
    stepRenderer,
    subscription,
    modals,
    plans,
    wishlist,
    toplist,
    artist,
    chords,
    relatedSongs,
    lessons,
})


function redirectMiddleware({getState, dispatch}) {
    return (next) => (action) => {
        if (typeof action === 'object' && action.hasOwnProperty('type')) {
            
            let url = action.type === userActionTypes.AUTH_LOGOUT ? '/' : window.location.pathname;
            if (action.type === userActionTypes.AUTH_LOGOUT || (action.type === userActionTypes.AUTH_LOGIN_SUCCESS && action.initiatedByUser)) {
                url = "/"
                next(action);
                window.location = url
            }
        }

        return next(action);
    };
}

let middleware = null
if(process.env.NODE_ENV === 'production') {
    middleware = applyMiddleware(
        redirectMiddleware,
        thunkMiddleware,
    )
} else {
    middleware = composeWithDevTools(applyMiddleware(
        redirectMiddleware,
        thunkMiddleware,
        loggerMiddleware
    ))
}

export const store = createStore(reducers, middleware)


const token = TokenStorage.getItem('token');
if (token !== null) {
    store.dispatch(userLoginSuccess(token));
}