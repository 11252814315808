import React from "react";
import { connect } from 'react-redux'
import { initStepper } from "../../api/step-renderer/actions";
import { SvgSoongbookPdfA4Landscape, SvgSoongbookPdfA4Portrait, SvgSoongbookPdfA5Portrait } from '../svg'
import { setPdfoption } from "../../api/songbook-pdf/actions";


class PageSetup extends React.Component {
    constructor(props) {
        super(props)
        this.selectSongsPerPage = this.selectSongsPerPage.bind(this)
        this.selectType = this.selectType.bind(this)
        this.getSetupElement = this.getSetupElement.bind(this)
        this.components = {
          'a4Land': SvgSoongbookPdfA4Landscape,
          'a4Port': SvgSoongbookPdfA4Portrait,
          'a5Port': SvgSoongbookPdfA5Portrait
        }
    }

    selectSongsPerPage(num){
      this.props.dispatch(setPdfoption("songsPerPage", num))
    }

    selectType(type){
      this.props.dispatch(setPdfoption("selectedLayout", type))
      if(type === 'a5Port'){
        this.props.dispatch(setPdfoption("songsPerPage", 1))
      }
    }

    getSetupElement(title, type){
      let Component = this.components[type]
      return (
        <div className="option-container">
            <p><b>{ title }</b></p>
            <div onClick={() => this.selectType(type)} className={ this.props.songbookPDF.selectedLayout === type ? 'active svg-container' : 'svg-container'}>
              <Component></Component>
            </div>
        </div>
      )
    }
    
    render() {
      return (
        <>
        <div className="page-setup">
          <h4 className="subtitle"> {gettext('Choose a layout')}</h4>
          <div className="content-wrapper">
            <div className="col-16">
                <div className="row mx-0 justify-content-between">
                  { this.getSetupElement('A4', 'a4Port') }
                  { this.getSetupElement('A4', 'a4Land') }
                  { this.getSetupElement('A5', 'a5Port') }
                </div>
            </div>
          </div>
          <h4 className="subtitle"> {gettext('Choose songs per page')}</h4>
          <div className="content-wrapper">
            <div className="col-16">
              <div className="radio-container">
                <div className="radio">
                  <input id="opp" type="radio" value={1}
                    checked={this.props.songbookPDF.songsPerPage === 1}
                    onChange={(e) => this.selectSongsPerPage(1)}/>
                  <label htmlFor="opp">{gettext('One song per page')}</label>
                </div>
                { this.props.songbookPDF.selectedLayout !== 'a5Port' && 
                  <div className="radio">
                    <input id="mpp" type="radio" value={2}
                      checked={this.props.songbookPDF.songsPerPage === 2}
                      onChange={(e) => this.selectSongsPerPage(2)}/>
                    <label htmlFor="mpp">{gettext('Many songs per page')}</label>
                  </div>
                }
              </div>
            </div>
          </div>
        </div> 
        </>
        )
    }
}

function mapStateToProps(state) {
  var f = {
    dispatch: {},
    songbookPDF: {},

  }
  f.dispatch = (state.dispatch === undefined) ? [] : state.dispatch
  f.songbookPDF = (state.songbookPDF === undefined) ? [] : state.songbookPDF
  return f;
}

export default connect(mapStateToProps)(PageSetup);
