import React from 'react';
import { deleteChordPreference, fetchChords, selectChord } from '../../api/chords/actions';
import { closeModal, openModal } from '../../api/modals/actions';
import { FONT_SIZES } from '../../constants';
import { store } from '../../store';
import { connectWithStore } from '../../utils';
import ModalChordChooser from '../modal-chordchooser';


const trans = [
    gettext('Reset chord')
]

class Chord extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            resetDivVisible: false,
        }

        this.getChords = this.getChords.bind(this)
        this.resetDivIsShown = this.resetDivIsShown.bind(this)
        this.resetChord = this.resetChord.bind(this)
        this.updateChordVariations = this.updateChordVariations.bind(this)
    }

    updateChordVariations(song, chord, name) {
        if (this.props.user && this.props.user.hasSubscription) {
            this.props.dispatch(selectChord(song, chord, name, this.props.song.data.transposed_to))
            this.props.dispatch(closeModal(`modal-chordchooser-${this.props.chord.name}`));
        }

    }

    getChords(chordName, instrId) {
        if (this.props.user && this.props.user.hasSubscription) {
            this.props.dispatch(fetchChords(chordName, instrId));
            this.props.dispatch(openModal(`modal-chordchooser-${chordName}`));
        }
    }

    isChordChosen(chords, chordId) {
        let selectedChord = false
        if (!chords.isChordPrefFetching && chords.chordPref && chords.chordPref.some(e => e.chord === chordId)) {
            selectedChord = true
        }
        return selectedChord;
    }

    getExtraClass(chords, chordId) {
        let extra_class = ''
        if (this.isChordChosen(chords, chordId)) {
            extra_class = 'pref-chosen'
        }
        return extra_class
    }

    resetDivIsShown(bool) {
        this.setState({
            resetDivVisible: bool,
        })
    }

    resetChord(songId, chordId) {
        let chordPref = this.props.chords.chordPref.find(c => c.chord === chordId)
        this.props.dispatch(deleteChordPreference(chordPref.id, songId, this.props.song.data.transposed_to))
    }



    render() {

        const { instrument, chord, instrId, chords, chordColor, songId } = this.props

        return (
            <>
                { chord ?
                <>
                <React.Fragment>
                    <div
                        onMouseEnter={() => this.resetDivIsShown(true)}
                        onMouseLeave={() => this.resetDivIsShown(false)}
                        className={`svg-chord-container ${instrument} ${FONT_SIZES[this.props.fontSize]} ${this.getExtraClass(chords, chord.id)}`}>
                        <div onClick={() => this.getChords(chord.name, instrId)}>
                            <text id="title">{chord.name}</text>
                            <div className="inner-container" dangerouslySetInnerHTML={{ __html: chord.svg_code }} />
                        </div>
                        {this.isChordChosen(chords, chord.id) && <div onClick={() => this.resetChord(songId, chord.id)} className={`set-default-container ${this.state.resetDivVisible ? "visible" : "hidden"}`}><span>{gettext('Reset chord')}</span></div>}
                    </div>
                </React.Fragment>
                <ModalChordChooser chords={chords} instrument={instrument} chordName={chord.name} chordColor={chordColor} updateChordVariations={this.updateChordVariations} user={this.props.user}/>
                </>
                : 
                <div style={{'text-align':'center'}}>{gettext('Could not find chord image')}</div>
    }
            </>
        )
    }
}

Chord.defaultProps = {
    fontSize: 2,
    instrument: ''
}

function mapStateToProps(state) {

    var f = {
        chords: {},
        user: {},
        song: {},
    }
    f.chords = (state.chords === undefined) ? [] : state.chords
    f.user = (state.user === undefined) ? [] : state.user
    f.song = (state.song === undefined) ? [] : state.song
    return f;
}

export default connectWithStore(store, Chord, mapStateToProps);
