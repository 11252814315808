import React from 'react';

import { collectionRelationTypes } from './actions'
import moment from 'moment'


export function collectionRelation(state = {
    isFetching: false,
    key: null,
    data: {},
}, action) {

    switch (action.type) {
        case collectionRelationTypes.CREATED:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        case collectionRelationTypes.DELETED:

            state = { ...state }

            state.isFetching = false
            state.data = action.data
            state.status = action.status

            return state

        case collectionRelationTypes.RELATIONS:

            state = { ...state }

            state.isFetching = false
            state.data = action.data
            state.status = action.status

            return state

        default:
            return state
    }

}