import { actionCreator, createTypes } from 'redux-action-creator';

export const modalActionTypes = createTypes(['OPEN', 'CLOSE', 'SET_SCREEN'], 'MODALS');


export const openModal = (name, message, data) => {
    return {
        type: modalActionTypes.OPEN,
        name: name,
        message: message,
        data: data
    };
};

export const closeModal = (name) => {
    return {
        type: modalActionTypes.CLOSE,
        name: name,
    };
};

export const setScreen = (screen) => {
    return {
        type: modalActionTypes.SET_SCREEN,
        screen: screen,
    };
};


