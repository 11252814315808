import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, addAuthorizeHeader, getCookie } from '../../utils'
import { search } from '../search/reducers';
import { songbook } from './reducers';

export const songbookActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'REQUEST_DETAIL', 'RECEIVE_DETAIL', 'REQUEST_UPDATE', 'RECEIVE_UPDATE', 'ERROR_UPDATE', 'SET_ACTIVE_SONGBOOKS'], 'SONGBOOKDATA');


export const requestSongbooks = () => {
    return {
        type: songbookActionTypes.REQUEST,
    };
};

export const receiveSongbooks = (response, songbookId, onlyPublic, searchQuery) => {
    return {
        type: songbookActionTypes.RECEIVE,
        data: response,
        onlyPublic: onlyPublic,
        songbookId: songbookId,
        searchQuery: searchQuery, 
    };
};

export const receiveSongbooksError = () => {
    return {
        type: songbookActionTypes.ERROR,
    };
};

export const requestSongbookDetail = () => {
    return {
        type: songbookActionTypes.REQUEST_DETAIL,
    };
};

export const receiveSongbookDetail = (response) => {
    return {
        type: songbookActionTypes.RECEIVE_DETAIL,
        data: response,
    };
};

export const requestUpdateSongList = () => {
    return {
        type: songbookActionTypes.REQUEST_UPDATE,
    };
};

export const receiveUpdateSongList = (response) => {
    return {
        type: songbookActionTypes.RECEIVE_UPDATE,
        data: response,
    };
};

export const receiveUpdateSongListError = () => {
    return {
        type: songbookActionTypes.ERROR_UPDATE,
    };
};


export const fetchSongbooks = (songbookId, onlyPublic=false, searchQuery, offset) => {
    return dispatch => {

        dispatch(requestSongbooks());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        })

        let url = `/api/v3/core/songbooks/${songbookId ? songbookId+`/` : ""}`

        let queryParams = []
        if (searchQuery) {
            queryParams['search'] = searchQuery
        }
        if (onlyPublic){
            queryParams['public'] = true
        }
        if (offset) {
            queryParams['limit'] = 100
            queryParams['offset'] = offset
        }
        Object.keys(queryParams).map((item, i) => {
            if (i == 0) {
                url+='?'
            }
            if (i != 0) {
                url+='&'
            }
            url+=`${item}=${queryParams[item]}`
            return url
        })

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveSongbooks(response, songbookId, onlyPublic, searchQuery));
            } catch (e) {
                dispatch(receiveSongbooksError(response))
            }


        })
        .catch(handleNetworkError);
    };
};


export const fetchSongbookDetail = (id, onlyPublic=true) => {
    return dispatch => {

        dispatch(requestSongbookDetail());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        })

        let url = ""
        if (onlyPublic) {
            url = `/api/v3/core/songbooks/${id}/?public=1`
        } else {
            url = `/api/v3/core/songbooks/${id}`
        }

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveSongbookDetail(response));
            } catch (e) {
                dispatch(receiveSongbooksError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const createSongbook = (title) =>  {
    return dispatch => {


        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/songbooks/`

        let data = {}
        data['title'] = title

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            dispatch(fetchSongbooks());
        })
        .catch(handleNetworkError);
    };
};

export const modifySongbook = (songbook, params={}) => {
    return dispatch => {
        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })
        
        let url = `/api/v3/core/songbooks/${songbook}/`
        
        dispatch(requestUpdateSongList());
        return fetch(url, {
            method: 'PATCH',
            headers: headers,
            body: JSON.stringify({params}),
        })
            .then(parseJSON)
            .then(response => {
                try {
                    dispatch(receiveUpdateSongList(response));
                } catch (e) {
                    dispatch(receiveUpdateSongListError(response))
                }
            })
            .catch(handleNetworkError);
    }
};


export const setSongbookPublic = (songbook_id, isPublic) => {
    return modifySongbook(songbook_id, {is_public: isPublic})
}


export const copySongbook = (id, onlyPublic) => {
    return dispatch => {

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/songbooks/${id}/copy/`

        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: ''
        })
        .then(parseJSON)
        .then(response => {

            dispatch(fetchSongbooks(null, onlyPublic));


        })
        .catch(handleNetworkError);
    };
};


export const deleteSongbook = (id) => {
    return dispatch => {

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/songbooks/${id}/`

        return fetch(url, {
            method: 'DELETE',
            headers: headers,
        })
        .then(response => {

            dispatch(fetchSongbooks());


        })
        .catch(handleNetworkError);
    };
};

export const setActiveSongbook = (songbookType) => {
    return {
        type: songbookActionTypes.SET_ACTIVE_SONGBOOKS,
        songbookType: songbookType,
    };
};