import React from "react";
import ReactDOM from "react-dom";
import { fetchWishlist } from '../../api/wishlist/actions'
import { connect } from 'react-redux'
import Wishlist from '../../components/wishlist'


class WishlistContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.dispatch(fetchWishlist());
  }



  render() {
    return(<Wishlist dispatch={this.props.dispatch} wishlist={this.props.wishlist} store={this.props.store}/>)
  }
}

WishlistContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    wishlist:{},
  }

  f.wishlist = (state.wishlist === undefined) ? [] : state.wishlist
  return f;
}

export default connect(mapStateToProps)(WishlistContainer);