import React from 'react';
import { connectWithStore } from '../../utils'
import { store } from '../../store'

import { Svg1ColIcon, Svg2ColIcon } from '../svg'

import {openModal} from '../../api/modals/actions'

class SongLayout extends React.Component {

    constructor(props, context) {
        super(props, context)
    }

    handleOptionChange(changeEvent) {
        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "scroll"))
        }
        else {
            this.props.onChange(this.props.songLayout === '1col' ? '2col' : '1col')
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.songLayout !== prevProps.songLayout) {
            this.setState({songLayout: this.props.songLayout})
        }
    }

    render() {
        return (
            <button className="btn btn-icon" onClick={this.handleOptionChange.bind(this)}>
                {this.props.songLayout === '1col' ?
                    <div className="svg-container"><Svg1ColIcon /></div>
                : 
                    <div className="svg-container"><Svg2ColIcon /></div>
                }
                <p className="d-none d-md-block">{this.props.songLayout === '1col' ? gettext("1 column") : gettext("2 columns")}</p>
            </button>
        ) 
    }
}

SongLayout.defaultProps = {
    songLayout: false
}

  export default SongLayout;
