import React from "react";
import { connect } from 'react-redux'
import { fetchArtist } from '../../api/artist/actions'
import { fetchProfile } from '../../api/profile/actions'
import SongList from '../../components/song-list'


class ArtistContainer extends React.Component {
  constructor(props) {
    super(props)


  }

  componentDidMount() {
    Promise.resolve(this.props.dispatch(fetchProfile()))
      .then(() => {
        this.props.dispatch(fetchArtist(this.props.id))
      })

  }




  render() {
    const artist = this.props.artist.data

    return (
      <>
        <div className="row justify-content-center artist-info">
          {artist && artist.artist_img && <div className="img col-16 col-lg-8 px-0"><img src={artist.artist_img} /></div>}
          <div className="text p-sm-0 col-16 col-sm-14 col-md-12 col-lg-8">
            <h1>{artist.name}</h1>
            {artist && artist.bio_short && <div className="bio" dangerouslySetInnerHTML={{ __html: artist.bio_short ? artist.bio_short.replace(/(?:\r\n|\r|\n)/g, '<br>') : "" }}></div>}
          </div>
        </div>
        <div className="row justify-content-center pt-5">
          <div className="col-16 col-sm-14 col-md-12 col-lg-8">
            <div className="col-16 p-0 favourites-div">
              {artist && artist.songs && artist.songs.length > 0 && <SongList list={artist.songs} allFavourites={false} showExcerpt={false} showFilterInfo={false}/>}
            </div>
          </div>
        </div>
      </>
    )
  }
}

ArtistContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    artist: {},
    user: {},
  }
  f.artist = (state.artist === undefined) ? {} : state.artist
  f.user = (state.user === undefined) ? {} : state.user
  return f;
}

export default connect(mapStateToProps)(ArtistContainer);