import React from "react";
import { connect } from 'react-redux';
import { fetchUserChordPreference } from "../../api/chords/actions";
import { fetchInstruments } from '../../api/instrument/actions';
import { fetchProfile } from '../../api/profile/actions';
import { fetchSong } from '../../api/song/actions';
import { fetchSongbookDetail, modifySongbook } from "../../api/songbooks/actions";
import Song from '../../components/song';


class SongbookPlayContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      songIndex: 0
    }

    this.nextSong = this.nextSong.bind(this)
    this.prevSong = this.prevSong.bind(this)
    this.setSong = this.setSong.bind(this)
    this.completeUpdate = this.completeUpdate.bind(this)
    
    this.channel = null
  }


  completeUpdate() {
    Promise.resolve(this.props.dispatch(fetchSongbookDetail(this.props.id, this.props.public)))
      .then(() => {
        Promise.resolve(this.props.dispatch(fetchProfile()))
          .then(() => {
            this.props.dispatch(fetchInstruments())
            let idToFetch = this.props.songbook.data.songs[this.state.songIndex].song.id
            if (this.props.songbook.data.current_song) {
              idToFetch = this.props.songbook.data.current_song
              this.setState({songIndex: this.props.songbook.data.songs.findIndex(f => f.song.id === this.props.songbook.data.current_song)})
            }
            this.props.dispatch(fetchSong(idToFetch, this.props.songbook.data.songs[this.state.songIndex].key, this.props.profile.instrument_preference))
            this.props.dispatch(fetchUserChordPreference(this.props.songbook.data.songs[this.state.songIndex].song.id))
          })
      })
  }

  componentDidMount() {
    this.completeUpdate();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.songIndex !== this.state.songIndex) {
      const idToFetch = this.props.songbook.data.songs[this.state.songIndex].song.id;
      Promise.resolve(this.props.dispatch(fetchSong(idToFetch, this.props.songbook.data.songs[this.state.songIndex].key, this.props.profile.instrument_preference)))
      .then(() => {
        if (this.props.user.username === this.props.songbook.data.owner) {
          this.props.dispatch(modifySongbook(this.props.songbook.data.id, {'current_song': idToFetch}))
        }
      })
    }

    if (this.props.songbook.data && JSON.stringify(prevProps.songbook.data) !== JSON.stringify(this.props.songbook.data)) {
        this.channel = window.pusherClient.subscribe(`presence-play-${this.props.songbook.data.id}`);
        this.channel.bind('client-song-change', (data) => {
          this.setState({
            songIndex: data.songIndex
          });
        });
        this.channel.bind('song-added', (data) => {
          this.completeUpdate();
        });
        this.channel.bind('song-removed', (data) => {
          this.completeUpdate();
        });
    }
  }

  nextSong() {
    let idx = this.state.songIndex + 1
    if (idx >= this.props.songbook.data.songs.length) {
      idx = 0
    }
    this.setState({
      songIndex: idx
    })
    if (this.props.user.username === this.props.songbook.data.owner) {
      this.channel.trigger('client-song-change', {'songIndex': idx})
    }
    document.getElementById("tag-container").scrollIntoView();
  }

  prevSong() {
    let idx = this.state.songIndex - 1
    if (idx < 0) {
      idx = this.props.songbook.data.songs.length - 1
    }
    this.setState({
      songIndex: idx
    })
    if (this.props.user.username === this.props.songbook.data.owner) {
      this.channel.trigger('client-song-change', {'songIndex': idx})
    }

    window.scrollTo(0,0)
  }

  setSong(idx) {
    this.setState({
      songIndex: idx
    })
    if (this.props.user.username === this.props.songbook.data.owner) {
      this.channel.trigger('client-song-change', {'songIndex': idx})
    }
  }

  render() {
    const { song, songbook, songbooks, favourites, instruments, profile } = this.props
    let collectionRelation = null

    try {
      collectionRelation = this.props.songbook.data.songs[this.state.songIndex]
    } catch (e) {void(0)}

    return (
      <Song song={song.data} collectionRelation={collectionRelation} playSongbook={songbook.data} setSong={this.setSong} nextSong={this.nextSong} prevSong={this.prevSong} songbooks={songbooks.privateSongbooks} favourites={favourites.data} {...profile.ui_preferences} instrument_preference={profile.ui_preferences.instrument_preference} instruments={instruments.data} isFetching={song.isFetching} user={this.props.user} dispatch={this.props.dispatch} songIndex={this.state.songIndex} />
    )
  }
}

SongbookPlayContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    song: {},
    songbook: {},
    songbooks: {},
    favourites: {},
    instruments: [],
    profile: {},
    user: {},
  }
  f.song = (state.song === undefined) ? {} : state.song
  f.songbook = (state.songbook === undefined) ? [] : state.songbook
  f.songbooks = (state.songbooks === undefined) ? [] : state.songbooks
  f.instruments = (state.instruments === undefined) ? {} : state.instruments
  f.profile = (state.profile === undefined) ? {} : state.profile
  f.favourites = (state.favourites === undefined) ? [] : state.favourites
  f.user = (state.user === undefined) ? [] : state.user
  return f;
}

export default connect(mapStateToProps)(SongbookPlayContainer);