import { userActionTypes, userInfoTypes } from './actions'

import TokenStorage from '../../storages/token-storage'
import jwtDecode from 'jwt-decode';
import { modalActionTypes } from '../modals/actions';

const _initalState = {
    isFetching: false,
    token: null,
    username: null,
    isAuthenticated: false,
    isAuthenticating: false,
    statusText: null,
    tokenExpires: null,
    failed_auth: false,
    hasSubscription: false,
    subscription: null
}

export function user(state=_initalState, action) {
    switch (action.type) {
        case userActionTypes.LOGOUT:
            state = {
                ...state,
                isAuthenticated: false,
                failed_auth: false,
                id: undefined,
                token: null,
            }
            return state
        case userActionTypes.LOGIN_REQUEST:
            state = {
                ...state,
                isAuthenticating: true,
                failed_auth: false,
            }
            return state
        case userActionTypes.LOGIN_FAILURE:
            state = {
                ...state,
                isAuthenticated: false,
                isAuthenticating: false,
                failed_auth: true,
                failed_auth_reason: action.data.error
            }
            return state
        case userActionTypes.LOGIN_SUCCESS:
            var userData = jwtDecode(action.data)
            if(userData.exp*1000 < Date.now()){
                TokenStorage.removeItem('token')
                state = {
                    ...state,
                    isAuthenticated: false,
                    token: null,
                }
            } else {
                state = {
                    ...state,
                }
                
                state = {
                    ...state,
                    isAuthenticating: false,
                    isAuthenticated: true,
                    token: action.data,
                    username: userData.username,
                    id: userData.user_id,
                    email: userData.email,
                    tokenExpires: userData.exp,
                    // firstName: userData.first_name,
                    // lastName: userData.last_name,
                    // name: `${userData.first_name} ${userData.last_name}`,
                }
            }
            return state
        case userActionTypes.LOGIN_REFRESH_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                token: null,
            }

        case userInfoTypes.REQUEST_USER_REGISTER:
            return {
                ...state,
                isRegistering: true,
                registrationError: null,
            }
        case userInfoTypes.RECEIVE_USER_REGISTER:
            return {
                ...state,
                isRegistering: false,
                registrationError: null,
                registerData: action.data
            }
        case userInfoTypes.RECEIVE_USER_REGISTER_ERROR:
            
            state = {...state,
                isRegistering: false,
                registrationError: action.data
            }
            return state

        case userInfoTypes.REQUEST_USER_RESET_PASSWORD:
            return {
                ...state,
                isResettingPassword: true,
                resetError: null,
            }
        case userInfoTypes.RECEIVE_USER_RESET_PASSWORD:
            return {
                ...state,
                isResettingPassword: false,
                resetError: null,
                resetData: action.data
            }
        case modalActionTypes.CLOSE:
            return {
                ...state,
                isResettingPassword: false,
                resetError: null,
                resetData: null
            }
        case userInfoTypes.RECEIVE_USER_RESET_PASSWORD_ERROR:
            
            state = {...state,
                isRegistering: false
            }
            state.resetError = action.data
            return state

        case userInfoTypes.REQUEST_USER_SET_NEW_PASSWORD:
            return {
                ...state,
                isSettingPassword: true,
                setError: null,
            }
        case userInfoTypes.RECEIVE_USER_SET_NEW_PASSWORD:
            return {
                ...state,
                isSettingPassword: false,
            }
        case userInfoTypes.RECEIVE_USER_SET_NEW_PASSWORD_ERROR:
            
            state = {...state,
                isRegistering: false
            }
            state.setPassError = action.data
            return state

        case userInfoTypes.REQUEST:
            state = {...state, isFetching: true}
            return state

        case userInfoTypes.RECEIVE:
            state = {...state, isFetching: false}
            Object.assign(state, action.data)
            state = {
                ...state,
                'song_set_length': action.data.song_set.length,
                'name': `${action.data.first_name} ${action.data.last_name}`,
                hasSubscription: action.data.has_subscription,
                trialAllowed: action.data.trial_allowed,
                subscription: action.data.subscription,
                isAuthenticated: action.data.authenticated,
                email: action.data.email,
                id: action.data.id,
            }
            return state

        default:
            return state

        case userInfoTypes.REQUEST_USER_CONTRIB_SONGS:
            state = {...state, isFetchingContribSongs: true}
            return state

        case userInfoTypes.RECEIVE_USER_CONTRIB_SONGS:
            state = {...state, isFetchingContribSongs: false}
            state = {
                ...state,
                contribSongs: action.data
            }
            return state

        case userInfoTypes.REQUEST_DELETE_SCHEDULED:
            state = {...state, isFetchingDeleted: true}
            return state

        case userInfoTypes.RECEIVE_DELETE_SCHEDULED:
            state = {...state, isFetchingDeleted: false}
            state = {
                ...state,
                user_delete: action.data
            }
            return state
    }
}