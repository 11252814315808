import React from 'react';
import { connectWithStore } from '../../utils'
import { store } from '../../store'
import { SvgGripIcon, SvgGripIconOff, SvgGripIconKeysOnly } from '../svg'


class SongShowChords extends React.Component {

    constructor(props, context) {
        super(props, context)
    }

    handleOptionChange(changeEvent) {
        let tmp = this.props.showChords
        if(!!this.props.showChords){    // eslint-disable-line no-extra-boolean-cast
            tmp = tmp - 0.5
        } else {
            tmp = 1
        }
        this.props.onChange(tmp)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.showChords !== prevProps.showChords) {
            this.setState({showChords: this.props.showChords})
        }
    }

    getSvgFromValue(chordVal){
        if(chordVal == 0.5){ 
            return <SvgGripIconKeysOnly/>
        } else if(chordVal){
            return <SvgGripIcon />
        } else {
            return <SvgGripIconOff/>
        }
    }

    render() {
        return (
            <button className="btn btn-icon" onClick={this.handleOptionChange.bind(this)}>
                <div className="svg-container">{this.getSvgFromValue(this.props.showChords)}</div>
                <p className="d-none d-md-block">{gettext("Chords")}</p>
            </button>
        ) 
    }
}

SongShowChords.defaultProps = {
    showChords: false
}

  export default SongShowChords;
