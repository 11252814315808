import fetch from 'isomorphic-fetch'
import { handleNetworkError, parseJSON, addAuthorizeHeader, getCookie } from '../../utils'

import { createTypes } from 'redux-action-creator';
import { fetchProfile } from '../profile/actions';

export const passwordActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'PASSWORD')

export const updatePasswordRequest = () => {
    return {
        type: passwordActionTypes.REQUEST,
    };
};

export const passwordUpdated = (response) => {
    return {
        type: passwordActionTypes.RECEIVE,
        data: response,
    };
};

export const passwordUpdateError = () => {
    return {
        type: passwordActionTypes.ERROR,
    };
};


export const updatePassword = (oldPw, newPw, authenticated) => {
    return dispatch => {

        dispatch(updatePasswordRequest());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/user/set_password/`


        return fetch(url, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({'old_password': oldPw, 'new_password': newPw, 'authenticated': authenticated})
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(passwordUpdated(response));
            } catch (e) {
                dispatch(passwordUpdateError({}))
            }
        })
        .catch(handleNetworkError);
    };
};