import React from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import ModalPaywall from "../../components/modal-paywall";
import ModalLogin from "../../components/modal-login";
import ModalSubscription from "../../components/modal-subscription";
import ModalAddEmail from "../../components/modal-add-email";
import ModalQR from "../../components/modal-qr";

class Modals extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { user } = this.props
    return (
      <>
        <ModalPaywall showButton={false} />
        <ModalLogin showButton={false} />
        <ModalSubscription showButton={false} />
        <ModalAddEmail showButton={false} />
        <ModalQR showButton={false}></ModalQR>
      </>
    )
  }
}

Modals.defaultProps = {
}



export default Modals;