import React from 'react'
import ReactDOM from 'react-dom/client'
import SongbookPlayContainer from './songbookplay'
import { store } from '../../store'
import { Provider } from 'react-redux'

function Wrapper(props) {
  return (
      <Provider store={store}>
          <SongbookPlayContainer {...props}/>
      </Provider>
  )
}

function initSongbookPlayContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-songbookplaycontainer]');
  elements.forEach(initSongbookPlayContainer);
});

