import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, addAuthorizeHeader, getCookie } from '../../utils'
import { songbook } from './reducers';

export const lessonActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'LESSONS');


export const requestLessons = () => {
    return {
        type: lessonActionTypes.REQUEST,
    };
};

export const receiveLessons = (response, lessonId) => {
    return {
        type: lessonActionTypes.RECEIVE,
        data: response,
        lessonId: lessonId,
    };
};

export const receiveLessonsError = () => {
    return {
        type: lessonActionTypes.ERROR,
    };
};



export const fetchLessons = () => {
    return dispatch => {

        dispatch(requestLessons());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        })
        // let url = ""
        // if (lessonId) {
        //     url = `/api/v3/core/lessons/${lessonId}/`
        // } else {
        //     url = "/api/v3/core/lessons/"
        // }
        let url = "/api/v3/core/lessons/"
        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveLessons(response));
            } catch (e) {
                dispatch(receiveLessonsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
