
import React from "react";
import { Switch } from 'react-router'

import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Provider } from 'react-redux'
import SongbookContainer from './songbooks'
import SongbookDetailContainer from './songbook_details'
import { store } from '../../store'


class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Switch>
              <Route exact path="/songbooks/" component={props => <SongbookContainer {...props}/>} />
              <Route exact path="/songbooks/:code/" component={props => <SongbookDetailContainer {...props}/>} />
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}

export default Root