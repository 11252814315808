import { SvgArrowDownGray, SvgMinusBlue, SvgPlusBlue, SvgStopBlue } from '../svg'

import React from 'react';
import ReactSlider from 'react-slider'
import { SvgArrowFillDown } from '../svg'
import {openModal} from '../../api/modals/actions'

class Autoscroll extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            speed: 0,
            subMenuOpen: false,
            scrolling: false
        }
        this.scroll_timer = null;
        this.wrapperRef = React.createRef();
        this.btnRef = React.createRef();
        this.handleClickOutsideAutoscroll = this.handleClickOutsideAutoscroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideAutoscroll);
        if(this.props.defaultScroll){
            this.setState({
                speed: this.props.defaultScroll
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.defaultScroll !== prevProps.defaultScroll){
            if(!this.props.defaultScroll){
                if(this.state.scrolling){
                    this.toggleStop()
                }
                this.setState({
                    speed: 0,
                    scrolling: false
                })
            } else {
                this.setState({
                    speed: this.props.defaultScroll
                })
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideAutoscroll);
    }

    handleClickOutsideAutoscroll(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !this.btnRef.current.contains(event.target)) {
            this.setState({
                subMenuOpen: false,
            })
        }
    }
    
    update_scroller(newSpeed){
        $(window).scrollTop($(window).scrollTop()+1);
        this.scroll_timer = setTimeout(() => {
            this.update_scroller(newSpeed);
        }, newSpeed);
    }

    calculate_and_start_scroll(number){
        var newSpeed = 300 - 3*number + 20;
        this.update_scroller(newSpeed)
    }

    scroll(number){
        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "scroll"))
        }
        else {
            if(number == 0){
                clearTimeout(this.scroll_timer);
                this.setState({
                    scrolling: false,
                    speed: 0 
                })
            }else{
                clearTimeout(this.scroll_timer);
                this.calculate_and_start_scroll(number)
                this.setState({
                    scrolling: true,
                    speed: number
                })
            }
            this.props.setScroll(number)
        }
    }

    toggleStop(){
        if(this.state.scrolling){
            clearTimeout(this.scroll_timer);
            this.setState({
                scrolling: false,
            })
        }else{
            this.calculate_and_start_scroll(this.state.speed)
            this.setState({
                scrolling: true,    
            })
        }
    }

    toggleSubmenu(){
        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "scroll"))
        }
        else {
            this.setState({subMenuOpen:!this.state.subMenuOpen})
        }
    }

    render() {
        return (
            <>
                <button className="btn btn-icon" ref={this.btnRef} onClick={() => this.toggleSubmenu()}>
                    <div className="svg-container">< SvgArrowFillDown /></div>
                    <p className="d-none d-md-block">{gettext("Auto Scroll")}</p>
                </button>
                <div className={`autoscroll ${this.state.subMenuOpen === true ? 'open' : ''}`} ref={this.wrapperRef}>
                    <ReactSlider
                        className="speed"
                        thumbClassName="line"
                        trackClassName="example-track"
                        renderThumb={(props, state) => <div {...props}></div>}
                        onAfterChange={val => this.scroll(val)}
                        value={this.state.speed}
                    />
                    <div className={`${!this.state.scrolling ? "play" : "stop"} ${this.state.speed === 0 && "disabled"}`} onClick={() => this.toggleStop()}></div>
                </div>
            </>
        )
    }
}

export default Autoscroll;