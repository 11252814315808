import { createTypes } from 'redux-action-creator';
import { addAuthorizeHeader, getCookie, handleNetworkError, parseJSON } from '../../utils';
import { fetchSong } from './../song/actions';
export const chordTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'CHORDS');
export const chordPreferenceTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'CREATED', 'DELETE'], 'CHORD_PREFERENCE');


export const requestChords = () => {
    return {
        type: chordTypes.REQUEST,
    };
};

export const receiveChords = (response, key) => {
    return {
        type: chordTypes.RECEIVE,
        data: response,
    };
};

export const receiveChordsError = () => {
    return {
        type: chordTypes.ERROR,
    };
};

export const fetchChords = (chord, instrument) => {
    return dispatch => {
        dispatch(requestChords());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        }

        let url = `/api/v3/core/chords/?chord=${encodeURIComponent(chord)}&instrument=${instrument}`



        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveChords(response));
            } catch (e) {
                dispatch(receiveChordsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const createdChordPreference = (response) => {
    return {
        type: chordPreferenceTypes.CREATED,
        data: response
    }
}

export const selectChord = (songId, chordId, name, key='') => {
    return dispatch => {
        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/chords/`

        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({song_id: songId, chord_id: chordId, chord_lookup: name})
        })
        .then(parseJSON)
        .then(response => {
            dispatch(createdChordPreference(response));
            dispatch(fetchSong(songId, key));
            dispatch(fetchUserChordPreference(songId))
        })
        .catch(handleNetworkError);
    }
};

export const requestChordPreference = () => {
    return {
        type: chordPreferenceTypes.REQUEST,
    };
};

export const receiveChordPreference = (response, key) => {
    return {
        type: chordPreferenceTypes.RECEIVE,
        data: response,
    };
};

export const receiveChordPreferenceError = () => {
    return {
        type: chordPreferenceTypes.ERROR,
    };
};

export const fetchUserChordPreference = (song_id) => {
    return dispatch => {
        dispatch(requestChordPreference());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        }

        let url = `/api/v3/core/chords/get_user_preference/?song_id=${song_id}`



        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveChordPreference(response));
            } catch (e) {
                dispatch(receiveChordPreferenceError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const deletedChordPreference = (response) => {
    return {
        type: chordPreferenceTypes.DELETE,
        data: response
    }
}

export const deleteChordPreference = (id, songId, key='') => {
    return dispatch => {
        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/chords/${id}/`


        return fetch(url, {
            method: 'DELETE',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            dispatch(deletedChordPreference(response));
            dispatch(fetchSong(songId, key));
            dispatch(fetchUserChordPreference(songId));
        })
        .catch(handleNetworkError);
    }
};
