import React from "react";
// import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import { openModal } from "../../api/modals/actions";


class BuyButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let { user } = this.props
    return (
      <>
        {user && !user.hasSubscription &&
          <>
            {user && user.isAuthenticated ?
              <>
                <button className="btn buy-button btn-buy" onClick={() => this.props.dispatch(openModal("modal-subscription"))}>{gettext("Buy subscription")}</button>
              </>
              :
              <button className="btn buy-button btn-buy" onClick={() => this.props.dispatch(openModal("modal-login", gettext("You must log in or sign up before you purchase a subscription")))}>{gettext("Buy subscription")}</button>
            }
          </>
        }
      </>
    )
  }
}

BuyButton.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    user: {},
  }
  f.user = (state.user === undefined) ? [] : state.user
  return f;
}

export default connect(mapStateToProps)(BuyButton);