import React from "react";
import { connect } from 'react-redux'
import SongList from '../song-list'
import { Spinner } from "../../utils";

class Toplist extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            toplist: this.props.toplist
        }

    }



    render() {
        return (
            (!this.props.toplist.isFetching && this.props.toplist.toplist.length > 0) ? <SongList list={this.props.toplist.toplist} allFavourites={false} showExcerpt={true} showFilterInfo={true} listType={"toplist"} /> : <Spinner/>

        )
    }
}

Toplist.defaultProps = {
}

function mapStateToProps(state) {

    var f = {
        toplist: {},
    }
    f.toplist = (state.toplist === undefined) ? [] : state.toplist
    return f;
}

export default connect(mapStateToProps)(Toplist);