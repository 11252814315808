import React from 'react';

import { songLangTypes } from './actions'
import moment from 'moment'


export function songLang(state = {
    isFetching: false,
    songLang: {},
}, action) {

    switch (action.type) {
        case songLangTypes.REQUEST:
            
            state = { ...state }
            state.isFetching = true
            return state

        case songLangTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.songLang = action.data

            return state

        default:
            return state
    }

}