import React from 'react'
import ReactDOM from 'react-dom/client'
import ArtistContainer from './artist'
import { store } from '../../store'
import { Provider } from 'react-redux'

function Wrapper(props) {
  return (
      <Provider store={store}>
          <ArtistContainer {...props}/>
      </Provider>
  )
}

function initArtistContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-artistcontainer]');
  elements.forEach(initArtistContainer);
});

