
import React from 'react'
import ReactDOM from 'react-dom/client'
import ProfileContainer from './profile'
import { store } from '../../store'
import { Provider } from 'react-redux'

function Wrapper(props) {
    return (
        <Provider store={store}>
            <ProfileContainer {...props}/>
        </Provider>
    )
}

function initProfileContainer(element) {
    var options = element.dataset;
    var root = ReactDOM.createRoot(element);
    root.render(React.createElement(Wrapper, options));
  }
  
  document.addEventListener("DOMContentLoaded", function() {
    var elements = document.querySelectorAll('[data-profile]');
    elements.forEach(initProfileContainer);
  });