import React from "react";

import { setLang } from '../../utils'

const LanguageSelector = (props) => {
  const { completed } = props;
  return (
    <>
    { window.LANGUAGE_CODE == 'is' ?
      <span className="lang" onClick={() => setLang('en')}>EN</span>
      :
      <span className="lang" onClick={() => setLang('is')}>IS</span>
    } 
    </>
  );
};

export default LanguageSelector;