import fetch from 'isomorphic-fetch'
import Cookie from 'js-cookie'
import { checkHttpStatus, handleNetworkError, parseJSON, getCookie, addAuthorizeHeader } from '../../utils'

import { createTypes, actionCreator } from 'redux-action-creator';
import { fetchUser } from '../user/actions';
import { setStep } from '../step-renderer/actions';

export const subscriptionTypes = createTypes(['REQUEST', 'RECEIVE', 'SUCCESS', 'ERROR', 'REQUEST_TEMP_TOKEN', 'RECEIVE_TEMP_TOKEN', 'SUCCESS_TEMP_TOKEN', 'ERROR_TEMP_TOKEN', 'RECEIVE_TOKEN_STATUS', 'REQUEST_TOKEN_STATUS', 'ERROR_TOKEN_STATUS',], 'SUBSCRIPTION');


export const requestSubscription = (response) => {
    return {
        type: subscriptionTypes.REQUEST,
        data: response
    };
};


export const receiveSubscription = (response) => {
    return {
        type: subscriptionTypes.RECEIVE,
        data: response,
    };
};

export const receiveSubscriptionError = (response) => {
    return {
        type: subscriptionTypes.ERROR,
        data: response
    };
};


export const requestTemporaryToken = (response) => {
    return {
        type: subscriptionTypes.REQUEST_TEMP_TOKEN,
        data: response
    };
};


export const receiveTemporaryToken = (response) => {
    return {
        type: subscriptionTypes.RECEIVE_TEMP_TOKEN,
        data: response,
    };
};

export const receiveTemporaryTokenError = (response) => {
    return {
        type: subscriptionTypes.ERROR_TEMP_TOKEN,
        data: response
    };
};

export const requestTokenStatus = () => {
    return {
        type: subscriptionTypes.REQUEST_TOKEN_STATUS,
    };
};


export const receiveTokenStatus = (response) => {
    return {
        type: subscriptionTypes.RECEIVE_TOKEN_STATUS,
        data: response,
    };
};

export const receiveTokenStatusError = (response) => {
    return {
        type: subscriptionTypes.ERROR_TOKEN_STATUS,
        data: response
    };
};


export const fetchSubscription = (subscription_id) => {
  return dispatch => {

      dispatch(requestSubscription())
      let success = true
      return fetch('/payments/subscriptions/' + subscription_id, {
          method: "GET",
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json;charset=UTF-8',
              'X-CSRFToken': getCookie("guitarpartycsrftoken"),
              credentials: 'include'
          }
          // body: JSON.stringify(
          //     Object.assign({subscription: subscription_id})
          // )
      })
      .then(response => {
          if(response.status !== 200) {
              success = false
              if (response.status >= 500) {
                  dispatch(receiveSubscriptionError({error: gettext("A server error occurred. Please try again later.")}))
              }
          }
          return response
      })
      .then(parseJSON)
      .then(response => {
          if (success) {
              dispatch(receiveSubscription(response));
              dispatch(fetchUser())
          } else {
              dispatch(receiveSubscriptionError(response))
          }
      })
      .catch(handleNetworkError);
  };
}


export const subscribe = (plan_id, token, cardObj) => {
    return dispatch => {

        dispatch(requestSubscription())
        let success = true

        let body = {
            plan: plan_id,
            token: token,
        }
        Object.assign(body, cardObj)
        delete body.cardNumber
        delete body.cvc
        delete body.expiryDate

        return fetch('/payments/subscribe/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': getCookie("guitarpartycsrftoken"),
                credentials: 'include'
            },
            body: JSON.stringify(body)
        })
        .then(response => {
            if(response.status !== 200) {
                success = false
                if (response.status >= 500) {
                    dispatch(receiveSubscriptionError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveSubscription(response));
                dispatch(fetchUser())
                dispatch(setStep(1))
            } else {
                dispatch(receiveSubscriptionError(response))
            }
        })
        .catch(handleNetworkError);
    };
}
export const createTemporaryToken = (cardObj, plan_id) => {
    return dispatch => {

        let expiry = cardObj.expiryDate.split("/")

        dispatch(requestTemporaryToken())
        let success = true
        return fetch(`${window.ASKELL_ENDPOINT}/temporarypaymentmethod/`, {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Api-Key ${window.ASKELL_PUBLIC_KEY}`,
            },
            body: JSON.stringify({
                'card_number': cardObj.cardNumber.replaceAll(" ", ""),
                'expiration_year': expiry[1].trim(),
                'expiration_month': expiry[0].trim(),
                'cvv_number': cardObj['cvc'],
                'plan': plan_id
            })
        })
        .then(response => {
            if(response.status !== 201) {
                success = false
                if (response.status >= 500) {
                    dispatch(receiveTemporaryTokenError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveTemporaryToken(response));
            } else {
                dispatch(receiveTemporaryTokenError(response))
            }
        })
        .catch(handleNetworkError);
    };
}

export const updateCreditcard = (user_id, token, cardObj) => {
    return dispatch => {

        let body = {
            token: token,
        }

        dispatch(requestSubscription())
        let success = true
        return fetch('/payments/updatecard/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': getCookie("guitarpartycsrftoken"),
                credentials: 'include'
            },
            body: JSON.stringify(
                body
            )
        })
        .then(response => {
            if(response.status !== 200) {
                success = false
                if (response.status >= 500) {
                    dispatch(receiveSubscriptionError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveSubscription(response));
                dispatch(fetchUser())
                dispatch(setStep(1))
            } else {
                dispatch(receiveSubscriptionError(response))
            }
        })
        .catch(handleNetworkError);
    };
}


export const cancel = () => {
    return dispatch => {

        dispatch(requestSubscription())
        let success = true
        return fetch('/payments/cancel/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': getCookie("guitarpartycsrftoken"),
                credentials: 'include'
            }
        })
        .then(response => {
            if(response.status !== 200) {
                success = false
                if (response.status >= 500) {
                    dispatch(receiveSubscriptionError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveSubscription(response));
                dispatch(fetchUser())
            } else {
                dispatch(receiveSubscriptionError(response))
            }
        })
        .catch(handleNetworkError);
    };
}



export const fetchTokenStatus = (token) => {
    return dispatch => {
  
        dispatch(requestTokenStatus())
        let success = true
        return fetch(`${window.ASKELL_ENDPOINT}/temporarypaymentmethod/${token}/`, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Authorization': `Api-Key ${window.ASKELL_PUBLIC_KEY}`,
        }
        })
        .then(response => {
            if(response.status !== 200) {
                success = false
                if (response.status >= 500) {
                    dispatch(receiveTokenStatusError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveTokenStatus(response));
            } else {
                dispatch(receiveTokenStatusError(response))
            }
        })
        .catch(handleNetworkError);
    };
  }