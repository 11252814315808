import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const songActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'SET_KEY'], 'SONGDATA');


export const requestSong = () => {
    return {
        type: songActionTypes.REQUEST,
    };
};

export const receiveSong = (response, key) => {
    return {
        type: songActionTypes.RECEIVE,
        data: response,
    };
};

export const receiveSongError = () => {
    return {
        type: songActionTypes.ERROR,
    };
};

export const setKey = (id, key) => {
    return dispatch => {
        dispatch(fetchSong(id, key))
    }
}

export const fetchSong = (id, key='', instrument='') => {
    return dispatch => {

        dispatch(requestSong());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v3/core/songs/${id}/`

        if (key !== '' && key !== null) {
            if (key.includes('#')) {
                key = key.replace('#', 'sharp')
            }
            url = `${url}transpose/?key=${key}`
        }

        if (instrument !== '') {
            if (url.includes('transpose')) {
                url = `${url}&instrument=${instrument}`
            } else {
                url = `${url}transpose/?instrument=${instrument}`
            }
        }

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveSong(response, key));
            } catch (e) {
                dispatch(receiveSongError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
