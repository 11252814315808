import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import { fetchSong } from '../song/actions'

export const instrumentActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'SELECT'], 'INSTRUMENT');


export const requestInstruments = () => {
    return {
        type: instrumentActionTypes.REQUEST,
    };
};

export const receiveInstruments = (response) => {
    return {
        type: instrumentActionTypes.RECEIVE,
        data: response,
    };
};

export const receiveInstrumentsError = () => {
    return {
        type: instrumentActionTypes.ERROR,
    };
};

export const _selectInstrument = (id) => {
    return {
        type: instrumentActionTypes.SELECT,
        id: id,
    }
}

export const selectInstrument = (song, key, instrument) => {
    return dispatch => {
        dispatch(_selectInstrument(instrument))
        dispatch(fetchSong(song, key, instrument))
    }
}

export const fetchInstruments = () => {
    return dispatch => {

        dispatch(requestInstruments());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v3/core/instruments/`


        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveInstruments(response));
            } catch (e) {
                dispatch(receiveInstrumentsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
