import React from 'react';

import { toplistTypes, allToplistTypes } from './actions'
import moment from 'moment'

const _initalState = {
    isFetching: false,
    toplist: []
}

export function toplist(state = _initalState, action) {
    switch (action.type) {
        case toplistTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case toplistTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.toplist = action.data

            return state

        case allToplistTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case allToplistTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.toplists = action.data       

            return state

        default:
            return state
    }

}