import React from 'react'
import ReactDOM from 'react-dom/client'
import Modals from './modals'
import { store } from '../../store'
import { Provider } from 'react-redux'


function Wrapper(props) {
  return (
      <Provider store={store}>
          <Modals {...props}/>
      </Provider>
  )
}

function initModals(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-modals]');
  elements.forEach(initModals);
});
