import React from 'react';
import { modalActionTypes } from './actions'
import moment from 'moment'
import { userInfoTypes } from '../user/actions'

export function modals(state = {
    "modal-login-screen": "login"
}, action) {

    switch (action.type) {
        case modalActionTypes.OPEN:
            
            state = {
                ...state,
                [action.name]: true,
            }
            if (action.message) {
             state[`${action.name}-message`] = action.message
            }
            if (action.data){
              state.data = action.data
            }
            return state

        case modalActionTypes.CLOSE:

            state = {
                ...state,
                [action.name]: false,
                [`${action.name}-message`]: null,
                "modal-login-screen": "login"
            }
            return state

        case modalActionTypes.SET_SCREEN:

            state = {
                ...state,
                "modal-login-screen": action.screen
            }
            return state
        case userInfoTypes.RECEIVE_USER_REGISTER:

            state = {
                ...state,
                "modal-login-screen": "signup-complete"
            }
            return state

        case userInfoTypes.RECEIVE_USER_SET_NEW_PASSWORD:

                state = {
                    ...state,
                    "modal-login-screen": "reset-password-complete"
                }
                return state
        

        default:
            return state
    }

}