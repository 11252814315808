import React from 'react';
import { connectWithStore, validateEmail } from '../../utils'
import { store } from '../../store'

class EmailEdit extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isEditing: false,
            email: ''
        }

        this.onChange = this.onChange.bind(this)
        this.changeEmail = this.changeEmail.bind(this)
    }

    componentDidUpdate(prevProps){
      if(this.props.user.email != prevProps.user.email){
        // On load, prevprops.user.email is undefined
        if (prevProps.user.email){
          this.setState({ updateStatus: 'Email changed'})
        }
        this.setState({ email: this.props.user.email })
      }
    }

    setEditing(value){
        this.setState({isEditing: value})
    }

    onChange(field, value){
      this.setState({ 
        [field]: value,
        updateStatus: ''
       })
    }

    changeEmail(value){
      if(validateEmail(value)){
        this.props.changeUserEmail('email', value)
      } else {
        this.setState({ updateStatus: 'Wrong format'})
      }
    }

    render() {
        return (
            <section>
                <div onBlur={() => this.setEditing(false)}>
                    <input
                        type="text"
                        name="task"
                        placeholder={this.props.placeholder}
                        value={this.state.email}
                        onChange={e => this.onChange(this.props.field, e.target.value)}
                    />
                </div>
                <button onClick={() => this.changeEmail(this.state.email)}>{gettext("Update email")}</button>
                {this.state.updateStatus && <div>{this.state.updateStatus}</div>}
                {this.props.user.registrationError && this.props.user.registrationError.error && <div>{this.props.user.registrationError.error}</div>}
            </section>
        )
    }
}

function mapStateToProps(state) {
  var f = {
      user: {},
  }

  f.user = (state.user === undefined) ? [] : state.user

  return f;
}

export default connectWithStore(store, EmailEdit, mapStateToProps);