import React from 'react';

import { songActionTypes } from './actions'
import moment from 'moment'
import {AVAILABLE_LANGUAGES} from '../../constants'

export function song(state = {
    isFetching: false,
    key: null,
    data: {},
}, action) {

    switch (action.type) {
        case songActionTypes.REQUEST:
            
            state = { ...state }
            state.isFetching = true
            return state

        case songActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            if (action.data.language && action.data.language !== "") {
                state.data.tags.push({name: AVAILABLE_LANGUAGES[action.data.language], slug: action.data.language})
            }

            return state

        default:
            return state
    }

}