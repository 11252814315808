import { createTypes, actionCreator } from 'redux-action-creator';
// import { parseJSON, handleNetworkError, addAuthorizeHeader } from '../../utils'

export const stepRenderTypes = createTypes(['INIT_STEPPER', 'SET_STEP'], 'STEPRENDER');

export const initStepper = (stepper) => {
  return {
    type: stepRenderTypes.INIT_STEPPER,
    stepper: stepper,
    index: 0
  };
};

export const setStep = (newIndex) => {
  return {
      type: stepRenderTypes.SET_STEP,
      data: newIndex,
  };
};
