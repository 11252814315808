import { actionCreator, createTypes } from 'redux-action-creator';
import { addAuthorizeHeader, checkHttpStatus, handleNetworkError, parseJSON, updateUserUI, getCookie } from '../../utils'

import Cookie from 'js-cookie'
import TokenStorage from '../../storages/token-storage'
import fetch from 'isomorphic-fetch'
import jwtDecode from 'jwt-decode';

export const userActionTypes = createTypes(['LOGIN_REQUEST', 'LOGIN_REFRESH', 'LOGIN_SUCCESS', 'LOGIN_FAILURE', 'LOGIN_REFRESH_FAILURE', 'LOGOUT', 'UPDATE'], 'AUTH');

export const userInfoTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'RECEIVE_USER_REGISTER', 'RECEIVE_USER_REGISTER_ERROR', 'REQUEST_USER_REGISTER',  'RECEIVE_USER_RESET_PASSWORD', 'RECEIVE_USER_RESET_PASSWORD_ERROR', 'REQUEST_USER_RESET_PASSWORD', 'RECEIVE_USER_SET_NEW_PASSWORD', 'RECEIVE_USER_SET_NEW_PASSWORD_ERROR', 'REQUEST_USER_SET_NEW_PASSWORD', 'REQUEST_USER_CONTRIB_SONGS', 'RECEIVE_USER_CONTRIB_SONGS_ERROR', 'RECEIVE_USER_CONTRIB_SONGS', 'SCHEDULE_DELETE', 'DELETE_SCHEDULED', 'SCHEDULE_DELETE_ERROR', 'DELETE_REMOVED', 'REQUEST_DELETE_SCHEDULED', 'RECEIVE_DELETE_SCHEDULED', 'RECEIVE_DELETE_SCHEDULED_ERROR'], 'USER')

export const userLoginSuccess = (token, initiatedByUser=false) => {
    TokenStorage.setItem('token', token);
    if (window.Raven !== undefined) {
        let userData = jwtDecode(token)
        Raven.setUserContext(userData)
    }
    return {
        type: userActionTypes.LOGIN_SUCCESS,
        data: token,
        initiatedByUser
    }
}

export const userRefreshLoginFailure = (data) => {
    TokenStorage.removeItem('token');
    return {
        type: userActionTypes.LOGIN_REFRESH_FAILURE,
        data: data
    }
}

export const userLoginFailure = (response) => {
    TokenStorage.removeItem('token');
    return {
        type: userActionTypes.LOGIN_FAILURE,
        data: response
    }
}

export const requestUser = () => {
    return {
        type: userActionTypes.LOGIN_REQUEST,
    };
};

export const requestUserInfo = () => {
    return {
        type: userInfoTypes.REQUEST,
    };
};

export const receiveUserInfo = (response) => {
    return {
        type: userInfoTypes.RECEIVE,
        data: response,
    };
};

export const receiveUserInfoError = () => {
    return {
        type: userInfoTypes.ERROR,
    };
};

export const refreshUser = () => {
    return {
        type: userActionTypes.LOGIN_REFRESH,
    };
};

export const updateUser = () => {
    return {
        type: userActionTypes.UPDATE,
    };
};

export const performUserLogout = () => {
    TokenStorage.removeItem('token');
    setTimeout(() => {}, 50)
    return {
        type: userActionTypes.LOGOUT,
    };
};

export const fetchUser = () => {
    return dispatch => {

        dispatch(requestUserInfo());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        })

        let url = `/api/v3/core/user/current/`


        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveUserInfo(response));
            } catch (e) {
                dispatch(receiveUserInfoError({}))
            }
        })
        .catch(handleNetworkError);
    };
};


export const userRefreshLogin = () => {
    return dispatch => {
        let token = TokenStorage.getItem("token");
        let shouldRefresh = true
        try {
            let userData = jwtDecode(token)
            let now = moment().unix()
            let shouldRefresh = now > (userData.exp - 300)
        } catch (e) {
            void(0)
        }

        if(window.__refreshing_login__){
            return Promise.resolve(dispatch({type: 'REFRESHING_LOGIN'}))
        }
        if (token && shouldRefresh) {
            window.__refreshing_login__ = true
            dispatch(refreshUser(token));
            return fetch('/api-token-refresh/', {
                followRedirect: false,
                method: "POST",
                credentials: "same-origin",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    token,
                })
            })
            .then(parseJSON)
            .then(response => {
                try {
                    window.__refreshing_login__ = false
                    // Validate if token is valid, throws error if invalid
                    jwtDecode(response.token);
                    dispatch(userLoginSuccess(response.token));
                } catch (e) {
                    dispatch(userRefreshLoginFailure({
                    }))
                }
            })
            .catch(handleNetworkError);
        }
        else {
            return Promise.resolve("No need to refresh login")
        }
    };
};

export const userDjangoLogin = (username, password) => {
    return dispatch => {

        dispatch(requestUser())
        
        let success = true
        return fetch('/ajaxlogin/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': getCookie("guitarpartycsrftoken"),
                credentials: 'include'
            },
            body: JSON.stringify({
                username,
                password
            })
        })
        .then(response => {
            if(response.status > 400) {
                success = false
                if (response.status >= 500) {
                    dispatch(recieveUserRegisterError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(fetchUser())
            } else {
                dispatch(userLoginFailure(response))
            }
        })
        .catch(handleNetworkError);
    };
}

export const userLogout = () => {
    return dispatch => {
        return fetch('/ajaxlogout/?next=/', {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
        .then(response => {
            dispatch(performUserLogout());
        })
        .catch(handleNetworkError);
    };
}


export const userLogin = (username, password) => {
    return dispatch => {
        dispatch(requestUser());

        return fetch('/api-token-auth/', {
            followRedirect: false,
            method: "POST",
            credentials: "same-origin",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                username,
                password
            })
        })
        //.then(checkHttpStatus)
        .then(parseJSON)
        .then(response => {

            try {
                
                // Validate if token is valid, throws error if invalid
                jwtDecode(response.token);

                dispatch(userDjangoLogin(username, password))
                .then(() => {
                    dispatch(userLoginSuccess(response.token, true));
                })
                //updateUserUI(dispatch)

            } catch (e) {
                dispatch(userLoginFailure({}))
            }
        })
        .catch(handleNetworkError);
    };
};

export const userUpdateInfo = (key, value) => {
    return dispatch => {

        dispatch(updateUser())

        let success = true

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/user/update_user/`

        let data = value;

        let error = gettext("That email is already taken.")
        if (key !== 'email') {
            error = gettext("An error occured.")
        }

        return fetch(url, {
            method: 'PUT',
            body: JSON.stringify({'field': key, 'new_string': data}),
            headers: headers,
        })
        .then(response => {
            if(response.status >= 400) {
                success = false
                dispatch(recieveUserRegisterError({error: error}))
            }
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(receiveUserInfo(response));
            } else {
                dispatch(receiveUserInfoError(response))
            }

        }).catch(handleNetworkError)
    }
};

export const requestUserRegister = () => {
    return {
        type: userInfoTypes.REQUEST_USER_REGISTER,
    };
};

export const recieveUserRegisterError = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_REGISTER_ERROR,
        data: response,
    };
};

export const recieveUserRegister = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_REGISTER,
        data: response,
    };
};

export const userDjangoRegister = (username, email, password, password_confirm) => {
    return dispatch => {

        let success = true
        
        dispatch(requestUserRegister())

        return fetch('/api/v3/core/accounts/register/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': getCookie("guitarpartycsrftoken"),
                credentials: 'include'
            },
            body: JSON.stringify({
                username,
                email: email.toLowerCase(),
                password,
                password_confirm,
            })
        })
        .then(response => {
            if(response.status > 300) {
                success = false
                if (response.status >= 500) {
                    dispatch(recieveUserRegisterError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(recieveUserRegister(response));
            } else {
                dispatch(recieveUserRegisterError(response))
            }
        })



    };
}

export const requestUserResetPassword = () => {
    return {
        type: userInfoTypes.REQUEST_USER_RESET_PASSWORD,
    };
};

export const recieveUserResetPasswordError = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_RESET_PASSWORD_ERROR,
        data: response,
    };
};

export const recieveUserResetPassword = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_RESET_PASSWORD,
        data: response,
    };
};

export const userDjangoResetPassword = (value) => {
    return dispatch => {

        let success = true
        
        dispatch(requestUserResetPassword())

        return fetch('/api/v3/core/accounts/send-reset-password-link/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': getCookie("guitarpartycsrftoken"),
                credentials: 'include'
            },
            body: JSON.stringify({
                'login': value,
            })
        })
        .then(response => {
            if(response.status > 300) {
                success = false
                if (response.status >= 500) {
                    dispatch(recieveUserResetPasswordError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(recieveUserResetPassword(response));
            } else {
                dispatch(recieveUserResetPasswordError(response))
            }
        })



    };
}

export const requestUserSetNewPassword = () => {
    return {
        type: userInfoTypes.REQUEST_USER_SET_NEW_PASSWORD,
    };
};

export const recieveUserSetNewPasswordError = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_SET_NEW_PASSWORD_ERROR,
        data: response,
    };
};

export const recieveUserSetNewPassword = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_SET_NEW_PASSWORD,
        data: response,
    };
};

export const userDjangoSetNewPassword = (user_id, timestamp, signature, password) => {

    return dispatch => {

        let success = true
        
        dispatch(requestUserSetNewPassword())

        return fetch('/api/v3/core/accounts/reset-password/', {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'X-CSRFToken': getCookie("guitarpartycsrftoken"),
                credentials: 'include'
            },
            body: JSON.stringify({
                user_id,
                timestamp,
                signature,
                password
            })
        })
        .then(response => {
            if(response.status > 300) {
                success = false
                if (response.status >= 500) {
                    dispatch(recieveUserSetNewPasswordError({error: gettext("A server error occurred. Please try again later.")}))
                }
            }
            return response
        })
        .then(parseJSON)
        .then(response => {
            if (success) {
                dispatch(recieveUserSetNewPassword(response));
            } else {
                dispatch(recieveUserSetNewPasswordError(response))
            }
        })
    };
}

export const requestUserContribSongs = () => {
    return {
        type: userInfoTypes.REQUEST_USER_CONTRIB_SONGS,
    };
};


export const recieveUserContribSongs = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_CONTRIB_SONGS,
        data: response,
    };
};

export const recieveUserContribSongsError = (response) => {
    return {
        type: userInfoTypes.RECEIVE_USER_CONTRIB_SONGS_ERROR,
        data: response,
    };
};

export const fetchContribSongs = () => {
    return dispatch => {

        dispatch(requestUserContribSongs())

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/user/sent_songs/`

        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(recieveUserContribSongs(response));
            } catch (e) {
                dispatch(recieveUserContribSongsError(response))
            }

        }).catch(handleNetworkError)
    }
};

export const requestScheduleUserDelete = () => {
    return {
        type: userInfoTypes.SCHEDULE_DELETE,
    };
};

export const recieveScheduleUserDelete = (response) => {
    return {
        type: userInfoTypes.DELETE_SCHEDULED,
        data: response,
    };
};

export const recieveScheduleUserDeleteError = (response) => {
    return {
        type: userInfoTypes.DELETE_SCHEDULED_ERROR,
        data: response,
    };
};

export const requestUserDelete = () => {
    return dispatch => {

        dispatch(requestScheduleUserDelete())

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/user_delete/`

        return fetch(url, {
            method: 'POST',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(recieveScheduleUserDelete(response));
                dispatch(isUserScheduledForDelete());
            } catch (e) {
                dispatch(recieveScheduleUserDeleteError(response))
            }

        }).catch(handleNetworkError)
    }
};

export const scheduledDeleteRemoved = (response) => {
    return {
        type: userInfoTypes.DELETE_REMOVED,
        data: response,
    };
};

export const removeUserDelete = (id) => {
    return dispatch => {

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/user_delete/${id}`

        return fetch(url, {
            method: 'DELETE',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            dispatch(scheduledDeleteRemoved(response));
            dispatch(isUserScheduledForDelete());
        }).catch(handleNetworkError)
    }
};

export const requestIsUserScheduledForDelete = () => {
    return {
        type: userInfoTypes.REQUEST_DELETE_SCHEDULED,
    };
};

export const recieveIsUserScheduledForDelete = (response) => {
    return {
        type: userInfoTypes.RECEIVE_DELETE_SCHEDULED,
        data: response,
    };
};

export const recieveIsUserScheduledForDeleteError = (response) => {
    return {
        type: userInfoTypes.RECEIVE_DELETE_SCHEDULED_ERROR,
        data: response,
    };
};

export const isUserScheduledForDelete = () => {
    return dispatch => {

        dispatch(requestIsUserScheduledForDelete())

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/user_delete/`

        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(recieveIsUserScheduledForDelete(response));
            } catch (e) {
                dispatch(recieveIsUserScheduledForDeleteError(response))
            }

        }).catch(handleNetworkError)
    }
};