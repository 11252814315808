import { createTypes, actionCreator } from 'redux-action-creator';
import { handleNetworkError, addAuthorizeHeader, getCookie } from '../../utils'

export const songbookPdfStepTypes = createTypes(['SET_PDF_OPTION', 'SET_CHORDS', 'REQUEST_SONGBOOK'], 'SONGBOOKPDF');


export const setPdfoption = (key, value) => {
  return {
      type: songbookPdfStepTypes.SET_PDF_OPTION,
      key,
      value
  };
};

export const requestSongbook = (songbookData) => {
  return dispatch => {
    
    let url = `/api/v3/core/songbooks/generate_pdf/`

    return fetch(url, {
        method: 'POST',
        headers: addAuthorizeHeader({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRFToken': getCookie("guitarpartycsrftoken"),
          credentials: 'include'
        }),
        body: JSON.stringify(songbookData)
    })
    .catch(handleNetworkError);
  };
};

export const setStep = (newIndex) => {
  return {
      type: songbookPdfStepTypes.SET_STEP,
      data: newIndex,
  };
};
