import React from 'react';
import { openModal } from '../../api/modals/actions';
import { createSongbook } from '../../api/songbooks/actions';
import { store } from '../../store';
import { connectWithStore } from '../../utils';
import { SvgExitFill, SvgPlusCircular } from '../svg';


let trans = [
    gettext("New songbook")
]
class SongbookCreate extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,
            title: ""
        }

        this.toggleNewSongBook = this.toggleNewSongBook.bind(this)
    }

    toggleNewSongBook(e) {
        if (!this.props.user.hasSubscription && this.props.songbooks && this.props.songbooks.privateSongbooks.results.length >= 1) {
            this.props.dispatch(openModal("modal-paywall", "create-songbook"))
        } else {
            if (e.target.id === 'save' && this.state.title !== "" && this.state.title !== undefined) {
                //this.props.createNewSongbook(this.state.title)
                this.props.dispatch(createSongbook(this.state.title))
                this.setState({
                    title: ''
                })
            }
            var currentState = this.state.open;
            this.setState({open: !currentState});
        }
    }
    
    onChange(e) {
        this.setState({
            [e.target.id]: e.target.value,
        })
    }

    render() {
        const { currentKey, id } = this.props
        return (
            <div className={`new-songbook dark-bg ${this.state.open ? "add-new-open" : "" }`}>
                {this.state.open && <div className="create-new-songbook">
                    <label><SvgPlusCircular/>{gettext("New songbook")}</label>
                    <button onClick={this.toggleNewSongBook} className="close"><SvgExitFill/></button>
                    <input onChange={this.onChange.bind(this)} id="title"></input>
                    {/* {(this.state.title !== "" && this.state.title !== undefined) && <button id="save" className="save-songbook" onClick={this.toggleNewSongBook.bind(this)}>Vista</button>} */}
                    <button id="save" className="btn save-songbook" disabled={(this.state.title.trim() === "" || this.state.title === undefined)} onClick={this.toggleNewSongBook}>{gettext("Save")}</button>
                    </div>}
                {!this.state.open && <button className="add-songbook" id="new-songbook" onClick={this.toggleNewSongBook}>
                    <SvgPlusCircular/>
                    {gettext("Create songbook")}</button> }
            </div>
        )
    }
}

SongbookCreate.defaultProps = {

}


function mapStateToProps(state) {
    var f = {
        user: {},
    }
    f.user = (state.user === undefined) ? [] : state.user
    return f;
}

// export default connect(mapStateToProps)(Song);
export default connectWithStore(store, SongbookCreate, mapStateToProps);

