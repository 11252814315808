import React from 'react';

import { submitSongTypes, parseSongTypes } from './actions'
import moment from 'moment'


export function songSubmition(state = {
    isFetching: false,
    key: null,
    data: {},
    song_chordified: ''
}, action) {

    switch (action.type) {
        case submitSongTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case submitSongTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        case parseSongTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case parseSongTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.song_chordified = action.data

            return state

        default:
            return state
    }

}