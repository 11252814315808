var moment = require('moment')
moment.locale('is')
import 'bootstrap';
import $ from 'jquery';
window.$ = $;
window.jQuery = $;

// pusher
import { setPusherClient } from 'react-pusher';
import Pusher from 'pusher-js';
 
const pusherClient = new Pusher(window.PUSHER_KEY, {
    cluster: 'eu',
    authEndpoint: "/pusher/",
})
window.pusherClient = pusherClient
 
setPusherClient(pusherClient);


require('./views/song')
require('./views/userbar')
require('./views/songbooks')
require('./views/songbook-play')
require('./views/search')
require('./views/submit-a-song')
require('./views/profile')
require('./views/subscription')
require('./views/favourites')
require('./views/buy-button')
require('./views/modals')
require('./views/wishlist')
require('./views/toplists')
require('./views/artist')
require('./views/lesson-list')
require('jquery.scrollto');

import "../../sass/main.scss"
