import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, addAuthorizeHeader, getCookie } from '../../utils'

export const artistActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS',], 'ARTISTDATA');


export const requestArtist = () => {
    return {
        type: artistActionTypes.REQUEST,
    };
};

export const receiveArtist = (response, onlyPublic) => {
    return {
        type: artistActionTypes.RECEIVE,
        data: response,
        onlyPublic: onlyPublic
    };
};

export const receiveArtistError = () => {
    return {
        type: artistActionTypes.ERROR,
    };
};


export const fetchArtist = (artistId) => {
    return dispatch => {

        dispatch(requestArtist());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        })

        let url = `/api/v3/core/authors/${artistId}`

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveArtist(response));
            } catch (e) {
                dispatch(receiveArtistError(response))
            }


        })
        .catch(handleNetworkError);
    };
};