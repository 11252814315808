import { connectWithStore, stepIndicator } from '../../utils'
import { initStepper, setStep, stepRenderTypes } from '../../api/step-renderer/actions'

import GuitarpartyModal from '../modal'
import Modal from 'react-modal'
import React from 'react';
import { SONGBOOK_PDF_STEPS } from '../../constants'
import StepRenderer from '../step-renderer'
import { SvgArrowLeftGray } from '../svg'
import { fetchInstruments } from '../../api/instrument/actions';
import { setPdfoption } from '../../api/songbook-pdf/actions'
import { closeModal } from '../../api/modals/actions'
import { store } from '../../store'
import { withModal } from '../modal'


let transGoback = gettext("Go back")
let transContinue = gettext("Continue")
let transClose = gettext("Close")
let transCreatesongbook = gettext("Create songbook")


class ModalSongbook extends React.Component {
  constructor() {
    super();

    this.state = {}

    this.previous = this.previous.bind(this)
    this.next = this.next.bind(this)
  }

  componentDidMount() {
    // Uncomment this to keep previous state on modal close
    // let renderer = this.props.stepRenderer.stepper ? this.props.stepRenderer.stepper : SONGBOOK_PDF_STEPS['TEXT']
    // this.props.dispatch(initStepper(renderer))

    // Reset config on modal open
    this.props.dispatch(setPdfoption('pdfType', 'text'))
    this.props.dispatch(initStepper(SONGBOOK_PDF_STEPS['TEXT']))
  }

  previous() {
    this.props.dispatch(setStep(this.props.stepRenderer.index - 1))
  }

  next() {
    this.props.dispatch(setStep(this.props.stepRenderer.index + 1))
  }

  render() {
    let { stepper, index } = this.props.stepRenderer
    return (
      <GuitarpartyModal extraClasses="modal-songbook pdf-modal-container " modalKey={`modal-songbook-${this.props.songbook.id}`} {...this.props} >
        {stepper &&
          <div className="songbook-pdf-modal">            
              <div className="nav">
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal(`modal-songbook-${this.props.songbook.id}`))}>
                      <span></span>
                      <span></span>
                  </button>
                </div>
              <h1 className="title">{transCreatesongbook}</h1>
              { stepIndicator(this.props.stepRenderer) }
              <div className="content">
                <StepRenderer context="pdf-generator" index={index} stepper={stepper} user={this.props.user} songbook={this.props.songbook}></StepRenderer>
              </div>
              {index !== (stepper.length - 1) &&
              <div className="pn-buttons">
                  <button className={index === 0 ? "disabled previous" : "previous"} onClick={() => this.previous()}><SvgArrowLeftGray/>{transGoback}</button>
                  <button className="next" onClick={() => this.next()}>{index === (stepper.length - 1) ? transClose : transContinue}</button>
              </div>
              }
            
          </div>
        }
      </GuitarpartyModal>
    )
  }

}



// export default withModal(ModalSongbook);

function mapStateToProps(state) {
  var f = {
    dispatch: {},
    songbookPDF: {},
    stepRenderer: {}

  }
  f.dispatch = (state.dispatch === undefined) ? [] : state.dispatch
  f.songbookPDF = (state.songbookPDF === undefined) ? [] : state.songbookPDF
  f.stepRenderer = (state.stepRenderer === undefined) ? [] : state.stepRenderer
  return f;
}

export default connectWithStore(store, ModalSongbook, mapStateToProps);