import React from 'react'
import ReactDOM from 'react-dom/client'
import ToplistRootContainer from './toplists_root'

function initToplistRootContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(ToplistRootContainer, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-toplist-container]');
  elements.forEach(initToplistRootContainer);
});

