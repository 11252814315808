import React from "react";

let trans = [
]

class RelatedSongs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    getSongs(relSongs) {
        let allRelSongs = [];

        relSongs.data.songs.map((song) => {
            allRelSongs.push(
            <div className={`rel-song d-flex align-items-center  ${relSongs.data.songs.length < 3 ? 'col-md-6 col-8 less' : 'col-sm' }`}>
                <div className={`${relSongs.data.artist.img == '' ? 'no-img' : 'img-container'}`}>
                    <img src={relSongs.data.artist.img} />
                </div>
                <div className="song-title-container">
                    <a href={`/songs/`+ song.slug}>{song.title}</a>
                    <p><a href={`/artists/`+ relSongs.data.artist.slug}>({relSongs.data.artist.name})</a></p>
                </div>
            </div>
        )})
        return allRelSongs;
    }

    render() {
        let relatedSongs = this.props.relatedSongs;
        let songs = []
        if(relatedSongs && relatedSongs.data && relatedSongs.data.songs ){
            songs = relatedSongs.data.songs;
        }
        return (
            <div className={`container-fluid ${relatedSongs && songs.length == 0 ? 'd-none' : ''}`}>
                <div className="rel-song-container row justify-content-center">
                    <div className="col-xxl-8 col-xl-12 col-lg-10 col-md-14 col-16">
                        <h3 className="title">{gettext("Related songs")}</h3>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-xxl-8 col-xl-12 col-lg-10 col-md-14 col-16 d-flex all-song-container px-0">
                        {relatedSongs && (Object.entries(relatedSongs.data).length > 0) && this.getSongs(relatedSongs)}
                    </div>
                </div>
            </div>
        )
    }
}

RelatedSongs.defaultProps = {
}


export default RelatedSongs;