import React from "react";
import { fetchToplist } from '../../api/toplist/actions'

import { connect } from 'react-redux'
import Toplist from '../../components/toplist'


class ToplistSingleContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      paths: [
        { 'name': 'alltime-popular', 'title': 'All time popular', 'link': '/top/songs/alltime-popular' },
        { 'name': 'popular', 'title': 'Popular this week', 'link': '/top/songs/popular' },
        { 'name': 'latest', 'title': 'New arrivals', 'link': '/top/songs/latest' },
      ],
    }
  }

  componentDidMount() {
    let path = window.location.pathname
    let item = this.state.paths.filter(p => path.includes(p.link))

    this.props.dispatch(fetchToplist(item[0].name));
  }

  getTitle(path) {
    let item = this.state.paths.filter(p => path.includes(p.link))
    return item[0].title
  }

  getOtherLinks(path) {
    let allLinks = []
    this.state.paths.map(p => {
      if (!path.includes(p.link)) {
        allLinks.push(<a className="top-list-selector" href={p.link}>{gettext(p.title)}</a>)
      }
    })
    return allLinks
  }

  render() {
    return (
      <>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8 col-md-12 col-16">
            <div className="col-16 p-0">
            {/* <div class="alert alert-warning">{gettext("Unfortunately the toplists are not working right now. We are working on a solution!")}</div> */}
              <h1 className="stylish">{gettext(this.getTitle(window.location.pathname))}</h1>
            </div>
          </div>
        </div>
        <div className="row justify-content-center pb-5">
          <div className="col-lg-10 col-xl-8 col-md-12 col-16">
            {this.getOtherLinks(window.location.pathname)}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10 col-xl-8 col-md-12 col-16">
            <div className="p-0 toplist-div">
              <Toplist dispatch={this.props.dispatch} toplist={this.props.toplist} store={this.props.store} />
            </div>
          </div>
        </div>
      </>
    )
  }
}

ToplistSingleContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    toplist: {},
  }

  f.toplist = (state.toplist === undefined) ? [] : state.toplist
  return f;
}

export default connect(mapStateToProps)(ToplistSingleContainer);