import React from 'react';
import { closeModal, openModal } from '../../api/modals/actions';
import { SvgQR } from '../svg'

export default function ShareQRCode({ user, dispatch }) {
    let { isAuthenticated } = user;

    const showMessage = () => {
        dispatch(openModal("modal-paywall", "share-qr"))
    }

    const showQRModal = () => {
        dispatch(openModal("modal-qr", "share-qr"))
    }

    return (

        <div className="share-qr-inner-container inner-container position-relative d-flex justify-content-end">
            <button className="btn btn-outline btn-icon-after hover-btn" onClick={isAuthenticated ? showQRModal : showMessage}>
                <span className="add">{gettext("Share songbook")}</span>
                <div className="svg-container"><SvgQR /></div>
            </button>
        </div>

    )
}