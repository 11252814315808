import React, { Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import SearchInput from './search-input'
import SongList from '../../components/song-list'

let trans = [
  gettext("Popular this week"),
  gettext("New arrivals"),
  gettext("All time popular"),
  gettext("Songs in English"),
  gettext("Songs in Icelandic"),
  gettext("Songs in Danish"),
  gettext("No results found"),
]

class SearchContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidUpdate(prevProps) {
    if (this.props.search.results.length !== prevProps.search.results.length) {
      if (this.props.search.results.length > 0) {
        $("body").addClass("searchresults");
      } else {
        $("body").removeClass("searchresults");
      }
    }
  }

  render() {
    const { search, user, songbooks } = this.props

    return (
      <React.Fragment>
        <div className={`search-wrapper ${(search.results.length > 0 || search.touched) ? "results" : ""}`}>
          <SearchInput />
          {search.results.length > 0 ?
            <div className="search-results">
              <SongList list={search.results} showFilterInfo={true} filterString={"search result"} />
            </div>
            :
            <>
              {search.touched &&
                <div className="search-results">
                  <div className="song-list favourites-list">
                    <div className="song-list row justify-content-center ">
                      <div className="item col-md-15 col-16">
                        {gettext("No results found")}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </>
          }
        </div>
        {search.results.length == 0 &&
          <ul className="toplists nav nav-pills">
            <li className="nav-item">
              <a className="nav-link" href="/top/songs/popular/">{gettext("Popular this week")}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/top/songs/latest/">{gettext("New arrivals")}</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/top/songs/alltime-popular/">{gettext("All time popular")}</a>
            </li>
            {window.COUNTRY_CODE && window.COUNTRY_CODE === 'IS' &&
              <li className="nav-item">
                <a className="nav-link" href={`/tag/is/`}>{gettext("Songs in Icelandic")}</a>
              </li>
            }
            {window.COUNTRY_CODE && window.COUNTRY_CODE === 'DK' &&
              <li className="nav-item">
                <a className="nav-link" href={`/tag/da/`}>{gettext("Songs in Danish")}</a>
              </li>
            }
            <li className="nav-item">
              <a className="nav-link" href={`/tag/en/`}>{gettext("Songs in English")}</a>
            </li>
          </ul>
        }
      </React.Fragment>
    )
  }
}

SearchContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    search: {},
    songbooks: {},
    user: {},
    collectionRelation: {},

  }
  f.search = (state.search === undefined) ? [] : state.search
  f.songbooks = (state.songbooks === undefined) ? [] : state.songbooks
  f.user = (state.user === undefined) ? [] : state.user
  f.collectionRelation = (state.collectionRelation === undefined) ? [] : state.collectionRelation
  return f;
}

export default connect(mapStateToProps)(SearchContainer);