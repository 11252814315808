import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'

export const toplistTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'TOPLIST');
export const allToplistTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'TOPLISTS');


export const requestToplist = () => {
    return {
        type: toplistTypes.REQUEST,
    };
};

export const receiveToplist = (response, listType) => {
    return {
        type: toplistTypes.RECEIVE,
        data: response,
        listType: listType
    };
};

export const receiveToplistError = () => {
    return {
        type: toplistTypes.ERROR,
    };
};

export const fetchToplist = (type) => {
    return dispatch => {
        dispatch(requestToplist());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
        }

        let url = `/api/v3/core/toplist/${type}`


        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveToplist(response, type));
            } catch (e) {
                dispatch(receiveToplistError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const requestToplists = () => {
    return {
        type: allToplistTypes.REQUEST,
    };
};

export const receiveToplists = (response, listType) => {
    return {
        type: allToplistTypes.RECEIVE,
        data: response,
        listType: listType
    };
};

export const receiveToplistsError = () => {
    return {
        type: allToplistTypes.ERROR,
    };
};

export const fetchToplists = (type) => {
    return dispatch => {
        dispatch(requestToplists());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
        }

        let url = `/api/v3/core/toplists`


        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveToplists(response, type));
            } catch (e) {
                dispatch(receiveToplistsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};