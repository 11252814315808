import React from 'react';
import Slider from '../rangeslider'
import {openModal} from '../../api/modals/actions'

class SongSizeSelector extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            fontSize: this.props.fontSize
        }
    }

    handleOnChange(value){
        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "font-size"))
        }
        else {
            this.setState({
            fontSize: value
            })
        }
    }

    handleOnChangeComplete(e){
        this.props.onChange(this.state.fontSize);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.fontSize !== prevProps.fontSize) {
            this.setState({fontSize: this.props.fontSize})
        }
    }

    render() {
        let { fontSize } = this.state
        return (
          <Slider
            value={fontSize}
            onChange={this.handleOnChange.bind(this)}
            onChangeComplete={this.handleOnChangeComplete.bind(this)}
            min={0}
            max={4}
            tooltip={false}
            labels={{0:<span className="label-small">A</span>, 100:<span className="label-large">A</span>}}
          />
        )
    }
}

SongSizeSelector.defaultProps = {
    fontSize: 2
}

  export default SongSizeSelector;