import React from 'react';
import { openModal } from '../../api/modals/actions';



const trans = [
    gettext('Original capo'),
    gettext("Capo on fret"),
]

class CapoPreference extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            keys: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
            subMenuOpen: false,
        }

        this.wrapperRef = React.createRef();
        this.btnRef = React.createRef();
        this.handleClickOutsideSubMenu = this.handleClickOutsideSubMenu.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideSubMenu);
    }


    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideSubMenu);
    }

    handleClickOutsideSubMenu(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !this.btnRef.current.contains(event.target)) {
            this.setState({
                subMenuOpen: false,
            })
        }
    }

    setCapo(id, capo){
        this.props.setCapo(id, capo)
        this.setState({subMenuOpen:!this.state.subMenuOpen})
    }

    toggleSubmenu(){
        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "set-capo"))
        }
        else {
            this.setState({subMenuOpen:!this.state.subMenuOpen})
        }
    }

    render() {
        const { currentCapo, originalCapo, id } = this.props
        let btnClasses = "btn btn-icon btn-tones-sub"
        if (currentCapo !== originalCapo) {
            btnClasses = `${btnClasses} transposed`
        }
        return (
            <>  
            {this.props.type == 'single-song' ?            
                <button ref={this.btnRef} className={btnClasses} onClick={() => this.toggleSubmenu()}>
                    <div className="svg-container">
                        <div className="transposer">
                            <div className="currentCapo">{currentCapo}</div>
                        </div>
                    </div>
                    <p className="d-none d-md-block">{gettext("Capo")}</p>
                </button> : <button ref={this.btnRef} className={btnClasses} onClick={() => this.toggleSubmenu()}>{currentCapo} </button>

            }
                <div ref={this.wrapperRef} className={`tones-sub ${this.state.subMenuOpen === true ? 'open' : ''}`}>
                    {this.state.keys.map((capo)=>
                        <div className="col-4 p-0 my-1"><button title={capo===originalCapo?gettext('Original key'):''}className={`btn btn-outline btn-tones ${capo===currentCapo?'active':''} ${capo===originalCapo?'original':''}`} onClick={() => this.setCapo(id, capo)}>{capo}</button></div>
                    )}
                </div>
            </>
        )
    }
}

CapoPreference.defaultProps = {
    currentCapo: null,
    originalCapo: null,
    heading: null,
}


export default CapoPreference;