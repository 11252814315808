import React from "react";
import { connect } from 'react-redux'
import { SvgSongbookPdfChordsOnly, SvgSongbookPdfChordsAndGrips } from '../svg'
import { setPdfoption } from "../../api/songbook-pdf/actions";

import { PaymentInputsContainer, PaymentInputsWrapper } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';


import { SvgGuitarPartyLogo } from '../svg';
import { subscribe, createTemporaryToken, updateCreditcard, fetchTokenStatus } from "../../api/subscription/actions";
import { timers } from "jquery";
import { formatISK, formatUSD } from "../../utils";
import { user } from "../../api/user/reducers";

const errorMessages = {
  'Enter a card number': 'Sláið inn kortanúmer',
  'Card number is invalid': 'Kortanúmerið er ógilt',
  'Card must be Visa or Mastercard': 'Kort verður að vera Visa eða Mastercard',
  'Enter an expiry date': 'Sláið inn gildistíma',
  'Expiry month must be between 01 and 12': 'Mánuður í gilditíma skal vera á milli 0-12',
  'Expiry year cannot be in the past': 'Ártal gildistíma má ekki vera liðið',
  'Expiry date cannot be in the past': 'Dagsetning gildistíma má ekki vera liðin',
  'Expiry date is invalid': 'Gildistími er ógildur',
  'Enter a CVC': 'Sláið inn öryggisnúmer',
  'CVC is invalid': 'Öryggisnúmer er ógilt',
}


class PaymentInformation extends React.Component {
  constructor(props) {
    super(props)

    this.timer = null

    this.state = {
      cardObj: {
        // cardNumber: '',
        // expiryDate: '',
        // cvc: '',
        // cardholderName: '',
      },
      planCurrency: 'USD',
      planInterval: 'month',
      display: 'cardform'
    }

    this.timer = null

    this.handleChange = this.handleChange.bind(this)
    // this.removeCard = this.removeCard.bind(this)
    this.authorizeCard = this.authorizeCard.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setCurrency = this.setCurrency.bind(this)
    this.setInterval = this.setInterval.bind(this)
    this.formDisabled = this.formDisabled.bind(this)

    this.cardNumberValidator = this.cardNumberValidator.bind(this)
  }


  handleChange(e) {
    this.setState({
      cardObj: { ...this.state.cardObj, [e.target.name]: e.target.value }
    })
  }

  onSubmit() {

  }

  componentDidMount() {
    if (window.LANGUAGE_CODE == "is") {
      this.setState({ planCurrency: 'ISK' })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.subscription.tokenStatus === 'tokencreated' && (this.props.subscription.tokenStatus !== prevProps.subscription.tokenStatus)) {
      if (this.props.user.hasSubscription) {
        //this.props.dispatch(updateCreditcard(this.props.user.id, this.props.subscription.tempToken, this.state.cardObj))
        Promise.resolve(this.props.dispatch(updateCreditcard(this.props.user.id, this.props.subscription.tempToken, this.state.cardObj)))
        .then(response => {
          clearInterval(this.timer)
          this.setState({display: 'done'})
        })
      } else {
        let currentPlan = this.props.plans.data.find(p => p.currency === this.state.planCurrency && p.interval === this.state.planInterval)
        //this.props.dispatch(subscribe(currentPlan.plan_id, this.props.subscription.tempToken, this.state.cardObj))
        Promise.resolve(this.props.dispatch(subscribe(currentPlan.plan_id, this.props.subscription.tempToken, this.state.cardObj)))
        .then(response => {
          clearInterval(this.timer)
          this.setState({display: 'done'})
        })
      }
    }
    if (this.props.subscription.tokenStatus === 'failed' && (this.props.subscription.tokenStatus !== prevProps.subscription.tokenStatus)) {
      this.setState({display: 'error'})
      clearInterval(this.timer)
    }
  }

  // removeCard() {
  //   this.setState({
  //       ccNumberHasBeenSaved: false,
  //   })
  //   this.props.dispatch(removeTempCC(this.props.payerId))
  // }

  isExpiryDateValid(expiryDate) {
    let valid = true;
    if (expiryDate && (expiryDate.length < 7 || !expiryDate.includes("/"))) {
      valid = false;
    }
    return valid
  }

  isCardNumberValid(cardnumber) {
    let valid = true
    if (cardnumber && cardnumber === "") {
      valid = false
    }
    return valid
  }

  cardNumberValidator({cardNumber, cardType, errorMessages}){
    if(cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard'){
      return;
    }
    return 'Card must be Visa or Mastercard';
  }

  isCvcValid(cvc) {
    let valid = true
    if (cvc && cvc.length < 3) {
      valid = false
    }
    return valid
  }

  authorizeCard() {
    if (this.props.user.hasSubscription) {
      Promise.resolve(this.props.dispatch(createTemporaryToken(this.state.cardObj, this.props.subscription.data.plan.id))).then(response => {
        this.setState({display: 'iframe'})
        if ( this.timer === null) {
          this.timer = setInterval(() => this.props.dispatch(fetchTokenStatus(this.props.subscription.tempToken)), 1000)
        }

        //this.props.dispatch(updateCreditcard(this.props.user.id, this.props.subscription.tempToken, this.state.cardObj))
      })
    } else {
      let currentPlan = this.props.plans.data.find(p => p.currency === this.state.planCurrency && p.interval === this.state.planInterval)
      Promise.resolve(this.props.dispatch(createTemporaryToken(this.state.cardObj, currentPlan.plan_id))).then(response => {
        this.setState({display: 'iframe'})
        if ( this.timer === null) {
          this.timer = setInterval(() => this.props.dispatch(fetchTokenStatus(this.props.subscription.tempToken)), 1000)
        }
        //this.props.dispatch(subscribe(currentPlan.plan_id, this.props.subscription.tempToken, this.state.cardObj))
      })
    }
  }

  setCurrency(currency) {
    this.setState({ planCurrency: currency })
  }

  setInterval(interval) {
    this.setState({ planInterval: interval })
  }

  formDisabled(meta) {
    return (meta.erroredInputs.cardNumber !== undefined || meta.erroredInputs.cvc !== undefined || meta.erroredInputs.expiryDate !== undefined || this.state.cardObj.cardholderName === '' || this.props.subscription.isFetching)
  }

  render() {

    let currentPlan = this.props.plans.data.find(p => p.currency === this.state.planCurrency && p.interval === this.state.planInterval)

    return (
      <>
        <div className="modal-body payment-info-modal">
          <a className="logo-container" href="/">
            <SvgGuitarPartyLogo />
          </a>
          {this.props.stepIndicator}
          <div className="row justify-content-center mb-5">
            <div className="col-8">
              <h5 className="modal-title" id="exampleModalLabel">{gettext('Payment information')}</h5>
            </div>
          </div>
          { this.props.user.hasSubscription ?
            <>
              <div className="row justify-content-center subscription-interval mb-5">
                <div className="col-md-8 col-16 text-center">
                  <p>{gettext('Your current subscription')}:</p>
                  <p>{this.props.user.subscription.plan.name}</p>
                  {this.props.user.subscription.plan.currency === "ISK" ?
                    <p>{formatISK(this.props.user.subscription.plan.amount)} {this.props.user.subscription.plan.currency}</p>
                    :
                    <p>{formatUSD(this.props.user.subscription.plan.amount)} {this.props.user.subscription.plan.currency}</p>
                  }
                  <p>{gettext('Fill in the details below to update your credit card registered with us.')}</p>
                </div>
              </div>
            </>
            :
            <>
              <div className="row justify-content-center subscription-interval mb-5">
                <div className="col-md-4 col-8 text-center">
                  <a className={currentPlan.interval === "month" ? "active" : ""} onClick={() => this.setInterval('month')}>{gettext('Monthly')}</a>
                </div>
                <div className="col-md-4 col-8 text-center">
                  <a className={currentPlan.interval === "year" ? "active" : ""} onClick={() => this.setInterval('year')}>{gettext('Yearly')}</a>
                </div>
              </div>
              <div className="row justify-content-center subscription-description">
                <div className="col-xl-8 col-md-12 col-16 title">
                  <div className="borders">
                    <div className="title-inner">
                      {currentPlan.name}
                      {this.props.user.trialAllowed && currentPlan.trial_period_days > 0 &&
                        <>
                          -
                        {interpolate(ngettext('Trial for %s day', 'Trial for %s days', currentPlan.trial_period_days), [currentPlan.trial_period_days])}
                        </>
                      }
                    </div>
                    {this.state.planCurrency === "ISK" ?
                      <div>
                        {formatISK(currentPlan.amount)} kr. <span> {currentPlan.interval === "month" && gettext('per month')}{currentPlan.interval === "year" && gettext('per year')}</span>
                      </div>
                      :
                      <div>
                        ${formatUSD(currentPlan.amount)}<span> {currentPlan.interval === "month" && gettext('per month')}{currentPlan.interval === "year" && gettext('per year')}</span>
                      </div>
                    }
                  </div>
                  <div>
                    <small>{currentPlan.interval === "year" && <>{gettext("You save 1 month!")}</>}</small>
                  </div>
                  <div className="d-flex justify-content-end">
                    <div className="dropdown">
                      <button type="button" className="btn btn-outline-light dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{gettext('Select currency')}</button>
                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        {this.props.plans.data.filter(f => f.interval === currentPlan.interval).map(p => {
                          if (p.currency !== this.state.planCurrency) {
                            return <a className="dropdown-item" onClick={() => this.setCurrency(p.currency)}>{p.currency}</a>
                          }
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          }
          { this.state.display === 'cardform' &&
          <PaymentInputsContainer cardNumberValidator={this.cardNumberValidator}>
            {({ meta, getCardImageProps, getCardNumberProps, getExpiryDateProps, getCVCProps }) => {
              return <form onSubmit={this.onSubmit} className="login ">
                <div className="cardholder row justify-content-center">
                  <div className="col-xl-6 col-md-8 col-11">
                    <label htmlFor="cardholder-name">{gettext('Cardholder name')}</label>
                    <input id="cardholder-name" placeholder={gettext('Cardholder name')} className="form-control" onChange={this.handleChange} name="cardholderName" value={this.state.cardObj.cardholderName} />
                    {this.state.cardObj.cardholderName === "" && <div className="error-msg">{gettext('Name can not be empty')}</div>}
                  </div>
                  <div className="col-xl-2 col-md-4 col-5">
                    <label htmlFor="expiry-date">{gettext('Expires')}</label>
                    <input id="expiry-date" placeholder={gettext('Expires')} className="form-control" {...getExpiryDateProps({ onChange: this.handleChange })} value={this.state.cardObj.expiryDate} name="expiryDate" />
                    {meta.erroredInputs.expiryDate !== undefined && <div className="error-msg">{gettext('Four digits')}</div>}
                  </div>
                </div>,

                <div className="payment-info row justify-content-center mx-0">
                  <div className="col-xl-6 col-md-8 col-11 cardnr-col">
                    {/* <svg {...getCardImageProps({ images })} /> */}
                    <label htmlFor="creditcard-number" >{gettext('Card number')}</label>
                    <input id="creditcard-number" placeholder={gettext('Card number')} className="form-control cardnumber" {...getCardNumberProps({ onChange: this.handleChange })} value={this.state.cardObj.cardNumber} name="cardNumber" placeholder="Kortanúmer" />
                    {meta.error && meta.isTouched && <div className="error-msg">{errorMessages[meta.error]}</div>}
                  </div>
                  <div className="col-xl-2 col-md-4 col-5">
                    <label htmlFor="cvc">{gettext('CVC')}</label>
                    <input id="cvc" className="form-control" placeholder={gettext('CVC')} {...getCVCProps({ onChange: this.handleChange, className: "form-control" })} value={this.state.cardObj.cvc} name="cvc" />
                    {meta.erroredInputs.cvc !== undefined && <div className="error-msg">{gettext('Three digits')}</div>}
                  </div>

                  {this.props.subscription.error &&
                    <div className="payment-info row justify-content-center mx-0">
                      <div className="alert alert-danger">{this.props.subscription.error}</div>
                    </div>
                  }
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-2 col-8 p-0">
                    <button type="button" className="btn btn-primary xs-mt-10 pay" disabled={this.formDisabled(meta)} onClick={this.authorizeCard}>
                      {this.props.subscription.isFetching && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>}
                      {this.props.user.hasSubscription ? gettext('Update credit card') : gettext('Subscribe')}
                    </button>
                  </div>
                </div>

              </form>
            }}
          </PaymentInputsContainer>
          }
          { this.state.display === 'iframe' &&
            <div className="row justify-content-center subscription-iframe-container">
              <div className="col-xl-8 col-md-12 col-16 title">
                <iframe src={this.props.subscription.cardVerificationUrl} />
                {this.props.subscription.error &&
                    <div className="payment-info row justify-content-center mx-0">
                      <div className="alert alert-danger">{this.props.subscription.error}</div>
                    </div>
                  }

              </div>
            </div>
          }
          { this.state.display === 'done' &&
            <div className="row justify-content-center subscription-iframe-container">
              <div className="col-xl-8 col-md-12 col-16 title">
                {gettext('Your credit card has been authenticated and saved.')}
                {this.props.subscription.error &&
                    <div className="payment-info row justify-content-center mx-0">
                      <div className="alert alert-danger">{this.props.subscription.error}</div>
                    </div>
                  }

              </div>
            </div>
          }
          { this.state.display === 'error' &&
            <div className="row justify-content-center subscription-iframe-container">
              <div className="col-xl-8 col-md-12 col-16 title">
                  <div className="payment-info row justify-content-center mx-0">
                    <div className="alert alert-danger">{gettext("We encountered an error during the authentication process. Please try again or contact support if the problem persists.")}</div>
                  </div>
              </div>
            </div>
          }

        </div>
      </>
    )
  }
}

export default PaymentInformation;