import React from 'react';
import GuitarpartyModal from '../modal'
import { closeModal } from '../../api/modals/actions'
import { store } from '../../store'
import { connectWithStore } from '../../utils'


class ModalUserSongs extends React.Component {
    constructor() {
        super();
    }
    
    render() {
        const songStatus = {'rejected':'Hafnað', 'pending': 'Í yfirferð', 'publish': 'Samþykkt'}
        return (
            <GuitarpartyModal extraClasses="modal-usersongs" modalKey="modal-usersongs"  {...this.props}>
                <div className="text-container">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal("modal-usersongs"))}>
                        <span></span>
                        <span></span>
                    </button>
                    <div className="row justify-content-center">
                        {this.props.user.contribSongs && this.props.user.contribSongs.map((song) => {
                            return (<div className="col-12"><a href={window.location.origin + "/songs/" + song.slug}>{song.title}</a><span> ({songStatus[song.status]})</span></div>)
                        })}
                    </div>
                </div>
            </GuitarpartyModal>
        )
    }
}



// export default withModal(ModalSongbook);

function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user

    return f;
}

export default connectWithStore(store, ModalUserSongs, mapStateToProps);