import { songbookPdfStepTypes } from './actions'

import React from 'react';

export function songbookPDF(state = {
    isFetching: false,
    selectedInstrument: 'nochords',
    selectedColor: 'red',
    songsPerPage: 1,
    selectedLayout: 'a4Port',
}, action) {
    switch (action.type) {
        case songbookPdfStepTypes.SET_PDF_OPTION: 
            state = { ...state }
            state[action.key] = action.value
            return state

        case songbookPdfStepTypes.SET_CHORDS:
            state = { ...state }
            state.stepIndex = action.data
            return state
        
        default:
            return state
    }

}

