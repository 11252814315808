import React from 'react';
import Modal from 'react-modal'
import { store } from '../../store'
import { connectWithStore, stepIndicator } from '../../utils'
import { openModal, closeModal } from '../../api/modals/actions';
import { setStep } from '../../api/step-renderer/actions'


class GuitarpartyModal extends React.Component {
  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal() {
    this.props.dispatch(openModal(this.props.modalKey))
    if(this.props.modalKey.startsWith('modal-songbook')) {
      this.props.dispatch(setStep(0))
    }
  }

  afterOpenModal() {
    animateLabels();
    slideLabelsUp();

    if (window.Intercom) {
      if (this.props.modalKey === "modal-paywall") {
        var metadata = {}
        if (this.props.modals && this.props.modals["modal-paywall-message"]) {
            metadata['source'] = this.props.modals["modal-paywall-message"]
        }
        
        window.Intercom('trackEvent', 'hit-paywall', metadata);
      }
      if (this.props.modalKey === "modal-subscription") {
        window.Intercom('trackEvent', 'clicks-buy-subscription-button');
      }
    }
  }

  closeModal() {
    this.props.dispatch(closeModal(this.props.modalKey))
  }


  render() {
    // ... and renders the wrapped component with the fresh data!
    // Notice that we pass through any additional props
    let isOpen = false
    if (this.props.modals && Object.keys(this.props.modals).includes(this.props.modalKey)) {
      isOpen = this.props.modals[this.props.modalKey]
    }
    return (
      <React.Fragment>
            { this.props.showButton &&
              <button className={`modal-opener btn button-${this.props.extraClasses} ${this.props.buttonClasses}`} onClick={this.openModal}><span className="text">{this.props.buttonText}</span> <span className="icon">{this.props.icon}</span></button>
            }
            <Modal
                className={this.props.extraClasses}
                isOpen={isOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={this.props.style}
                contentLabel={this.props.title}
                ariaHideApp={false}
                shouldCloseOnOverlayClick={true}
                >
                {this.props.children}
            </Modal>
        </React.Fragment>
    )
  }
}

GuitarpartyModal.defaultProps = {
  buttonClasses: ""
}


// export default withModal(ModalSongbook);

function mapStateToProps(state) {
  var f = {
    modals: {},
  }
  
  f.modals = (state.modals === undefined) ? [] : state.modals

  return f;
}

export default connectWithStore(store, GuitarpartyModal, mapStateToProps);