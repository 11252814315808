import React from "react";
import ReactDOM from "react-dom";
import { fetchSubscription } from '../../api/subscription/actions'
import { fetchUser } from '../../api/user/actions'
import { connect } from 'react-redux'


import Subscription from '../../components/subscription'


class SubscriptionContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    Promise.resolve(this.props.dispatch(fetchUser()))
    .then(() => {
      if(this.props.user.subscription.subscription_id !== null && this.props.user.subscription.subscription_id !== undefined && this.props.user.subscription.subscription_id !== '') {
        this.props.dispatch(fetchSubscription(this.props.user.subscription.subscription_id))
      }
    })
    // Promise.resolve(this.props.dispatch(fetchSubscription()))
    // .then(() => {
    //   this.props.dispatch(fetchUser())
    // })
    // .then(() => {
    //   this.props.dispatch(fetchInstruments())
    // })
    // .then(() => {
    //   this.props.dispatch(fetchSongLang())
    // })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.darkMode !== prevProps.darkMode && this.props.darkMode) {
            $('body').addClass("dark-mode");
        }
    }

  render() {
    const { subscription, instruments, uiPreference, instrumentPreference, user, songLang, passwordUpdate } = this.props

    return (
        <Subscription subscription={subscription} user={user} dispatch={this.props.dispatch} />
    )
  }
}

SubscriptionContainer.defaultProps = { } 

function mapStateToProps(state) {
    var f = {
        subscription: {},
        user: {},
    }

    f.subscription = (state.subscription === undefined) ? {} : state.subscription
    f.user = (state.user === undefined) ? {} : state.user

    return f
}

export default connect(mapStateToProps)(SubscriptionContainer);