import React from 'react';
import { connectWithStore } from '../../utils'
import { store } from '../../store'
import {openModal} from '../../api/modals/actions'

class ChordColorSelector extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            selectedOption: props.chordColor
        }
    }

    handleOptionChange(changeEvent) {
        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "chord-color"))
        }
        else {
            this.setState({
            selectedOption: changeEvent.target.value
            });
            this.props.onChange(changeEvent.target.value)
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.chordColor !== prevProps.chordColor) {
            this.setState({selectedOption: this.props.chordColor})
        }
    }

    render() {
        const { color } = this.props.chordColor
        return (
            <form className="chord-color-selector">
                <label className={`radio blue ${this.state.selectedOption === 'blue' ? 'active' : ''}`}>
                    <input type="radio" value="blue" 
                           checked={this.state.selectedOption === 'blue'}
                           onChange={this.handleOptionChange.bind(this)} />
    
                </label>
                <label className={`radio orange ${this.state.selectedOption === 'orange' ? 'active' : ''}`}>
                    <input type="radio" value="orange" 
                           checked={this.state.selectedOption === 'orange'}
                           onChange={this.handleOptionChange.bind(this)} />
    
                </label>
                <label className={`radio black ${this.state.selectedOption === 'black' ? 'active' : ''}`}>
                    <input type="radio" value="black" 
                           checked={this.state.selectedOption === 'black'}
                           onChange={this.handleOptionChange.bind(this)} />
    
                </label>
                <label className={`radio purple ${this.state.selectedOption === 'purple' ? 'active' : ''}`}>
                    <input type="radio" value="purple" 
                           checked={this.state.selectedOption === 'purple'}
                           onChange={this.handleOptionChange.bind(this)} />
    
                </label>
                <label className={`radio red ${this.state.selectedOption === 'red' ? 'active' : ''}`}>
                    <input type="radio" value="red" 
                           checked={this.state.selectedOption === 'red'}
                           onChange={this.handleOptionChange.bind(this)} />
    
                </label>
            </form>
        )
    }
}

ChordColorSelector.defaultProps = {
    chordColor: 'blue'
}

  export default ChordColorSelector;