import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'

export const submitSongTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'SUBMIT_SONG');
export const parseSongTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'PARSE_SONG');


export const requestSubmitSong = () => {
    return {
        type: submitSongTypes.REQUEST,
    };
};

export const receiveSubmitSong = (response, key) => {
    return {
        type: submitSongTypes.RECEIVE,
        data: response,
    };
};

export const receiveSubmitSongError = () => {
    return {
        type: submitSongTypes.ERROR,
    };
};

export const requestParseSong = () => {
    return {
        type: parseSongTypes.REQUEST,
    };
};

export const receiveParseSong = (response) => {
    return {
        type: parseSongTypes.RECEIVE,
        data: response,
    };
};

export const receiveParseSongError = () => {
    return {
        type: parseSongTypes.ERROR,
    };
};

export const submitSong = (song, artist, norobot) => {
    return dispatch => {
        dispatch(requestSubmitSong());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        }

        let url = `/api/v3/core/submit-song/`


        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"song" : song, "artist": artist, 'norobot': norobot})
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveSubmitSong(response));
            } catch (e) {
                dispatch(receiveSubmitSongError(response))
            }


        })
        .catch(handleNetworkError);
    };
};


export const parseSong = (song) => {
    return dispatch => {
        dispatch(requestParseSong());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        }

        let url = `/api/v3/core/parse-song/`

        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"song" : song})
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveParseSong(JSON.parse(response)));
            } catch (e) {
                dispatch(receiveParseSongError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
