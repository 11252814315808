import React from "react";
import { connect } from 'react-redux'
import { SvgSongbookPdfChordsOnly, SvgSongbookPdfChordsAndGrips } from '../svg'
import { setPdfoption } from "../../api/songbook-pdf/actions";

import { PaymentInputsContainer, PaymentInputsWrapper } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';


import { SvgGuitarPartyLogo } from '../svg';
import { subscribe } from "../../api/subscription/actions";
import { formatISK, formatUSD } from "../../utils";
import { closeModal } from "../../api/modals/actions";


class PaymentDone extends React.Component {
  constructor(props) {
    super(props)

    this.timer = null

    this.state = {
      cardObj: {
        cardNumber: '',
        expiryDate: '',
        cvc: '',
        cardholderName: '',
      },
      planCurrency: 'USD'
    }

    this.handleChange = this.handleChange.bind(this)
    // this.removeCard = this.removeCard.bind(this)
    this.authorizeCard = this.authorizeCard.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setCurrency = this.setCurrency.bind(this)
    this.formDisabled = this.formDisabled.bind(this)
  }


  handleChange(e) {
    this.setState({
      cardObj: { ...this.state.cardObj, [e.target.name]: e.target.value }
    })
  }

  onSubmit() {

  }

  componentDidMount() {
    if (window.LANGUAGE_CODE == "is") {
      this.setState({ planCurrency: 'ISK' })
    }
  }

  // removeCard() {
  //   this.setState({
  //       ccNumberHasBeenSaved: false,
  //   })
  //   this.props.dispatch(removeTempCC(this.props.payerId))
  // }

  authorizeCard() {
    let currentPlan = this.props.plans.data.find(p => p.currency === this.state.planCurrency)
    this.props.dispatch(subscribe(currentPlan.plan_id, this.state.cardObj))
  }

  setCurrency(currency) {
    this.setState({ planCurrency: currency })
  }

  formDisabled(meta) {
    return (meta.erroredInputs.cardNumber !== undefined || meta.erroredInputs.cvc !== undefined || meta.erroredInputs.expiryDate !== undefined || this.state.cardholderName === '' || this.props.subscription.isFetching)
  }

  render() {

    let currentPlan = this.props.plans.data.find(p => p.currency === this.state.planCurrency)

    return (
      <>
        <div className="modal-body payment-info-modal payment-done">
          <a className="logo-container" href="/">
            <SvgGuitarPartyLogo />
          </a>
          {this.props.stepIndicator}
          <div className="row justify-content-center">
            <div className="col-8">
              <h5 className="modal-title" id="exampleModalLabel">{gettext("You're subscribed!")}</h5>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-8">
              {this.props.user.hasSubscription ?
                <p>
                  {gettext('Your credit card has been updated.')}
                </p>
                :
                <>
                  {this.props.user.trialAllowed && currentPlan.trial_period_days > 0 ?
                    <>
                      {currentPlan.interval === "month" ?
                        <p>{interpolate(ngettext('We will charge your card in %s day after your trial and every month after that.', 'We will charge your card in %s days after your trial and every month after that.', currentPlan.trial_period_days), [currentPlan.trial_period_days])} {gettext('To stop the subscription, check out the settings page.')}</p>
                        :
                        <p>{interpolate(ngettext('We will charge your card in %s day after your trial and every year after that.', 'We will charge your card in %s days after your trial and every year after that.', currentPlan.trial_period_days), [currentPlan.trial_period_days])} {gettext('To stop the subscription, check out the settings page.')}</p>
                      }
                    </>
                    :
                    <>
                      {currentPlan.interval === "month" ?
                        <p>{gettext('We will charge your card monthly from now on.')} {gettext('To stop the subscription, check out the settings page.')}</p>
                        :
                        <p>{gettext('We will charge your card yearly from now on.')} {gettext('To stop the subscription, check out the settings page.')}</p>
                      }
                    </>
                  }
                </>}
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="btn" onClick={() => this.props.dispatch(closeModal("modal-subscription"))}>{gettext('Close')}</button>
        </div>
      </>
    )
  }
}

export default PaymentDone;