import React from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { openModal } from '../../api/modals/actions';
import { copySongbook, deleteSongbook, setSongbookPublic } from '../../api/songbooks/actions';
import { copyToClipboard, Spinner } from '../../utils';
import ModalSongbook from '../modal-songbook';
import { SvgCopy, SvgDropdownToggle, SvgPdf, SvgPlay, SvgTrash } from '../svg';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        // width: '400px',
        padding: 0
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.5)"
    }
};

let trans = [
    gettext("song"),
    gettext("songs"),
    gettext('Delete'),
    gettext('Duplicate'),
    gettext('Songbook access'),
    gettext('Public'),
    gettext('Private'),
    gettext('Share URL'),
    gettext('copied!'),
]



class SongbookListItem extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            dropdownOpen: false,
            copied: false,
        }

        this.toggle = this.toggle.bind(this)
        this.togglePublic = this.togglePublic.bind(this)
        this.showPDFModal = this.showPDFModal.bind(this)
        this.deleteSongbook = this.deleteSongbook.bind(this)
        this.duplicateSongbook = this.duplicateSongbook.bind(this)
    }

    toggle() {
        this.setState({ dropdownOpen: !this.state.dropdownOpen, copied: false })
    }

    togglePublic(e) {
        this.setState({ public: !this.state.public })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.songbook && this.state.public === undefined) {
            this.setState({ public: this.props.songbook.is_public })
        }
    }

    showPDFModal() {
        // return 
        this.setState({ pdfModalOpen: true })

    }


    deleteSongbook(id) {
        if (window.confirm(gettext("Are you sure you want to delete this songbook?"))) {
            this.props.dispatch(deleteSongbook(id))
        }
    }

    duplicateSongbook(id) {
        if (!this.props.user.hasSubscription && this.props.songbooks && this.props.songbooks.privateSongbooks.results.length >= 1) {
            this.props.dispatch(openModal("modal-paywall", "duplicate-songbook"))
        } else {
            this.props.dispatch(copySongbook(id, this.props.onlyPublic))
        }
    }

    render() {

        const { songbook, getSongbook, user, onlyPublic } = this.props
        return (
            <>
                {songbook && 
                    <div className="songbook row" key={`${this.props.index}-songbook-${onlyPublic ? 'public' : 'private'}-${songbook.id}`}>
                        {onlyPublic && !user.isAuthenticated ? 
                            <>
                                <div className="col-14">
                                    <h3 className="songbook-title">
                                        <Link to={{
                                            pathname: `/songbooks/${songbook.id}`,
                                            state: {
                                                onlyPublic: onlyPublic
                                            },
                                        }} >
                                            {songbook.title}
                                        </Link>
                                    </h3>
                                    <div className="songbook-songs">
                                        {songbook.song_ids.length} {songbook.song_ids.length % 10 == 1 ? gettext("song") : gettext("songs")} | {songbook.song_title_preview.slice(0, 15).join(" - ")}
                                    </div>
                                </div>
                                <div className="col-2 actions white">
                                    <Button color ="light">
                                        <a title={gettext("Play songbook")} onClick={() => this.props.dispatch(openModal("modal-paywall", "play-songbook"))}>
                                            <SvgPlay />
                                        </a>
                                    </Button>
                                </div>
                            </> 
                        : 
                            <>
                                <div className="col-md-12 col-8">
                                    <h3 className="songbook-title">
                                        <Link to={{
                                            pathname: `/songbooks/${songbook.id}`,
                                            state: {
                                                onlyPublic: onlyPublic
                                            },
                                        }} >{songbook.title}</Link></h3>
                                    <div className="songbook-songs">{songbook.song_ids.length} {songbook.song_ids.length % 10 == 1 ? gettext("song") : gettext("songs")} | {songbook.song_title_preview.slice(0, 15).join(" - ")}</div>
                                </div>
                                <div className="col-md-4 col-8 actions align-items-start white">
                                    <Button color="light">
                                        {user.hasSubscription ?
                                            <a title={gettext("Play songbook")} href={`/songbooks/${songbook.id}/play/?public=${onlyPublic}`}>
                                                <SvgPlay />
                                            </a>
                                            :
                                            <a title={gettext("Play songbook")} onClick={() => this.props.dispatch(openModal("modal-paywall", "play-songbook"))}>
                                                <SvgPlay />
                                            </a>
                                        }
                                    </Button>
                                    {user.hasSubscription ?
                                        <ModalSongbook showButton={true} icon={<SvgPdf />} style={customStyles} user={user} songbook={songbook}></ModalSongbook>
                                        :
                                        <button className="modal-opener btn button-modal-songbook" onClick={() => this.props.dispatch(openModal("modal-paywall", "pdf-songbook"))}><span className="text"></span> <span className="icon"><SvgPdf /></span></button>
                                    }
                                    <ButtonDropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                                        <DropdownToggle color="light" className="dropdown-btn">
                                            <SvgDropdownToggle />
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            {user.isAuthenticated && user.username === songbook.owner &&
                                                <DropdownItem onClick={() => this.deleteSongbook(songbook.id)}>
                                                    {gettext('Delete')}
                                                    <SvgTrash />
                                                </DropdownItem>
                                            }
                                            {user.hasSubscription &&
                                                <DropdownItem onClick={() => this.duplicateSongbook(songbook.id)}>
                                                    {gettext('Duplicate')}
                                                    <SvgCopy />
                                                </DropdownItem>
                                            }
                                            {user.isAuthenticated && user.username === songbook.owner &&
                                                <DropdownItem header>
                                                    {gettext('Songbook access')} { this.props.isFetching && <Spinner />}
                                                    {/* <CustomInput type="switch" id={`togglePublic-${songbook.id}`} name="customSwitch" label="" checked={state.public} onClick={e => this.setState({ public: e.target.checked })}/> */}
                                                    {/* <div className="custom-control custom-switch" onClick={togglePublic}>
                                                        <input type="checkbox" className="custom-control-input" checked={state.public} id={`togglePublic-${songbook.id}`} onChange={e => void (0)} />
                                                        <label className="custom-control-label" htmlFor={`togglePublic-${songbook.id}`}></label>
                                                    </div> */}
                                                    <div className="radio-container">
                                                        <label htmlFor="public">
                                                            <input id="public" type="radio" className="public-radio" checked={songbook.is_public} onClick={() => this.props.dispatch(setSongbookPublic(songbook.id, true))} />
                                                            {gettext('Public')}
                                                        </label>
                                                        <label htmlFor="personal">
                                                            <input id="personal" type="radio" className="public-radio" checked={!songbook.is_public} onClick={() => this.props.dispatch(setSongbookPublic(songbook.id, false))} />
                                                            {gettext('Private')}
                                                        </label>
                                                    </div>
                                                    {songbook.is_public &&
                                                        <> 
                                                            {gettext('Share URL')} {this.state.copied && <>{gettext('copied!')}</>}
                                                            <input onFocus={() => {this.setState({copied:true}); copyToClipboard(songbook.short_url)}} type="text" value={songbook.short_url} />
                                                    </>
                                                    }
                                                </DropdownItem>
                                            }
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </div>
                            </>
                        }
                    </div>
                }
            </>
        )
    }
}

export default SongbookListItem;
