import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const relatedSongsActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'RELATED_SONGS');

export const requestRelatedSongs = () => {
    return {
        type: relatedSongsActionTypes.REQUEST,
    };
};

export const receiveRelatedSongs = (response, key) => {
    return {
        type: relatedSongsActionTypes.RECEIVE,
        data: response,
    };
};

export const receiveRelatedSongsError = () => {
    return {
        type: relatedSongsActionTypes.ERROR,
    };
};

export const fetchRelatedSongs = (song_id) => {
    return dispatch => {

        dispatch(requestRelatedSongs());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v3/core/related-songs/?song_id=${song_id}`

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveRelatedSongs(response));
            } catch (e) {
                dispatch(receiveRelatedSongsError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
