import React from 'react';

import { songbookActionTypes } from './actions'
import { userActionTypes } from '../user/actions'
import moment from 'moment'
import { user } from '../user/reducers';
import { songActionTypes } from '../song/actions';


let _initialState = {
    isFetching: false,
    key: null,
    data: {
        results: []
    },
    privateSongbooks: {
        results:[] 
    },
    publicSongbooks: {
        results:[] 
    },
}

const sortFunction = (a, b) => {
    return moment(b.created)-moment(a.created)
}

export function songbooks(state = _initialState, action) {

    switch (action.type) {
        case songbookActionTypes.REQUEST:

            state = { ...state }
            state.isFetching = true

            return state

        case songbookActionTypes.RECEIVE:

            state = { ...state }
            state.isFetching = false

            if (!action.onlyPublic) {
                let privData = action.data
                if (action.songbookId) {
                    let elementsIndex = state.privateSongbooks.results.findIndex(element => element.id == action.songbookId )
                    state.privateSongbooks.results[elementsIndex] = privData
                } else {
                    state.privateSongbooks = {
                        'next': action.data.next,
                        'previous': action.data.previous,
                        'count': action.data.count,
                        'results': privData.results.sort(sortFunction)
                    }
                }
            } else {
                let pubData = action.data;

                if (action.songbookId) {
                    let elementsIndex = state.publicSongbooks.results.findIndex(element => element.id == action.songbookId )
                    state.publicSongbooks.results[elementsIndex] = pubData
                } else {
                    state.publicSongbooks = {
                        'next': action.data.next,
                        'previous': action.data.previous,
                        'count': action.data.count,
                        'results': pubData.results.sort(sortFunction)
                    }
                }
            }

            state.onlyPublic = action.onlyPublic
            if (action.onlyPublic) {
                state.searchText = action.searchQuery
            } else {
                state.searchTextPriv = action.searchQuery
            }

            return state
        case songbookActionTypes.RECEIVE_UPDATE:
            state = { ...state }

            state.privateSongbooks.results = state.privateSongbooks.results.map(s => {
                if (s.id === action.data.id) {
                    return action.data
                } else {
                    return s
                }
            })

            state.publicSongbooks.results = state.publicSongbooks.results.map(s => {
                if (s.id === action.data.id) {
                    return action.data
                } else {
                    return s
                }
            })

            return state

        case userActionTypes.LOGOUT:
            state = _initialState;
            return state
        
        case songbookActionTypes.SET_ACTIVE_SONGBOOKS:

            state = {
                ...state,
                "activeSongbook": action.songbookType
            }
            return state

        default:
            return state
    }

}

export function songbook(state = {
    isFetching: false,
    key: null,
    data: {},
    ordering: {},
}, action) {

    switch (action.type) {
        case songbookActionTypes.REQUEST_DETAIL:

            state = { ...state }
            state.isFetching = true
            return state


        case songbookActionTypes.RECEIVE_DETAIL:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        case songbookActionTypes.REQUEST_UPDATE:

            state = { ...state }
            state.data = state.data ? state.data : {}
            state.isFetching = true

            return state

        case songbookActionTypes.RECEIVE_UPDATE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        default:
            return state
    }

}