import React from 'react';
import Cookies from 'js-cookie'
import { store } from '../../store'
import { connectWithStore } from '../../utils';
import { SvgGuitarLessons, SvgHeart, SvgLogOut, SvgMySubscription, SvgSendSong, SvgSettings, SvgSongbookIcon, SvgStarFlag, SvgWishlist } from '../../components/svg'
import { fetchUser, userLogin, userLogout, userRefreshLogin } from '../../api/user/actions'
import {openModal} from '../../api/modals/actions'
import { fetchFavourites } from '../../api/favourite/actions'
import LanguageSelector from '../../components/language-selector'

// import ModalLogin from '../../components/modal-login'
// import ModalSubscription from '../../components/modal-subscription'
// import ReactDOM from 'react-dom';
// import { connect } from 'react-redux'
// import { receiveSongbookDetail } from '../../api/songbooks/actions';
// import { saveProfile } from '../../api/profile/actions'
// import { updateProfile } from '../../api/profile/actions';


// const customStyles = {
//   content: {
//     top: '50%',
//     left: '50%',
//     right: 'auto',
//     bottom: 'auto',
//     marginRight: '-50%',
//     transform: 'translate(-50%, -50%)',
//     width: '400px',
//     padding: 0
//   },
//   overlay: {
//     backgroundColor: "rgba(0, 0, 0, 0.5)"
//   }
// };


let trans = [
  gettext("Log in / Sign up"),
  gettext("Favourites"),
  gettext("Settings"),
  gettext("My subscription"),
  gettext("Top lists"),
  gettext("Songbooks"),
  gettext("Wishlist"),
  gettext("Guitar lessons"),
  gettext("Send us a song"),
  gettext("Log out"),
  gettext("Log in"),
  gettext("Buy subscription"),
  gettext("You must log in before you purchase a subscription"),
]

class UserbarContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    }
  }

  componentDidMount() {

    if(window.location.pathname == "/" && window.location.search.indexOf('verify=true')>=0){
      this.props.dispatch(openModal('modal-login'));
    }

    this.props.dispatch(fetchUser())
    this.props.dispatch(fetchFavourites())

    // setInterval(() => {
    //   this.props.dispatch(userRefreshLogin())
    // }, 15 * 60 * 1000)
  
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.darkMode !== prevProps.darkMode && this.props.darkMode && !this.props.profile.isFetching) {
      $('body').addClass("dark-mode");
    }

    const { user } = this.props;
    // User is logged in and has no email
    if (user.isAuthenticated && !user.email){
      let cookie = Cookies.get('has_email');
      // Cookie expired
      if (!cookie){ 
        this.props.dispatch(openModal("modal-add-email"))
      } 
    }
  }

  handleLogout(e) {
    this.props.dispatch(userLogout())
  }

  toggleHamburger(e){
    let isOpen = this.state.menuOpen
    if(isOpen){
      $('body').removeClass("menu-open");
    } else {
      $('body').addClass("menu-open");
      $('.song').removeClass('active');
      $('.song .tones>button').parent().find(".tones-sub").removeClass("open");
    }
    this.setState({ menuOpen: !isOpen })
  }

  render() {
    const { user } = this.props
    return (
      <>
        <div id="menu" className="menu">
          <div className="lang"><LanguageSelector /></div>
          <div className="login ml-auto">
            {user.isAuthenticated ?
              <span className="user-name"><a href={"/profile/"}>{user.username}</a></span>
              :
              <button className="modal-opener btn button-modal-login" onClick={() => this.props.dispatch(openModal("modal-login"))}><span className="text">{gettext("Log in / Sign up")}</span></button>
            }
          </div>
          <button className={`hamburger hamburger--slider ${this.state.menuOpen ? "is-active": ""}`} type="button" onClick={this.toggleHamburger.bind(this)}>
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div className={`menu-container${user.isAuthenticated ? " user-authenticated" : ""}`}>
          <div className="menu-wrapper">
            <ul className={`${!user.hasSubscription ? 'subscription-btn-visible' : ''}`}>
            {/* <ul className={"hello"}> */}
              { !user.hasSubscription &&
                <li className="menu__buy mt-auto">
                  { user.isAuthenticated ?
                    <button className="modal-opener btn button-modal-subscription" onClick={() => this.props.dispatch(openModal("modal-subscription"))}>{gettext("Buy subscription")}</button>
                    :
                    <button className="modal-opener btn button-modal-login" onClick={() => this.props.dispatch(openModal("modal-login", gettext("You must log in before you purchase a subscription")))}><span className="text">{gettext("Buy subscription")}</span></button>
                  }
                </li>
              }
              {user.isAuthenticated ?
                <>
                  <li className="menu__favourites"><a href="/favourites/"><SvgHeart />{gettext("Favourites")}</a></li>
                  <li className="menu__settings"><a href="/profile/"><SvgSettings /> {gettext("Settings")}</a></li>
                  <li className="menu__subscription"><a href="/subscription/"><SvgMySubscription />{gettext("My subscription")}</a></li>
                </>
                : <></>}
              <li className="menu__popular"><a href="/top/"><SvgStarFlag />{gettext("Top lists")}</a></li>
              <li className="menu__songbooks"><a href="/songbooks/"><SvgSongbookIcon />{gettext("Songbooks")}</a></li>
              <li className="menu__lessons"><a href="/lessons/"><SvgGuitarLessons />{gettext("Guitar lessons")}</a></li>
              {user.isAuthenticated &&
              <>
              {user.hasSubscription && <li className="menu__wishlist"><a href="/wishlist"><SvgWishlist />{gettext("Wishlist")}</a></li>}
              <li className="menu__send_song"><a href="/submit-a-song"><SvgSendSong />{gettext("Send us a song")}</a></li>
              <li className="menu__logout mt-auto log-out d-none d-xl-block"><button className="btn btn-logout" onClick={this.handleLogout.bind(this)}><SvgLogOut />{gettext("Log out")}</button></li>
            </>
              }
            </ul>
          </div>
            {user.isAuthenticated &&
              <>
                <div className="logout-container w-100 position-fixed d-flex d-xl-none justify-content-center"><div className="menu__buy mt-auto log-out"><button className="btn btn-buy" onClick={this.handleLogout.bind(this)}><SvgLogOut />{gettext("Log out")}</button></div></div>
              </>
            }
        </div>
      </>
    );
  }
}


function mapStateToProps(state) {

  var f = {
    user: {},
    profile: {},
  }
  f.user = (state.user === undefined) ? {} : state.user
  f.profile = (state.profile === undefined) ? {} : state.profile
  return f;
}

// export default connect(mapStateToProps)(UserbarContainer);
export default connectWithStore(store, UserbarContainer, mapStateToProps);


