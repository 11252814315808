import React from "react";
import { connect } from 'react-redux'
// import { store } from '../../store'
import { setStep, initStepper } from '../../api/step-renderer/actions'
import { setPdfoption } from '../../api/songbook-pdf/actions'
import { SONGBOOK_PDF_STEPS } from '../../constants'
import { SvgSongbookPdfTextOnly, SvgSongbookPdfChordsOnly, SvgDownload } from '../svg'

class PdfType extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount(){
    this.props.dispatch(setPdfoption('sbId', this.props.songbook.id))
    this.props.dispatch(setPdfoption('uId', this.props.user.id))
  }

  selectType(type) {
    // Set to props 
    this.props.dispatch(setPdfoption('pdfType', type))
    // Switch stepper type
    this.props.dispatch(initStepper(SONGBOOK_PDF_STEPS[type.toUpperCase()]))
  }

  render() {
    let type = this.props.songbookPDF.pdfType
    return (
      <>
        {this.props.songbook.pdf_file &&
          <>
            <a href={this.props.songbook.pdf_file} target="_blank" className="alert text-center"><SvgDownload/></a>
            <p>{gettext("Hey there! You already have a PDF copy of this songbook available.")}<br/>
            <span>{gettext("Click the PDF icon")}</span> {gettext("to download it, or create a new one below")}</p>
          </>
        }
        <div className="pdf-type">
          <h4 className="subtitle"> {gettext('Choose a type')}</h4>
          <div className="col-16 justify-content-center d-flex p-0">
            <div className="row mx-0">
              <div className="option">
                <p><b>{gettext('Lyrics PDF')}</b></p>
                <p>{gettext('For those who sing a long')}</p>
                <div className={type === "text" ? "active justify-content-center d-flex" : "justify-content-center d-flex"} onClick={this.selectType.bind(this, 'text')}>
                  <SvgSongbookPdfTextOnly></SvgSongbookPdfTextOnly>
                </div>
              </div>
              <div className="vertical-single"><div className="or-box">eða</div></div>
              <div className="option">
                <p><b>{gettext('Lyrics and chords PDF')}</b></p>
                <p>{gettext('For those who want to play and sing')}</p>
                <div className={type === "chord" ? "active justify-content-center d-flex" : "justify-content-center d-flex"} onClick={this.selectType.bind(this, 'chord')}>
                  <SvgSongbookPdfChordsOnly></SvgSongbookPdfChordsOnly>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  var f = {
    dispatch: {},
    songbookPDF: {},

  }
  f.dispatch = (state.dispatch === undefined) ? [] : state.dispatch
  f.songbookPDF = (state.songbookPDF === undefined) ? [] : state.songbookPDF
  return f;
}

export default connect(mapStateToProps)(PdfType);
