
import React from 'react'
import ReactDOM from 'react-dom/client'
import SubscriptionContainer from './subscription'
import { store } from '../../store'
import { Provider } from 'react-redux'

function Wrapper(props) {
    return (
        <Provider store={store}>
            <SubscriptionContainer {...props}/>
        </Provider>
    )
}

function initWishlistContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-subscription]');
  elements.forEach(initWishlistContainer);
});