import React from "react";
import SongList from '../song-list'

class Toplists extends React.Component {
    constructor(props) {
        super(props)

    }

    getToplists(toplists) {
        let allTopLists = []
        toplists.map((toplist) => {
            allTopLists.push(<>
                <div class="row justify-content-center top-list-row">
                    <div class="col-lg-10 col-xl-8 col-md-12 col-15">
                        {/* <div class="alert alert-warning">{gettext("Unfortunately the toplists are not working right now. We are working on a solution!")}</div> */}
                        <a href={`/top/${toplist.slug}`}>
                            <h1 class="stylish">{gettext(toplist.title)}</h1>
                        </a>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-lg-10 col-xl-8 col-md-12 col-15">
                        <SongList list={toplist.list} allFavourites={false} showExcerpt={true} toplistType={toplist.slug} showFilterInfo={false} />
                    </div>
                </div>
            </>)
        })
        return allTopLists
    }

    render() {
        const { toplists } = this.props
        return (
            this.props.toplists && this.getToplists(toplists)
        )
    }
}


export default Toplists;