import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, getCookie } from '../../utils'

export const submitWishItemTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'SUBMIT_WISHLIST_ITEM');
export const wishlistTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'WISHLIST');
export const userWishlistTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'USER_WISHLIST');
export const upvoteItemTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS'], 'UPVOTE_ITEM');


export const requestSubmitWishlistItem = () => {
    return {
        type: submitWishItemTypes.REQUEST,
    };
};

export const receiveSubmitWishlistItem = (response, key) => {
    return {
        type: submitWishItemTypes.RECEIVE,
        data: response,
    };
};

export const receiveSubmitWishlistItemError = () => {
    return {
        type: submitWishItemTypes.ERROR,
    };
};

export const submitWishlistItem = (song) => {
    return dispatch => {
        dispatch(requestSubmitWishlistItem());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        }

        let url = `/api/v3/core/submit-wishlist-item/`


        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"song" : song})
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveSubmitWishlistItem(response));
            } catch (e) {
                dispatch(receiveSubmitWishlistItemError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const requestWishlist = () => {
    return {
        type: wishlistTypes.REQUEST,
    };
};

export const receiveWishlist = (response, key) => {
    return {
        type: wishlistTypes.RECEIVE,
        data: response,
    };
};

export const receiveWishlistError = () => {
    return {
        type: wishlistTypes.ERROR,
    };
};

export const fetchWishlist = () => {
    return dispatch => {
        dispatch(requestWishlist());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
        }

        let url = `/api/v3/core/wishlists/`


        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveWishlist(response));
            } catch (e) {
                dispatch(receiveWishlistError(response))
            }


        })
        .catch(handleNetworkError);
    };
};

export const requestUserWishlist = () => {
    return {
        type: userWishlistTypes.REQUEST,
    };
};

export const receiveUserWishlist = (response, key) => {
    return {
        type: userWishlistTypes.RECEIVE,
        data: response,
    };
};

export const receiveUserWishlistError = () => {
    return {
        type: userWishlistTypes.ERROR,
    };
};

export const fetchUserWishlist = () => {
    return dispatch => {
        dispatch(requestUserWishlist());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        }

        let url = `/api/v3/core/wishlists/user_wishlist/`


        return fetch(url, {
            method: 'GET',
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveUserWishlist(response));
            } catch (e) {
                dispatch(receiveUserWishlistError(response))
            }


        })
        .catch(handleNetworkError);
    };
};


export const requestItemUpvote = () => {
    return {
        type: upvoteItemTypes.REQUEST,
    };
};

export const receiveItemUpvote = (response, key) => {
    return {
        type: upvoteItemTypes.RECEIVE,
        data: response,
    };
};

export const receiveItemUpvoteError = () => {
    return {
        type: upvoteItemTypes.ERROR,
    };
};

export const upvoteWishlistItem = (itemId) => {
    return dispatch => {
        dispatch(requestItemUpvote());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        }

        let url = `/api/v3/core/wishlists/upvote_item/`


        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({"item_id" : itemId})

        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveItemUpvote(response));
            } catch (e) {
                dispatch(receiveItemUpvoteError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
