import React from 'react';
import { connect } from 'react-redux';
import PaymentDone from '../components/modal-subscription/payment_done';
import PaymentInformation from '../components/modal-subscription/payment_information';
import ChordConfig from '../components/songbook-pdf-modal/chord_config';
import FinishPDF from '../components/songbook-pdf-modal/finish_pdf';
import PageSetup from '../components/songbook-pdf-modal/page_setup';
import PdfType from '../components/songbook-pdf-modal/pdf_type';
import TokenStorage from '../storages/token-storage';



export const Spinner = (props) => {
  /* props.size can be
     lg
     2x
     3x
     4x
     5x
  */
  let size = ''
  if (props.size) {
    size =`fa-${props.size}` 
  }
  if (props.inline) {
    return <i className={`spinner fa fa-spinner fa-pulse ${size}`} />
  }
  return <div><i className={`spinner fa fa-spinner fa-pulse ${size}`}></i></div>
};

export const parseJSON = response => {
  //console.log(response)
  try {
    return response.json();
  } catch (error) {
    return response
  }
}

export const handleNetworkError = (error) => {
    if (window.Raven !== undefined) {
      Raven.captureException(error)
    } else {
      throw (error)
    }
  }

export const renderKennitala = (value) => {
  return `${value.substr(0,6)}-${value.substr(6,10)}`
}

export const renderFloat = (value) => {
  if (value === null || value === undefined) {
    value = 0
  }
  return Number.parseFloat(value).toFixed(2)
}

export const renderPercentage = (value) => {
  if (value === null || value === undefined) {
    value = 0
  }
  let color = "";
  if (value < 0) {
    color = "percentage-red";
  }
  if (value > 0) {
    color = "percentage-green";
  }
  let formatted_value = `${renderFloat(value)}%`
  return <span className={color}>{formatted_value}</span>
}

export const renderFinancialShort = (value) => {
  const sufixes = ['', 'þ', 'm', 'ma'];
  const i = Math.floor(Math.log(value) / Math.log(1000));
  return !value && '0' || (value / Math.pow(1000, i)).toFixed(0) + "" + sufixes[i];
}

export const renderNumber = (value) => {
  if (value === null || value === undefined) {
    return "-"
  }
  return value
}

export const groupBy = (list, keyGetter) => {
  const map = new Map();
  list.forEach((item) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
          map.set(key, [item]);
      } else {
          collection.push(item);
      }
  });
  return map;
}

export const customStringify = (str) => {
  const cache = new Map();
  return JSON.stringify(str, function (key, value) {
      if (typeof value === 'object' && value !== null) {
          if (cache.has(value)) {
              // Circular reference found, discard key
              return;
          }
          // Store value in our map
          cache.set(value, true);
      }
      return value;
  });
};



export const buildPdfUrl = (companyId, year) => {
  return `/api/v1/pdf_report/?company_id=${companyId}&year=${year}`
}

export const buildDocumentPdfUrl = (documentId) => {
  return `/api/v1/pdf_document/?document_id=${documentId}`
}


export const base64ToArrayBuffer = (data) => {
  var binaryString = window.atob(data);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
  }
  return bytes;
};


export const getAnnualReportPDF = (companyId, year, authToken) => {
  let ajaxOptions = {
    url: buildPdfUrl(companyId, year), 
    headers: {
      Authorization: `jwt ${authToken}`
    },
    success: function (data) {
      var arrBuffer = base64ToArrayBuffer(data);
      var a = document.createElement('a');
      var blob=new Blob([arrBuffer], { type: "application/pdf" });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${companyId}-${year}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }
  $.ajax(ajaxOptions)
}

export const getDocumentPDF = (documentId, authToken) => {
  let ajaxOptions = {
    url: buildDocumentPdfUrl(documentId), 
    headers: {
      Authorization: `jwt ${authToken}`
    },
    success: function (data) {
      var arrBuffer = base64ToArrayBuffer(data);
      var a = document.createElement('a');
      var blob=new Blob([arrBuffer], { type: "application/pdf" });
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${documentId}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  }
  $.ajax(ajaxOptions)
}


export const toMoney = (n, decimals, decimal_sep, thousands_sep) => {
  let c = isNaN(decimals) ? 2 : Math.abs(decimals); //if decimal is zero we must take it, it means user does not want to show any decimal
  let d = decimal_sep || '.'; //if no decimal separator is passed we use the dot as default decimal separator (we MUST use a decimal separator)

  /*
    according to [http://stackoverflow.com/questions/411352/how-best-to-determine-if-an-argument-is-not-sent-to-the-javascript-function]
    the fastest way to check for not defined parameter is to use typeof value === 'undefined'
    rather than doing value === undefined.
  */
  let t = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep; //if you don't want to use a thousands separator you can pass empty string as thousands_sep value

  let sign = (n < 0) ? '-' : '';

  //extracting the absolute value of the integer part of the number and converting to string
  let i = parseInt(n = Math.abs(n).toFixed(c), 10) + '';

  let j = ((j = i.length) > 3) ? j % 3 : 0;
  return sign + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
}

export const formatISK = (d, decimals=0) => {
  return toMoney(d, decimals, ',', '.');
}

export const formatUSD = (d, decimals=2) => {
  return toMoney(d, decimals, '.', ',')
}

export const connectWithStore = (store, WrappedComponent, ...args) => {
    var ConnectedWrappedComponent = connect(...args)(WrappedComponent)
    return function (props) {
      return <ConnectedWrappedComponent {...props} store={store} />
    }
  }

export const addAuthorizeHeader = (headers) => {
  let token = TokenStorage.getItem("token")
  if (token !== null && token !== undefined) {
   // headers["Authorization"] = `JWT ${token}`
  }
  return headers
}

// Stepper for making PDF from songbook
export const getStepComponent = (step, context) => {
  switch(context) {
    case 'pdf-generator':
      switch(step) {
        case 'pdf_type':
          return PdfType
        case 'chord_config':
          return ChordConfig
        case 'page_setup':
          return PageSetup
        case 'finish_pdf':
          return FinishPDF

        default:
          return ChordConfig
      }
    case 'buy-subscription':
      switch(step) {
        case 'payment_info':
          return PaymentInformation
        case 'payment_done':
          return PaymentDone
        default:
          return PaymentInformation
      }
  }
}

export const setLang = (lang) => {
  window.location.href = `${window.location.origin}${window.location.pathname}?lang=${lang}`
}


export const stepIndicator = (stepRenderer) => {

  let { stepper, index } = stepRenderer
  if (stepper) {
    let extraClass = ""
    switch(stepper.length) {
      case 2:
        extraClass = "two"
        break
      case 4:
        extraClass = "four"
        break
    }
    return (
      <div className={`step-indicator row justify-content-center ${extraClass}`}>
          {stepper.map((item, idx) =>
            <div className="col-md-4 col-8">
              <hr className={index >= idx ? "highlight" : ""}></hr>
            </div>
          )}
      </div>
    )
  }
  return null
}


export const getCookie = (name) => {
  var cookieValue = null;
  if (document.cookie && document.cookie != '') {
    var cookies = document.cookie.split(';');
    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i].trim();
      // Does this cookie string begin with the name we want?

      if (cookie.substring(0, name.length + 1) == (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}


export const copyToClipboard = str => {
  const el = document.createElement('textarea');  // Create a <textarea> element
  el.value = str;                                 // Set its value to the string that you want copied
  el.setAttribute('readonly', '');                // Make it readonly to be tamper-proof
  el.style.position = 'absolute';                 
  el.style.left = '-9999px';                      // Move outside the screen to make it invisible
  document.body.appendChild(el);                  // Append the <textarea> element to the HTML document
  const selected =            
    document.getSelection().rangeCount > 0        // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0)     // Store selection if found
      : false;                                    // Mark as false to know no selection existed before
  el.select();                                    // Select the <textarea> content
  document.execCommand('copy');                   // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el);                  // Remove the <textarea> element
  if (selected) {                                 // If a selection existed before copying
    document.getSelection().removeAllRanges();    // Unselect everything on the HTML document
    document.getSelection().addRange(selected);   // Restore the original selection
  }
};


export const validateEmail = (str) => {
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  return pattern.test(str)
};