import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const searchActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'SET_KEY', 'CLEAR'], 'SEARCH');


export const requestSearch = () => {
    return {
        type: searchActionTypes.REQUEST,
    };
};

export const clearSearch = () => {
    return {
        type: searchActionTypes.CLEAR,
    };
};

export const receiveSearch = (response, query) => {
    return {
        type: searchActionTypes.RECEIVE,
        data: response,
        query: query,
    };
};

export const receiveSearchError = (response, e) => {
    return {
        type: searchActionTypes.ERROR,
    };
};



export const fetchSearch = (query) => {
    return dispatch => {

        dispatch(requestSearch());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v3/core/search/?q=${query}`

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveSearch(response, query));
            } catch (e) {
                dispatch(receiveSearchError(response, e))
            }


        })
        .catch(handleNetworkError);
    };
};
