import React from 'react';
import { user } from '../../api/user/reducers';

class SongLangEditor extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isEditing: false,
            songLangList: [],
        }

        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    componentDidUpdate(prevProps){
        if ((JSON.stringify(this.props.allLang) !== JSON.stringify(prevProps.allLang)) && !this.props.allLang.isFetching) {
            let songLangAccountant = [];
            this.props.allLang.songLang.map((s) => {
                let userSongLangArr = this.props.songLang.split(",");
                if (userSongLangArr.includes(s[0])) {
                    this.state.songLangAccountant = {...this.state.songLangAccountant, [s[0]]: true}
                } else {
                    this.state.songLangAccountant = {...this.state.songLangAccountant, [s[0]]: false}
                }
            })
        } 
    }

    setEditing(value){
        this.setState({isEditing: value})
    }

    toggleEdit() {
        if (this.state.isEditing) {
            let songLangPreferences = Object.keys(this.state.songLangAccountant).filter((s) => {
                return this.state.songLangAccountant[s] === true
            })
            this.props.updateSongLangPreferences(songLangPreferences.join(","))
        }
        this.setState({
            isEditing: !this.state.isEditing
        })
    }

    shouldBeChecked(s){ 
        return this.state.songLangAccountant[s] === true
    }

    onCheckboxChange(s, e){
        let songLangAccountant = {...this.state.songLangAccountant};
        songLangAccountant[s] = e.target.checked;
        this.setState({songLangAccountant})
    }
    
    renderCheckboxes(allLang) {
        let allCheckboxes = [];
        allLang.map(s => {
            allCheckboxes.push(<div className="checkbox">
                <input id={s[0]} type="checkbox" checked={this.shouldBeChecked(s[0])} onChange={(e) => this.onCheckboxChange(s[0], e)}/>
                <label htmlFor={s[0]}>{s[1]}</label>
            </div>)
        })
        return allCheckboxes;
    }
    //function that converts lang ids to lang strings
    // getSongLangNameList(userLang, allLang) {
    //     let songLangStrArr = []
    //     let userLangArr = userLang.split(",");
    //     allLang.map(s => {
    //         if (userLangArr.includes(s[0])) {
    //             songLangStrArr.push(s[1])
    //         }
    //     })
    //     return songLangStrArr.join(",")
    // }

    render() {
        return (
            <>
                <div>
                    <p>{this.props.songLang}</p>
                    {/* {this.props.allLang.songLang.length > 0 && !this.props.allLang.isFetching ? <p>{this.getSongLangNameList(this.props.songLang, this.props.allLang.songLang)}</p> : this.props.songLang} */}
                    <button onClick={this.toggleEdit.bind(this)}>{this.state.isEditing ? gettext("Save") : gettext("Edit")}</button>
                </div>
                {this.state.isEditing && this.state.songLangAccountant &&
                    <div className="checkboxes-container">
                        {this.renderCheckboxes(this.props.allLang.songLang)}
                    </div>
                }
            </>
        )
    }
}

export default SongLangEditor;