import { artistActionTypes } from './actions'

let _initialState = {
    isFetching: false,
    key: null,
    data: {},
}


export function artist(state = _initialState, action) {

    switch (action.type) {
        case artistActionTypes.REQUEST:

            state = { ...state }
            state.isFetching = true

            return state

        case artistActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        default:
            return state
    }

}