import React from "react";
import { connect } from 'react-redux'
import { requestSongbook } from "../../api/songbook-pdf/actions";
import Pusher from 'react-pusher';
import ProgressBar from "../progress-bar";

gettext('Just a second, we\'re preparing your PDF...')
gettext('You can download it here.')
const gae1 = gettext('Gathering songbook data, this may take some time...')
const gae2 = gettext('Your songbook is ready')
const gae3 = gettext('Generating songbook, round 1')
const gae4 = gettext('Generating songbook, round 2')
const gae5 = gettext('Generating songbook, round 3')
const gae6 = gettext('Generating songbook, round 4')
const gae7 = gettext('Generating songbook, round 5')


class FinishPDF extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            started: false,
            progress: 0,
            round: 0,
            message: null,
            url: null,
        }
        this.updateProgress = this.updateProgress.bind(this)
    }

    componentDidMount(){
        this.props.dispatch(requestSongbook(this.props.songbookPDF))
    }

    updateProgress(e) {
        this.setState({started: true, ...e})
    }

    render() {
        let {id} = this.props.user
        return (
            <div className="text-center">
                <h4 className="subtitle">
                    { this.state.started ?
                        <>{this.state.message}</>
                        :
                        <>{gettext('Just a second, we\'re preparing your PDF...')}</>
                    }
                </h4>
                { id &&
                    <>
                        <Pusher
                            channel={`user-${id}`}
                            event="songbook_pdf_file_progress"
                            onUpdate={this.updateProgress}
                        />
                        { this.state.started && !this.state.url &&
                            <ProgressBar completed={this.state.progress} />
                        }
                        { this.state.started && this.state.url && 
                            <a href={this.state.url}>{gettext('You can download it here.')}</a>
                        }
                    </>
                }
            </div>
        
        )
    }
}

function mapStateToProps(state) {
    var f = {
      dispatch: {},
      songbookPDF: {},
  
    }
    f.dispatch = (state.dispatch === undefined) ? [] : state.dispatch
    f.songbookPDF = (state.songbookPDF === undefined) ? [] : state.songbookPDF
    return f;
  }
  
  export default connect(mapStateToProps)(FinishPDF);
 