import React from 'react'
import ReactDOM from 'react-dom/client'
import FavouritesContainer from './favourites'
import { store } from '../../store'
import { Provider } from 'react-redux'

function Wrapper(props) {
  return (
      <Provider store={store}>
          <FavouritesContainer {...props}/>
      </Provider>
  )
}

function initFavouritesContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-favouritescontainer]');
  elements.forEach(initFavouritesContainer);
});

