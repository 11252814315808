import React from 'react';

import { favouriteActionTypes } from './actions'
import moment from 'moment'


export function favourites(state = {
    isFetching: false,
    fetched: false,
    data: {},
}, action) {

    switch (action.type) {
        case favouriteActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.fetched = true
            state.data = action.data

            return state

        case favouriteActionTypes.CHECK:

            state = { ...state }
            state.isFetching = true
            state.fetched = false
            return state

        case favouriteActionTypes.CHECKED:

            state = { ...state }

            state.isFetching = false
            state.data = action.data
            state.fetched = true

            return state

        case favouriteActionTypes.CREATED:
            
            state = { ...state }

            state.isFetching = false
            state.data = action.data
            state.fetched = true 

            return state

        case favouriteActionTypes.DELETED:

            state = { ...state }

            state.isFetching = false
            state.data = action.data
            state.fetched = true

            return state

        default:
            return state
    }

}