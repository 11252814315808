import React from "react";
import { connect } from 'react-redux'
import { SvgSongbookPdfChordsOnly, SvgSongbookPdfChordsAndGrips } from '../svg'
import { setPdfoption } from "../../api/songbook-pdf/actions";

let trans = [
  gettext('PDF chord layout'),
  gettext('No chord images'),
  gettext('Chord images'),
  gettext('Chord color'),
]

class ChordConfig extends React.Component {
  constructor(props) {
    super(props)
    this.getChordDropdown = this.getChordDropdown.bind(this)
    this.getChordColors = this.getChordColors.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }


  handleChange(e, attr) {
    this.props.dispatch(setPdfoption(attr, e.target.value))
  }

  getChordDropdown() {
    return (
      <div className="select-wrapper">
        <select value={this.props.songbookPDF.selectedInstrument} className="selectpicker" onChange={(e) => this.handleChange(e, 'selectedInstrument')}>
          <option value="nochords">{gettext('No chord images')}</option>
          {this.props.instruments.data.map((item) => {
            if (item.safe_name !== "piano") {
              return <option value={item.id}>{item.name}</option>
            }
          }
          )}
        </select>
      </div>
    )
  }

  getChordColors() {
    let chordColors = ['red', 'blue', 'orange', 'purple', 'black']
    return (
      <div>
        <form className="chord-color-selector">
          {chordColors.map((item) =>
            <label className={`radio ${item} ${this.props.songbookPDF.selectedColor === item ? 'active' : ''}`}>
              <input type="radio" value={item}
                checked={this.props.songbookPDF.selectedColor === item}
                onChange={(e) => this.handleChange(e, 'selectedColor')} />
            </label>
          )}
        </form>
      </div>
    )
  }

  render() {
    return (
      <>
        <div className="chord-config">
          <h4 className="subtitle"> {gettext('PDF chord layout')} </h4>
          <div className="col-16">
            <div className="row mx-0">
              <div className="col">
                <div className={`text-pdf-img ${this.props.songbookPDF.selectedColor}`}>
                  {this.props.songbookPDF.selectedInstrument === 'nochords' ?
                    <SvgSongbookPdfChordsOnly></SvgSongbookPdfChordsOnly>
                    :
                    <SvgSongbookPdfChordsAndGrips></SvgSongbookPdfChordsAndGrips>
                  }
                </div>
              </div>
              <div className="vertical-single"></div>
              <div className="col-10 selection">
                <p><b>{gettext('Chord images')}</b></p>
                {this.getChordDropdown()}
                <p><b>{gettext('Chord color')}</b></p>
                {this.getChordColors()}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
  var f = {
    dispatch: {},
    songbookPDF: {},
    instruments: {},
  }
  f.dispatch = (state.dispatch === undefined) ? [] : state.dispatch
  f.songbookPDF = (state.songbookPDF === undefined) ? [] : state.songbookPDF
  f.instruments = (state.instruments === undefined) ? {} : state.instruments
  return f;
}

export default connect(mapStateToProps)(ChordConfig);
