import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { store } from '../../store'
import BuyButton from './buy-button'

function Wrapper(props) {
  return (
      <Provider store={store}>
          <BuyButton {...props}/>
      </Provider>
  )
}

function initBuyButton(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-buy-button]');
  elements.forEach(initBuyButton);
});

