import React from 'react';
import PropTypes from 'prop-types';


class Adsense extends React.Component {
  componentDidMount() {
    if(window)  {
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({})
        } catch(e) {
            void(0)
        }
    }
  }

  render() {
    return (
      <ins className={`${this.props.className} adsbygoogle`}
        style={this.props.style}
        data-ad-client={this.props.client}
        data-ad-slot={this.props.slot}
        data-ad-layout={this.props.layout}
        data-ad-format={this.props.format}
        data-full-width-responsive={this.props.responsive}
        data-ad-layout-key={this.props.layout_key}
      />
    )
  }
}


Adsense.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  format: PropTypes.string,
  responsive: PropTypes.string,
  layout_key: PropTypes.string
}


Adsense.defaultProps = {
  className: '',
  style: {display: 'block'}, 
  format: 'auto',
  layout: '',
  responsive: 'false',
  layout_key: ''
}


export default Adsense;