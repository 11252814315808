import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'
import { fetchSong } from '../song/actions'

export const planActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'SELECT'], 'PLAN');


export const requestPlans = () => {
    return {
        type: planActionTypes.REQUEST,
    };
};

export const receivePlans = (response) => {
    return {
        type: planActionTypes.RECEIVE,
        data: response,
    };
};

export const receivePlansError = () => {
    return {
        type: planActionTypes.ERROR,
    };
};

export const _selectPlan = (id) => {
    return {
        type: planActionTypes.SELECT,
        id: id,
    }
}

export const selectPlan = (song, key, plan) => {
    return dispatch => {
        dispatch(_selectPlan(plan))
        dispatch(fetchSong(song, key, plan))
    }
}

export const fetchPlans = () => {
    return dispatch => {

        dispatch(requestPlans());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v3/core/plans/`


        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receivePlans(response));
            } catch (e) {
                dispatch(receivePlansError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
