import React from 'react';
import {SvgHeart} from '../svg'
import { connect } from 'react-redux'
import { connectWithStore } from '../../utils'
import {openModal} from '../../api/modals/actions'
import { store } from '../../store'
import { toggleFavourite } from '../../api/favourite/actions'

class Favourite extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
          favedSongs: []
        }

        this.toggleFavourite = this.toggleFavourite.bind(this)
    }

    componentDidMount(){
      this.setState({favedSongs: this.props.favouriteIds})
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
      if(this.props.favouriteIds.join(',') !== prevProps.favouriteIds.join(',')) {
          this.setState({favedSongs: this.props.favouriteIds})
        }
    }

    toggleFavourite(id){
      if (!this.props.user.id || !this.props.user.hasSubscription) {
        this.props.dispatch(openModal("modal-paywall", "favourite"))
      }
      else {
        let arr = this.state.favedSongs;
        let isFav = this.isFavourite(id)
        if(isFav){
          arr.splice(arr.indexOf(id), 1);
        } else {
          arr.push(id)
        }
        this.setState({favedSongs: arr})
        this.props.dispatch(toggleFavourite(id, !isFav))
      }
    }

    isFavourite(id){
      if(this.props.allFavourites){
        return true
      }
      return this.state.favedSongs.includes(id)
    }

    render() {
      const { id } = this.props
        return (
            <a onClick={() => this.toggleFavourite(id)} className={this.isFavourite(id)  ? "favoured" : "not-favoured"}><SvgHeart /></a>                              
        )
    }
}

Favourite.defaultProps = {
  allFavourites: false
}

function mapStateToProps(state){
  var f = {
    user: {}

  }
  f.user = (state.user === undefined) ? [] : state.user
  return f;
}

export default connectWithStore(store, Favourite, mapStateToProps);