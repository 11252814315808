import { withModal } from '../modal'

import React from 'react';
import Modal from 'react-modal'
import { SvgGuitarPartyLogo, SvgCheckMark } from '../svg';
import GuitarpartyModal from '../modal'
import { openModal, closeModal } from '../../api/modals/actions'


import { store } from '../../store'
import { connectWithStore, stepIndicator } from '../../utils'
import ModalSubscription from '../modal-subscription';


class ModalPaywall extends React.Component {
    constructor() {
        super();

    }

    render() {
        return (
            <GuitarpartyModal extraClasses="modal-paywall" modalKey="modal-paywall"  {...this.props}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal("modal-paywall"))}>
                    <span></span>
                    <span></span>
                </button>
                <div className="d-flex">
                    <div className="image-container">
                        <SvgGuitarPartyLogo />
                        <img src={window.IMAGES.paywall} />
                    </div>
                    <div className="text-container">
                        <h2>{gettext('Subscription benefits')}</h2>
                        <ul>
                            <li><SvgCheckMark />{gettext('Over 15.000 songs and lyrics')}</li>
                            <li><SvgCheckMark />{gettext('Transpose songs')}</li>
                            <li><SvgCheckMark />{gettext('Control layout and colors')}</li>
                            <li><SvgCheckMark />{gettext('More than one songbook')}</li>
                            <li><SvgCheckMark />{gettext('Guitar lessons')}</li>
                            <li><SvgCheckMark />{gettext('No ads')}</li>
                        </ul>
                        <div className="button-container d-flex flex-column justify-content-center align-items-center">
                            <span>{gettext('Price only')} <span className="price">{gettext('SUBSCRIPTION_PRICE')}</span> {gettext('per month')}</span>
                            { this.props.user.isAuthenticated ?
                                <button className="modal-opener btn button-modal-subscription" onClick={() => this.props.dispatch(openModal("modal-subscription"))}>{gettext("Buy subscription")}</button>
                                :
                                <button className="modal-opener btn button-modal-login" onClick={() => this.props.dispatch(openModal("modal-login", gettext("You must log in before you purchase a subscription")))}><span className="text">{gettext("Buy subscription")}</span></button>
                            }
                        </div>
                    </div>
                </div>
            </GuitarpartyModal>
        )
    }
}



// export default withModal(ModalSongbook);

function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user

    return f;
}

export default connectWithStore(store, ModalPaywall, mapStateToProps);