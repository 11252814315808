import React from 'react';
import GuitarpartyModal from '../modal'
import { closeModal } from '../../api/modals/actions'
import { store } from '../../store'
import { connectWithStore } from '../../utils'


let trans = [
  gettext("Payment Processor"),
  gettext("Date"),
  gettext("Time"),
]

class ModalReceipt extends React.Component {
    constructor() {
        super();
    }
    
    render() {

        if (this.props.modals.data && this.props.modals.data.transaction) {
            let transaction = this.props.modals.data.transaction;
            let receipt = transaction.receipt;
            return (
                <GuitarpartyModal extraClasses="modal-receipt" modalKey="modal-receipt"  {...this.props}>
                { receipt && 
                    <>
                        {receipt.VerslunNafn ?
                            <div className="receipt row">
                                <div className="col-16">{receipt.VerslunNafn }</div>
                                <div className="col-16">{receipt.VerslunHeimilisfang }</div>
                                <div className="col-16 mb-3">{receipt.VerslunStadur }</div>
                                <div className="col-16 mb-3">
                                    <h3>{ receipt.TegundKorts }</h3>
                                </div>
                                <div className="col-16">{gettext("Payment Processor")}: { receipt.Faersluhirdir}</div>
                                <div className="col-8">{gettext("Date")}: { receipt.Dagsetning }</div>
                                <div className="col-8 text-right">{gettext("Time")}: { receipt.Timi }</div>
                                <div className="col-16 mt-3">{ receipt.Kortnumer }</div>
                                <div className="col-16 text-center mt-3">
                                    <h3>{ receipt.TegundAdgerd }</h3>
                                </div>
                                <div className="col-16 text-center mb-3">
                                    <h3>{ receipt.Vidskiptaskilabod }</h3>
                                </div>
                                <div className="col-8">{ receipt.Bunkanumer }</div>
                                <div className="col-8 text-right">{ receipt.Soluadilinumer }</div>
                                <div className="col-16">{ receipt.F22_1til4 }</div>
                                <div className="col-5">{ receipt.LinaD1 }</div>
                                <div className="col-6 text-center">{ receipt.LinaC3 }</div>
                                <div className="col-5 text-right">{ receipt.LinaC4 }</div>
                            </div>
                        :
                            <div className="receipt row">
                                <div className="col-16">Guitarparty.com</div>
                                <div className="col-16">{gettext("Payment Processor")}: Valitor</div>
                                <div className="col-8">{gettext("Date")}: { moment(this.props.modals.data.billing_date).format('YYYY-MM-DD') }</div>
                                <div className="col-8 text-right">{gettext("Time")}: { moment(this.props.modals.data.billing_date).format('hh:mm:ss') }</div>
                                <div className="col-16 mt-3">{ receipt.maskedCardNumber }</div>
                                <div className="col-16 text-center mt-3">
                                    <h3>SALA</h3>
                                </div>
                                <div className="col-8">{ receipt.authorizationCode }</div>
                                <div className="col-8 text-right">{ receipt.transactionID }</div>
                            </div>
                        }
                    </>
                }
                </GuitarpartyModal>
            )
        } else {
            return <GuitarpartyModal extraClasses="modal-receipt" modalKey="modal-receipt"  {...this.props} />
        }
    }
}



// export default withModal(ModalSongbook);

function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user

    return f;
}

export default connectWithStore(store, ModalReceipt, mapStateToProps);