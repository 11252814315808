import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, addAuthorizeHeader, getCookie } from '../../utils'

export const favouriteActionTypes = createTypes(['CREATED', 'DELETED', 'CHECK', 'CHECKED', 'REQUEST', 'RECEIVE'], 'FAVOURITE');

export const requestFavorites = () => {
    return {
        type: favouriteActionTypes.REQUEST,
    };
};

export const receiveFavorites = (response) => {
    return {
        type: favouriteActionTypes.RECEIVE,
        data: response
    }
};

export const fetchFavourites = (id, limit=1000) => {
    return dispatch => {
        dispatch(requestFavorites());

        let url = `/api/v3/core/favourites/?limit=` + limit

        return fetch(url, {
            method: 'GET',
        })
        .then(parseJSON) 
        .then(response => {
            dispatch(receiveFavorites(response))
        })
        .catch(handleNetworkError);
    }
};

export const createdFavourite = (response) => {
    return {
        type: favouriteActionTypes.CREATED,
        data: response
    }
}

export const createFavourite = (id) => {
    return dispatch => {
        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/favourites/`

        return fetch(url, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({song_id: id})
        })
        .then(parseJSON)
        .then(response => {
            dispatch(createdFavourite(response))
        })
        .catch(handleNetworkError);
    }
};

export const deletedFavourite = (response) => {
    return {
        type: favouriteActionTypes.DELETED,
        data: response
    }
}

export const deleteFavourite = (id) => {
    return dispatch => {
        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/favourites/${id}/`

        return fetch(url, {
            method: 'DELETE',
            headers: headers,
            body: JSON.stringify({song_id: id})
        })
        .then(parseJSON)
        .then(response => {
            dispatch(deletedFavourite(response))
        })
        .catch(handleNetworkError);
    }
};

export const checkFavourite = () => {
    return {
        type: favouriteActionTypes.CHECK,
    };
};

export const checkedFavourite = (response) => {
    return {
        type: favouriteActionTypes.CHECKED,
        data: response,
    };
};

export const toggleFavourite = (id, state) => {
    return dispatch => {        
        if(state === true){
            dispatch(createFavourite(id))
        }
        else if(state === false){
            dispatch(deleteFavourite(id))
        }
    };
}

export const checkIfFavouriteSong = (id) => {
    return dispatch => {

        dispatch(checkFavourite());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        })

        let url = `/api/v3/core/favourites/${id}/check/`

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            dispatch(checkedFavourite(response));
        })
        .catch(handleNetworkError);
    };
};
