import React from "react";
import { connect } from 'react-redux'
import { fetchLessons } from '../../api/lessons/actions'
import { openModal } from '../../api/modals/actions'
import { fetchProfile } from '../../api/profile/actions'

class LessonListContainer extends React.Component {
  constructor(props) {
    super(props)


  }

  componentDidMount() {
    Promise.resolve(this.props.dispatch(fetchProfile()))
      .then(() => {
        this.props.dispatch(fetchLessons())
      })
  }

  returnLink(lesson) {
    if (this.props.user.has_subscription) {
      return (<a href={lesson.song ? lesson.song.slug + '/' + lesson.id : '/lessons/' + lesson.id} >{lesson.title}</a>)
    } else {
      return (
        <div class="upsell-modal-opener" id="upsell-modal" onClick={() => this.props.dispatch(openModal("modal-paywall"))}>{lesson.title}</div>
      )
    }
  }

  getLessons(lessons) {
    let allLessons = []
    lessons.map((lesson, i) => {
      if (i !== 0) {
        allLessons.push(<hr />)
      }
      allLessons.push(
        <div class="generic-list__item px-0 d-flex align-items-center">
          <div class="text">
            <div class="generic-list__title">
              {this.returnLink(lesson)}
            </div>
            <div class="generic-list__body">
              <p>Kennari: {lesson.teacher.name}</p>
              {lesson.publish_at > new Date() && <p>Birtist: {lesson.publish_at}</p>}
            </div>
          </div>
        </div>
      )})
    return allLessons
  }


  render() {
    const lessons = this.props.lessons.data

    return (
      <>
        <div class="row justify-content-center">
          <div class="col-16 col-sm-14 col-md-12 col-lg-8 mx-auto">
            <h1 class="stylish">{gettext("Lessons")}</h1>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="generic-list col-16 col-sm-14 col-md-12 col-lg-8 mx-auto">
            {lessons.results.length > 0 && this.getLessons(lessons.results)}
          </div>
        </div>
      </>
    )
  }
}

LessonListContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    lessons: {},
    user: {},
  }
  f.lessons = (state.lessons === undefined) ? {} : state.lessons
  f.user = (state.user === undefined) ? {} : state.user
  return f;
}

export default connect(mapStateToProps)(LessonListContainer);