import jwtDecode from 'jwt-decode';
import TokenStorage from '../../storages/token-storage'

import { passwordActionTypes } from './actions'
import { userRefreshLogin } from '../user/actions'


const _initalState = {
    isFetching: false,
    status: {},
}

export function passwordUpdate(state=_initalState, action) {
    switch (action.type) {        
        case passwordActionTypes.REQUEST:
            state = {...state, isFetching: true}
            return state

        case passwordActionTypes.RECEIVE:
            state = {...state, isFetching: false}
            state.status = action.data.status;
            if (action.data.status === "Password updated") {
                userRefreshLogin();
            }

            return state

        default:
            return state
    }
}