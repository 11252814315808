import React from 'react';
import { memo } from 'react'
import { Button } from 'reactstrap';
import { openModal } from '../../api/modals/actions';
import Favourite from '../favourite';
import CapoPreference from '../song-capo';
import Transpose from '../song-transpose';
import { SvgDragHandle, SvgTrash } from '../svg';
import { useDrag, useDrop } from 'react-dnd'


const DragHandle = () => <SvgDragHandle />


const SortableItem = memo(function SortableItem({ findItem, onSortEnd, collectionRelation, songbook, transposeSong, setSongCapo, keys, index, deleteSongFromSongbook, songbookId, favedSongs, user, dispatch }) {
    const originalIndex = findItem(collectionRelation.id).index
    const id = collectionRelation.id
    const moveCard = (oldIndex, newIndex) => {
        onSortEnd({oldIndex, newIndex})
    }
    const [{ opacity, isDragging }, drag, preview] = useDrag(
        () => ({
            type: 'SonglistItem',
            item: { id, originalIndex },
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                opacity: monitor.isDragging() ? 0.4 : 1.0,
            })
        }),
        [id, originalIndex, moveCard],
    )

    const [{backgroundColor}, drop] = useDrop(
        () => ({
            accept: 'SonglistItem',
            drop({ id: draggedId }) {
                if (draggedId !== id) {
                    const { index: overIndex } = findItem(id)
                    moveCard(findItem(draggedId).index, overIndex)
                }
            },
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                backgroundColor: monitor.isOver() ? 'rgba(255, 255, 255, 0.3)' : '',
            })

        }),
        [findItem, moveCard],
    )

    return (
        <div ref={(node) => drop(preview(node))} className="song row align-items-center w-100" key={index} style={{ backgroundColor, opacity }}>
            {songbook.owner === user.username &&
                <div ref={drag} className="handle white">
                    <SvgDragHandle />
                </div>
            }
            <div className="col-md-5 col-sm-8 col-7 title-col">
                <div className="song-title"><a href={`/songs/${collectionRelation.song.slug}/${collectionRelation.song.transposed_to !== collectionRelation.song.key ? `?key=${collectionRelation.song.transposed_to}` : ''}`}>{collectionRelation.song.title}</a></div>
            </div>
            <div className="col-sm-2 col-2 tones">
                <CapoPreference heading={gettext("Capo preference")} setCapo={setSongCapo.bind(this)} originalCapo={collectionRelation.song.capo} currentCapo={collectionRelation.capo !== null ? collectionRelation.capo : collectionRelation.song.capo} id={collectionRelation.id} user={user} dispatch={dispatch} type={"list"} />
            </div>
            <div className="col-sm-2 col-2 tones">
                <Transpose heading={gettext("Chord key")} setKey={transposeSong.bind(this)} originalKey={collectionRelation.song.key} currentKey={collectionRelation.song.transposed_to} id={collectionRelation.song.id} crId={collectionRelation.id} user={user} dispatch={dispatch} type={"list"} />
            </div>
            <div className="col-md-4 col-4 d-none d-md-block chords-col">
                <div className="chords">{collectionRelation.song.chordstr}</div>
            </div>
            <div className="col-lg-2 col-sm-3 col-5 actions d-flex justify-content-between align-items-center">
                {songbook.owner === user.username &&
                    <Button className="trash white" color="light" onClick={(e) => deleteSongFromSongbook(collectionRelation.id, songbookId, e)}>
                        <SvgTrash />
                    </Button>
                }
                <div className="heart">
                    <Favourite id={collectionRelation.song.id} favouriteIds={favedSongs} />
                </div>
            </div>
        </div>
    )
})

class SongListItem extends React.Component {

    constructor(props) {
        super(props)
        this.transposeSong = this.transposeSong.bind(this)
        this.setSongCapo = this.setSongCapo.bind(this)
        this.deleteSongFromSongbook = this.deleteSongFromSongbook.bind(this)
    }

    componentDidMount() {
        $(".song .tones>button").click(function () {
            $(".song .tones .tones-sub").removeClass('open');
            $(this).parent().find(".tones-sub").addClass("open");
            $(".song").removeClass('active');
            $(this).parents('.song').addClass('active');
        });
    }

    renderUnsortableItem(collectionRelation, index, keys, songbookId, favedSongs) {
        return (<div className={`song row align-items-center w-100`} key={index}>
            <div className="col-md-5 col-sm-8 col-7 pl-0">
                <div className="song-title"><a href={`/songs/${collectionRelation.song.slug}/${collectionRelation.song.transposed_to !== collectionRelation.song.key ? `?key=${collectionRelation.song.transposed_to}` : ''}`}>{collectionRelation.song.title}</a></div>
            </div>
            <div className="col-sm-2 col-2 tones">
                {this.props.user && this.props.songbook && this.props.songbook.data && this.props.user.username === this.props.songbook.data.owner ?
                    <CapoPreference heading={gettext("Capo preference")} setCapo={this.setSongCapo.bind(this)} originalCapo={collectionRelation.song.capo} currentCapo={collectionRelation.capo !== null ? collectionRelation.capo : collectionRelation.song.capo} id={collectionRelation.id} user={this.props.user} dispatch={this.props.dispatch} type={"list"} />
                    :
                    <>{collectionRelation.song.capo}</>
                }
            </div>
            <div className="col-sm-2 col-2 tones">
                {this.props.user && this.props.songbook && this.props.songbook.data && this.props.user.username === this.props.songbook.data.owner ?
                    <Transpose heading={gettext("Chord key")} setKey={this.transposeSong.bind(this)} originalKey={collectionRelation.song.key} currentKey={collectionRelation.song.transposed_to} id={collectionRelation.song.id} crId={collectionRelation.id} user={this.props.user} dispatch={this.props.dispatch} type={"list"} />
                    :
                    <>{collectionRelation.song.transposed_to}</>
                }
            </div>

            <div className="col-md-4 col-4 d-none d-md-block">
                <div className="chords">{collectionRelation.song.chordstr}</div>
            </div>
            <div className="col-sm-3 col-5 actions d-flex justify-content-between align-items-center">
                {this.props.owner === this.props.user.username &&
                    <Button className="trash white" color="light" onClick={(e) => this.deleteSongFromSongbook(collectionRelation.id, songbookId)}>
                        <SvgTrash />
                    </Button>
                }
                <div className="heart">
                    <Favourite id={collectionRelation.song.id} favouriteIds={favedSongs} user={this.props.user} dispatch={this.props.dispatch} />
                </div>
            </div>
        </div>)
    }

    setSongCapo(crId, capo) {
        if (this.props.user.hasSubscription) {
            this.props.onSetSongCapo(crId, capo)
        } else {
            this.props.dispatch(openModal("modal-paywall", "capo-songbook-item"))
        }
    }

    transposeSong(id, key, crId) {
        if (this.props.user.hasSubscription) {
            this.props.onTransposeSong(id, key, crId)
        } else {
            this.props.dispatch(openModal("modal-paywall", "transpose-songbook-item"))
        }
    }

    deleteSongFromSongbook(relationId, songbookId, e) {
        this.props.deleteSongFromSongbook(relationId, songbookId)
    }

    render() {
        const { songbook, sortType, songbookId, collectionRelation } = this.props

        let keys = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"]
        if (collectionRelation && collectionRelation.song.key.charAt(collectionRelation.song.key.length - 1) === "m") {
            keys = ["Cm", "C#m", "Dm", "Ebm", "Em", "Fm", "F#m", "Gm", "Abm", "Am", "Bbm", "Bm"]
        }

        return (
            <>
                {(songbook !== undefined && sortType === "custom") &&
                    <SortableItem findItem={this.props.findItem} onSortEnd={this.props.onSortEnd} songbook={songbook.data} key={`item-${this.props.index}`} transposeSong={this.transposeSong} setSongCapo={this.setSongCapo} index={this.props.index} collectionRelation={collectionRelation} keys={keys} deleteSongFromSongbook={this.deleteSongFromSongbook} songbookId={songbookId} favedSongs={this.props.favedSongs} user={this.props.user} dispatch={this.props.dispatch} />}
                {(songbook !== undefined && (sortType === "title" || sortType === "author")) &&
                    this.renderUnsortableItem(collectionRelation, this.props.index, keys, songbookId, this.props.favedSongs)}
            </>

        )
    }
}

export default SongListItem;
