import React from 'react';
import { connectWithStore, Spinner } from '../../utils'
import { store } from '../../store'

import {SvgPlus, SvgCheckFill, SvgSongbooklistAddList, SvgExitFill, SvgSongbooklistPlay} from '../svg'
import { openModal } from '../../api/modals/actions';
import { fetchSongbooks } from '../../api/songbooks/actions'
import { addChosenSongToSongbook, removeChosenSongFromSongbook } from '../../api/collection-relation/actions'

import SongbookCreate from '../songbook-create'

let trans = [
    gettext("Add to songbook"),
]

class AddSongToSongbooks extends React.Component {

    constructor(props, context) {
        super(props, context)
        this.state = {
            songbookListOpen: false,
            newSongBookOpen: false,
            songbooks: [],
            songbookClicked: {}
        }
        this.addSongtoSongbook = this.addSongtoSongbook.bind(this)
    }


    componentDidMount(){
        // Close menu on outside click
        $(document).click((e) => {
            if(this.state.songbookListOpen && !($.contains($('.song-add-to-songbook-container')[0], e.target))) {
                this.setState({
                    songbookListOpen: false,
                    newSongBookOpen: false,
                })
            }
        })

        this.props.dispatch(fetchSongbooks(false))
    }

    componentDidUpdate(prevProps){
      // Was fetching but not anymore
      let prevId = prevProps.collectionRelation.data.songbook
      if(prevId in this.state.songbookClicked){
        if (prevProps.songbooks.isFetching && this.props.songbooks.isFetching === false){
          this.setState({ songbookClicked: {...this.state.songbookClicked, [prevId]: false } }) //simple value
        }
      }
    }

    toggleSongbookList(){
        var currentState = this.state.songbookListOpen;
        if (currentState && this.props.multipleSongs) {
            this.props.clearCheckedSongs();
        }
        this.setState({songbookListOpen: !currentState});
    }

    hideSearchResults(){
        var container = $(".search-results");
        container.hide();
    }

    addSongtoSongbook(songbook, songIds) {
        // Songbook either not in object or has value false
        if(!(songbook.id in this.state.songbookClicked) || !this.state.songbookClicked[songbook.id]){
          this.setState({ songbookClicked: {...this.state.songbookClicked, [songbook.id]: true } }) //simple value
          let songbookDetail = false;
          if (this.isSongInSongbook(songbook.id, songIds, this.props.currentKey)) {
              songIds.map((songId) => {
                  this.props.dispatch(removeChosenSongFromSongbook(songId, songbook.id, songbookDetail, this.props.currentKey))
              })
          } else {
              if (this.props.currentKey !== undefined) {
                  songIds.map((songId) => {
                      this.props.dispatch(addChosenSongToSongbook(songbook.id, songId, this.props.currentKey))
                  })
              } else {
                  songIds.map((songId) => {
                      this.props.dispatch(addChosenSongToSongbook(songbook.id, songId))
                  })
              }
          }
        }
    }

    isSongInSongbook(songbookId, songIds, key) {

        let songbook = this.props.songbooks.privateSongbooks.results.find( (el) => {return el.id === songbookId})

        return songIds.every((item) => {
            if (key === undefined) {
                return songbook.song_ids.includes(item)
            }
            return songbook.song_ids.includes(item) && (songbook.song_ids_keys && songbook.song_ids_keys[item] && songbook.song_ids_keys[item].includes(key))
        })
    }

    renderSongbooks(songbooks) {
        let songIds = this.props.ids;
        let allUserSongbooks = 
        <ul>
            {songbooks.map((songbook) => {
                return <li key={songbook.id} onClick={this.addSongtoSongbook.bind(this, songbook, songIds, this.props.currentKey)} className={this.isSongInSongbook(songbook.id, songIds, this.props.currentKey) ? "active" : ""}>
                        <label htmlFor="chosen-songbook" className="sr-only">Choose songbook {songbook.title}</label>
                        <input id="chosen-songbook" type="checkbox"></input>
                        {( this.state.songbookClicked[songbook.id]) ? <div className="pr-2"><Spinner/></div> : <div className={`input ${this.isSongInSongbook(songbook.id, songIds, this.props.currentKey) ? "checked": ''}`}></div>}
                        
                        <span>{songbook.title}</span>
                    </li>
            })}
            <li className="new-book">
                <SongbookCreate />
            </li>
        </ul>
        return allUserSongbooks;
    }


    showMessage() {
        this.props.dispatch(openModal("modal-paywall", "add-to-songbook"))
    }

    render() {
        let {songbookListOpen} = this.state;
        const { songbooks } = this.props
        
        return (
            <div className={`song-add-to-songbook-container${songbookListOpen ? " songbook-open" : ""} ${this.state.newSongBookOpen ? " new-songbook-open" : ""}`} disabled={this.props.disabled}>
                <div className={`song-add-to-songbook${songbookListOpen ? " open" : ""} ${!this.props.user.isAuthenticated && "disabled"}`}>
                    <div className="inner-container">
                        <button className="btn btn-outline btn-icon-after" onClick={this.props.user.isAuthenticated ? this.toggleSongbookList.bind(this) : this.showMessage.bind(this)}><span className="add">{gettext("Add to songbook")}</span> {songbookListOpen ? <SvgExitFill/> : <div className="svg-container"><SvgPlus/></div>}</button>
                        <div className="songbooks">
                            {songbooks && songbooks.privateSongbooks && songbooks.privateSongbooks.results && this.renderSongbooks(songbooks.privateSongbooks.results)}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}



function mapStateToProps(state) {
    var f = {
        user: {},
        songbooks: {},
        collectionRelation: {}
    }
    f.user = (state.user === undefined) ? [] : state.user
    f.songbooks = (state.songbooks === undefined) ? [] : state.songbooks
    f.collectionRelation = (state.collectionRelation === undefined) ? [] : state.collectionRelation
    return f;
}

// export default connect(mapStateToProps)(Song);
export default connectWithStore(store, AddSongToSongbooks, mapStateToProps);
