import React from 'react'
import ReactDOM from 'react-dom/client'
import SongbookRootContainer from './songbook_root'

function initSongbookRootContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(SongbookRootContainer, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-songbookcontainer]');
  elements.forEach(initSongbookRootContainer);
});

