import React from "react";
import ReactDOM from "react-dom";
import { submitSong, parseSong } from '../../api/submit-song/actions'
import { connect } from 'react-redux'
import ReCAPTCHA from "react-google-recaptcha";

import { RECAPTCHA_KEY } from '../../constants'

class SubmitASongContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      capo: 0,
      errors: {
        title: '',
        song: '',
        capo: '',
        norobot: '',
      },
      thankYou: false,
      recaptchaValid: false,
      cover: '',
      lyrics: '',
      music: '',
    }

    this.onChange = this.onChange.bind(this)
    this.submitASong = this.submitASong.bind(this)
    this.onChangeRecaptcha = this.onChangeRecaptcha.bind(this)

    this.title = React.createRef()
    this.song = React.createRef()
    this.capo = React.createRef()
    this.norobot = React.createRef()
  }

  validateForm() {
    let errors = this.state.errors
    let valid = true;
    Object.keys(errors).forEach(key => {
      switch (key) {
        case 'title':
          var titleVal = this.title.current.value;
          if (titleVal === "") {
            errors.title = gettext('The title is required so we can register it properly')
            valid = false;
          } else {
            errors.title = ''
          }
          break;
        case 'song':
          var songVal = this.song.current.value;
          if (songVal === "") {
            errors.song = gettext("Don't forget the song itself")
            valid = false;
          } else {
            errors.song = ''
          }
          break;
        // case 'norobot':
        //   if (!this.state.recaptchaValid) {
        //     errors.norobot = gettext('This is not that hard, lets try again.')
        //     valid = false;
        //   } else {
        //     errors.norobot = ''
        //   }
        //   break;
        case 'norobot':
          var norobotVal = this.norobot.current.value;
          if (norobotVal === "" || parseInt(norobotVal) !== 3) {
            errors.norobot = gettext('This is not that hard, lets try again.')
            valid = false;
          } else {
            errors.norobot = ''
          }
          break;
        case 'capo':
          var capoVal = this.capo.current.value;
          if (capoVal.length > 2) {
            errors.capo = gettext('Capo must be a number with less than two digits')
            valid = false;
          } else {
            errors.capo = ''
          }
          break;
        default:
          break;

      }
    });
    this.setState({ errors: errors })
    return valid;
  }
  componentDidUpdate() {
    window.init_vextab()
  }

  onChange(e) {
    const { name, value } = e.currentTarget;
    this.setState({
      [name]: value
    })
    if (name === 'song') {
      this.props.dispatch(parseSong(value))
    }
  }

  onChangeRecaptcha() {
    this.setState({recaptchaValid: true})
  }

  // Checks that all requirements have been properly fulfilled before submit
  checkBeforeSubmit(){
    if (this.validateForm()) {
      let songParsed = this.props.songSubmition.song_chordified.song_chordified
      if(!songParsed){
        Promise.resolve(this.props.dispatch(parseSong(this.state.song)))
        .then(()=>{
          songParsed = true
          this.submitASong()
        })
      }else{
        this.submitASong()
      }
    }
  }

  submitASong() {
    let songObj = { 'song_parsed': this.props.songSubmition.song_chordified.song, 'title': this.state.title, 'capo': this.state.capo }
    let artistObj = { 'cover': this.state.cover, 'lyrics': this.state.lyrics, 'music': this.state.music }
    let norobot = this.state.norobot;
    // let norobot = this.state.recaptchaValid ? 3 : 0;
    Promise.resolve(this.props.dispatch(submitSong(songObj, artistObj, norobot)))
      .then(() => {
        this.setState({ thankYou: true })
    })
  }

  renderChordifiedSong() {
    if (this.props.songSubmition.song_chordified.song_chordified) {
      return (<div dangerouslySetInnerHTML={{ __html: this.props.songSubmition.song_chordified.song_chordified }}></div>)
    } else {
      return (<div>{gettext('Nothing to render... yet!')}</div>)
    }
  }

  render() {
    // const { song, songbooks, favourite, instruments, profile } = this.props

    return (
      <>
      { this.state.thankYou ? 
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-10 col-16">
              <h2>{gettext('Thank you! Your song is now being moderated by our team and should appear on the website within a few days.')}</h2>
            </div>
          </div>
        </div>
      :
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-xxl-4 col-lg-10 col-16">
              <h2 className="title">{gettext('Here you can submit a song!')}</h2>
              <form className="submit-song-form">
                <div>
                  <label htmlFor="song-title">{gettext('Song title')}</label>
                  <input id="song-title" type="text" name="title" onChange={this.onChange} ref={this.title}></input>
                  {this.state.errors.title.length > 0 ?
                    <span className="error"> ({this.state.errors.title}) </span> : ''
                  }
                </div>
                <div>
                  <label htmlFor="performer-name">{gettext('Performer name (empty if unknown)')}</label>
                  <input id="performer-name" type="text" name="cover" onChange={this.onChange}></input>
                </div>
                <div>
                  <label htmlFor="lyrics-author">{gettext('Lyrics author (empty if unknown)')}</label>
                  <input id="lyrics-author" type="text" name="lyrics" onChange={this.onChange}></input>
                </div>
                <div>
                  <label htmlFor="song-author">{gettext('Song author (empty if unknown)')}</label>
                  <input id="song-author" type="text" name="music" onChange={this.onChange}></input>
                </div>
                <div>
                  <label htmlFor="lyrics-with-cords">{gettext('Lyrics with cords')}</label>
                  <textarea id="lyrics-with-cords" type="text-area" name="song" onChange={this.onChange} ref={this.song}></textarea>
                  {this.state.errors.song.length > 0 ?
                    <span className="error"> ({this.state.errors.song}) </span> : ''
                  }
                </div>
                <div>
                  <label htmlFor="capo">{gettext('Capo position (optional) e.g. 3 for capo on fret 3. Default 0 for no capo.')}</label>
                  <input id="capo" type="number" name="capo" defaultValue={0} onChange={this.onChange} ref={this.capo}></input>
                  {this.state.errors.capo.length > 0 ?
                    <span className="error"> ({this.state.errors.capo}) </span> : ''
                  }
                </div>
                {/* <div>
                <ReCAPTCHA
                  sitekey={RECAPTCHA_KEY}
                  onChange={this.onChangeRecaptcha}
                />
                </div> */}
                <div>
                  <label htmlFor="not-a-robot">{gettext('What is 1 + 2?')}</label>
                  <input id="not-a-robot" type="text" name="norobot" onChange={this.onChange} ref={this.norobot}></input>
                  {this.state.errors.norobot.length > 0 ?
                    <span className="error"> ({this.state.errors.norobot}) </span> : ''
                  }
                </div>
                <div className="btn submit-song" onClick={this.checkBeforeSubmit.bind(this)}>{gettext('Submit song')}</div>
              </form>
            </div>
            <div className="col-xxl-4 offset-xxl-1  col-lg-10 col-16 song-detail">
              <div className="about-song-container">
                <div className="title-container">
                  <h2 className="title">{this.state.title||gettext("Song title")}</h2>
                </div>
                <div className="subtitle-container">
                  <ul className="artists list-inline">
                      <li className="list-inline-item">{gettext("Melody author")}: {this.state.music||gettext("Unknown")}</li>
                      <li className="list-inline-item">{gettext("Lyrics author")}: {this.state.lyrics||gettext("Unknown")}</li>
                      <li className="list-inline-item">{gettext("Performed by")}: {this.state.cover||gettext("Unknown")}</li>
                  </ul>
                </div>
              </div>
              
                { this.state.capo > 0 ?
                  <div className="capo">{gettext('Capo on fret ')}{this.state.capo}</div>
                  : ""
                }
                {this.renderChordifiedSong()}
            </div>
          </div>
        </div>
      }
      </>
    )
  }
}

SubmitASongContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    songSubmition: {},
    music: '',
    lyrics: '',
    cover: '',
    title: '',
    capo: 0
  }

  f.songSubmition = (state.songSubmition === undefined) ? [] : state.songSubmition
  return f;
}

export default connect(mapStateToProps)(SubmitASongContainer);