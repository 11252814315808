export const FONT_SIZES = ['smaller', 'small', 'normal', 'big', 'bigger']
export const DEFAULT_INSTRUMENT = 1

// export const SONGBOOK_PDF_STEPS_TEXT = ['pdf_type', 'page_setup', 'finish_pdf']
// export const SONGBOOK_PDF_STEPS_CHORD = ['pdf_type', 'chord_config', 'page_setup', 'finish_pdf']
export const SONGBOOK_PDF_STEPS = {
  'TEXT' : ['pdf_type', 'page_setup', 'finish_pdf'],
  'CHORD' : ['pdf_type', 'chord_config', 'page_setup', 'finish_pdf']
}

export const SUBSCRIPTION_PDF_STEPS = ['payment_info', 'payment_done']

// export const SONGBOOK_PDF_STEPS_CHORD = ['pdf_type', 'chord_config', 'page_setup', 'finish_pdf']

export const SESSION_STORAGE_NAME = 'guitarparty'

export const ANALYTICS_ID = 'UA-4038942-1';

export const RECAPTCHA_KEY = '6LdGjtcSAAAAALaEI21Y0t762FEUyNu2s7wylqVO'

export const AVAILABLE_LANGUAGES = {
  af: gettext('Afrikaans'),
  sq: gettext('Albanian'),
  ar: gettext('Arabic'),
  be: gettext('Belarusian'),
  bg: gettext('Bulgarian'),
  ca: gettext('Catalan'),
  zh: gettext('Chinese'),
  'zh-CN': gettext('Chinese Simplified'),
  'zn-TW': gettext('Chinese Traditional'),
  hr: gettext('Croatian'),
  cs: gettext('Czech'),
  da: gettext('Danish'),
  nl: gettext('Dutch'),
  en: gettext('English'),
  et: gettext('Estonian'),
  fi: gettext('Finnish'),
  fr: gettext('French'),
  gl: gettext('Galician'),
  de: gettext('German'),
  el: gettext('Greek'),
  ht: gettext('Haitian Creole'),
  iw: gettext('Hebrew'),
  hi: gettext('Hindi'),
  hu: gettext('Hungarian'),
  is: gettext('Icelandic'),
  id: gettext('Indonesian'),
  ga: gettext('Irish'),
  it: gettext('Italian'),
  ja: gettext('Japanese'),
  ko: gettext('Korean'),
  lv: gettext('Latvian'),
  lt: gettext('Lithuanian'),
  mk: gettext('Macedonian'),
  ms: gettext('Malay'),
  mt: gettext('Maltese'),
  no: gettext('Norwegian'),
  fa: gettext('Persian'),
  po: gettext('Polish'),
  pt: gettext('Portuguese'),
  'pt-PT': gettext('Portuguese Portugal'),
  ro: gettext('Romanian'),
  ru: gettext('Russian'),
  sr: gettext('Serbian'),
  sk: gettext('Slovak'),
  sl: gettext('Slovenian'),
  es: gettext('Spanish'),
  sw: gettext('Swahili'),
  sv: gettext('Swedish'),
  tl: gettext('Tagalog'),
  th: gettext('Thai'),
  tr: gettext('Turkish'),
  uk: gettext('Ukrainian'),
  vi: gettext('Vietnamese'),
  cy: gettext('Welsh'),
  yi: gettext('Yiddish'),
}