import { withModal } from '../modal'

import React from 'react';
import Modal from 'react-modal'
import { SvgGuitarPartyLogo, SvgCheckMark } from '../svg';
import GuitarpartyModal from '../modal'
import { openModal, closeModal } from '../../api/modals/actions'
import { fetchUser, userUpdateInfo } from '../../api/user/actions'
import { validateEmail, Spinner } from "../../utils";

import { store } from '../../store'
import { connectWithStore, stepIndicator } from '../../utils'
import ModalSubscription from '../modal-subscription';
import Cookies from 'js-cookie'



// Disable button & show spinner

class ModalAddEmail extends React.Component {
    constructor() {
        super();

        this.state = {
          email: '',
          valid: true,
          fetching: false
        }

        this.closeModal = this.closeModal.bind(this)
        this.submitEmail = this.submitEmail.bind(this)
        this.onChange = this.onChange.bind(this)
    }

    closeModal(){
      Cookies.set('has_email', false, { expires: 1 });  // Expires in 1 day
      this.props.dispatch(closeModal("modal-add-email"))
    }

    onChange(e) {
      const {id, value} = e.target;
      this.setState({
          [id]: value,
          valid: true
      })
    }

    submitEmail(){
      let valid = validateEmail(this.state.email)
      this.setState({ valid: valid })
      if (valid){
        this.setState({ fetching: true })
        Promise.resolve(this.props.dispatch(userUpdateInfo('email', this.state.email)))
        .then(() => {
          this.setState({ fetching: false })
          this.props.dispatch(closeModal("modal-add-email"))
        })
      }
    }

    render() {
        let { user } = this.props;
        return (
            <GuitarpartyModal extraClasses="modal-add-email" modalKey="modal-add-email"  {...this.props}>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.closeModal()}>
                    <span></span>
                    <span></span>
                </button>
                <div className="d-flex">
                    <div className="image-container">
                        <SvgGuitarPartyLogo />
                        <img src={window.IMAGES.paywall} />
                    </div>
                    <div className="text-container">
                        <h2>{gettext('Oh no!')}</h2>
                        <p> Looks like there is no email set for this account!</p> 
                        <p> Don't worry, you can just add it below:</p>
                        <div className="button-container d-flex flex-column justify-content-center align-items-center">
                            <input type="email" id="email" value={this.state.email} onChange={this.onChange}></input>
                            {!this.state.valid ?
                              <span className="alert alert-danger">Invalid email</span> : <></>
                            }
                            <button className="modal-opener btn button-modal-subscription" disabled={this.state.fetching} onClick={() => this.submitEmail()}>{gettext("Add email")}</button>
                            { this.state.fetching && 
                              <Spinner />
                            }
                        </div>
                    </div>
                </div>
            </GuitarpartyModal>
        )
    }
}



// export default withModal(ModalSongbook);

function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user

    return f;
}

export default connectWithStore(store, ModalAddEmail, mapStateToProps);