import React from 'react';
import GuitarpartyModal from '../modal'
import { closeModal } from '../../api/modals/actions'
import { store } from '../../store'
import { connectWithStore, Spinner } from '../../utils'
import { FONT_SIZES } from '../../constants'


class ModalChordChooser extends React.Component {
    constructor() {
        super();

        this.updateChordVar = this.updateChordVar.bind(this)
    }

    updateChordVar(songId, chordId, name){
        this.props.updateChordVariations(songId, chordId, name)
    }

    isSelectedChord(chords, chordId){
        let selectedChord = false
        if (!chords.isChordPrefFetching && chords.chordPref && chords.chordPref.some(e => e.chord === chordId)) {
            selectedChord = true
        }
        return selectedChord;
    }
    
    render() {
        const {chords, instrument, chordName, chordColor, song, user} = this.props
        return (
            <GuitarpartyModal extraClasses="modal-chordchooser" modalKey={`modal-chordchooser-${chordName}`} {...this.props}>
                <div className="text-container">
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal(`modal-chordchooser-${chordName}`))}>
                        <span></span>
                        <span></span>
                    </button>
                    <h1 className="title text-center">{gettext('Hér getur þú sérsniðið hljómastillingarnar þínar')}</h1>
                    <p className="text-center">Smelltu á einhvern af hljómunum hér að neðan og við geymum stillinguna fyrir þetta lag.</p>
                    {!chords.isFetching && chords.data && chords.data.count === 0 && <div>Engar aðrar fingrasetningar í boði</div>}
                    <div className="row justify-content-center">
                    {!chords.isFetching && chords.data ? <div className={`chord-container chord-color-${chordColor} ${this.props.user && this.props.user.hasSubscription && "user-pref d-flex justify-content-between flex-wrap"}`} >{chords.data.results.map((chord)=>{
                        return(<div onClick={() => this.updateChordVar(song.data.id, chord.id, chord.name)} className={`svg-chord-container ${instrument} ${FONT_SIZES[this.props.fontSize]} ${this.isSelectedChord(chords, chord.id) && 'selected-chord'}`}>
                        <text id="title">{chord.name}</text>
                        <div className="inner-container" dangerouslySetInnerHTML={{__html: chord.svg_code}} />
                      </div>)})}</div>: <Spinner/>}
                    </div>
                </div>
            </GuitarpartyModal>
        )
    }
}



// export default withModal(ModalSongbook);
ModalChordChooser.defaultProps = {
    fontSize: 2,
    instrument: ''
}

function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
        song: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user
    f.song = (state.song === undefined) ? [] : state.song

    return f;
}

export default connectWithStore(store, ModalChordChooser, mapStateToProps);