import jwtDecode from 'jwt-decode';
import TokenStorage from '../../storages/token-storage'

import { subscriptionTypes, subscribe } from './actions'


const _initalState = {
    isFetching: false,
    error: null,
    data: {
        customer: null,
        subscription: null,
    },
    tempToken: null,
    tokenStatus: 'initial'
}

export function subscription(state=_initalState, action) {
    switch (action.type) {


        case subscriptionTypes.REQUEST:
            state = {
                ...state,
                isFetching: true,
                error: null
            }
            return state

        case subscriptionTypes.ERROR_TOKEN_STATUS:
        case subscriptionTypes.ERROR_TEMP_TOKEN:
        case subscriptionTypes.ERROR:
            state = {
                ...state,
                isFetching: false,
                error: action.data.message
            }
            return state
        case subscriptionTypes.RECEIVE:
            state = {
                ...state,
                error: null,
                isFetching: false, 
                data: action.data.response
            }
            return state

        case subscriptionTypes.RECEIVE_TEMP_TOKEN:
            state = {
                ...state,
                error: null,
                isFetching: false, 
                tempToken: action.data.token,
                cardVerificationUrl: action.data.card_verification_url
            }
            return state

        case subscriptionTypes.RECEIVE_TOKEN_STATUS:
            state = {
                ...state,
                tokenStatus: action.data.status
            }
            return state
    
        default:
            return state
    }
}