import React from "react";
import { SvgCountdownCircle } from '../svg';
import { song } from "../../api/song/reducers";

let trans = [
    gettext("Next song"),
    gettext("Seconds"),
    gettext("Cancel"),
    gettext("Pause"),
    gettext("Restart"),
]

const START_TIME = 5;

class CountdownTimer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            time: {},
            seconds: START_TIME,
            paused: false,
        }
        this.timer = null;

        this.startTimer = this.startTimer.bind(this)
        this.pauseTimer = this.pauseTimer.bind(this)
        this.restartTimer = this.restartTimer.bind(this)
        this.countDown = this.countDown.bind(this)
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
    }

    componentDidUpdate(){
        if (this.props.active && this.props.song) {
            this.startTimer();
        }
    }

    startTimer() {
        setTimeout(() => {
            if (this.timer === null && this.state.seconds > 0 && !this.state.paused) {
                this.timer = setInterval(this.countDown, 1000);
            }
        }, 1500);
    }

    pauseTimer() {
        clearInterval(this.timer);
        this.timer = null;
        this.setState({paused: true})
    }

    restartTimer() {
        this.setState({paused: false})
        if (this.timer === null && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        let c = 90*2*Math.PI;
        let offset = ((START_TIME - seconds + 1)/START_TIME)*c;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        $('circle#bar').css({strokeDashoffset: offset})

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            this.props.nextSong();
        }
    }

    render() {
        return (
            <div className="countdown-timer">
                <div className="next-song">{gettext('Next song')}:</div>
                <h2 className="next-song-title" onClick={() => this.props.nextSong()}>{this.props.nextSongTitle}</h2>
                <div className="countdown-wrapper">
                    <div className="countdown-item">
                        <SvgCountdownCircle />
                        {this.state.time.s}
                        <span>{gettext('Seconds')}</span>
                    </div>
                </div>
                <div className="countdown-actions">
                    <div className="stop-countdown" onClick={() => this.props.cancelSkipToNextSong()}>{gettext('Cancel')}</div>
                    { this.timer === null || this.timer === undefined ?
                        <div className="pause-countdown" onClick={() => this.restartTimer()}>{gettext('Restart')}</div>
                        :
                        <div className="pause-countdown" onClick={() => this.pauseTimer()}>{gettext('Pause')}</div>
                    }
                </div>
            </div>
        )
    }
}

CountdownTimer.defaultProps = {
}


export default CountdownTimer;