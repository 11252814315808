import React from "react";
import { Switch } from 'react-router'

import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Provider } from 'react-redux'
import ToplistsContainer from './toplists'
import ToplistSingleContainer from './toplist_single'
import { store } from '../../store'


class Root extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div>
            <Switch>
              <Route exact path="/top/" component={props => <ToplistsContainer {...props}/>} />
              <Route exact path="/top/songs/:code/" component={props => <ToplistSingleContainer {...props}/>} />
            </Switch>
          </div>
        </Router>
      </Provider>
    )
  }
}

export default Root