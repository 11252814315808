import React from "react";

const ProgressBar = (props) => {
  const { completed } = props;
  return (
    <div className="progress-bar">
      <div className="progress-bar-inner" style={{width: `${completed}%`}}>
        <span className="progress-bar-completed">{`${completed}%`}</span>
      </div>
    </div>
  );
};

export default ProgressBar;