import React from "react";
import ReactDOM from "react-dom";
import { fetchProfile } from '../../api/profile/actions'
import { fetchInstruments } from '../../api/instrument/actions'
import { fetchUser, fetchContribSongs } from '../../api/user/actions'
import { connect } from 'react-redux'


import Profile from '../../components/profile'
import { fetchSongLang } from "../../api/song-languages/actions";


class ProfileContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    Promise.resolve(this.props.dispatch(fetchProfile()))
    .then(() => {
      this.props.dispatch(fetchUser())
    })
    .then(() => {
      this.props.dispatch(fetchInstruments())
    })
    .then(() => {
      this.props.dispatch(fetchSongLang())
    })
    .then(() => {
      this.props.dispatch(fetchContribSongs())
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.darkMode !== prevProps.darkMode && this.props.darkMode) {
            $('body').addClass("dark-mode");
        }
    }

  render() {
    const { profile, instruments, uiPreference, instrumentPreference, user, songLang, passwordUpdate } = this.props

    return (
        <Profile profile={profile} instruments={instruments.data} uiPreference={uiPreference} instrumentPreference={instrumentPreference} user={user} dispatch={this.props.dispatch} songLang={songLang} passwordUpdate={passwordUpdate}/>
    )
  }
}

ProfileContainer.defaultProps = { } 

function mapStateToProps(state) {
    var f = {
        profile: {},
        instruments: [],
        uiPreference: {},
        instrumentPreference: {},
        user: {},
        songLang: {},
        passwordUpdate: {},
    }

    f.profile = (state.profile === undefined) ? {} : state.profile
    f.instruments = (state.instruments === undefined) ? {} : state.instruments
    f.uiPreference = (state.profile.ui_preferences === undefined) ? {} : state.profile.ui_preferences
    f.instrumentPreference = (state.profile.ui_preferences.instrument_preference === undefined) ? {} : state.profile.ui_preferences.instrument_preference
    f.user = (state.user === undefined) ? {} : state.user
    f.songLang = (state.songLang === undefined) ? {} : state.songLang
    f.passwordUpdate = (state.passwordUpdate === undefined) ? {} : state.passwordUpdate

    return f
}

export default connect(mapStateToProps)(ProfileContainer);