import React from "react";
import ReactDOM from "react-dom";
import { fetchToplists } from '../../api/toplist/actions'

import { connect } from 'react-redux'
import Toplists from '../../components/toplists'
import { Spinner } from "../../utils";


class ToplistsContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.props.dispatch(fetchToplists());
  }



  render() {
      return (!this.props.toplist.isFetching && this.props.toplist.toplists ? <Toplists toplists={this.props.toplist.toplists}/> : <Spinner/>)
  }
}

ToplistsContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    toplist:{},
  }

  f.toplist = (state.toplist === undefined) ? [] : state.toplist
  return f;
}

export default connect(mapStateToProps)(ToplistsContainer);