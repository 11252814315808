import React from 'react';
import $ from 'jquery'
import {openModal} from '../../api/modals/actions'

class DarkmodeSelector extends React.Component {

    constructor(props, context) {
        super(props, context)
    }

    handleOnChange(value){

        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "dark-mode"))
        }
        else {
            if (value === 'light') {
                $('body').removeClass("dark-mode");
            } else {
                $('body').addClass("dark-mode");
            }

            this.props.onChange(value);
        }

    }

    render() {
        return (
            <div className="d-flex justify-content-md-center">
                <button onClick={this.handleOnChange.bind(this, 'light')} className={`btn-mode light-mode ${this.props.darkMode === false ? 'active' : ''}`}></button>
                <button onClick={this.handleOnChange.bind(this, 'dark')} className={`btn-mode dark-mode ${this.props.darkMode === true ? 'active' : ''}`}></button>
            </div>
            
        )
    }
}

DarkmodeSelector.defaultProps = {
    darkMode: false
}

export default DarkmodeSelector;
