import React from 'react';

import { relatedSongsActionTypes } from './actions'
import moment from 'moment'

export function relatedSongs(state = {
    isFetching: false,
    data: {},
}, action) {

    switch (action.type) {
        case relatedSongsActionTypes.REQUEST:
        
            state = { ...state }
            state.isFetching = true
            return state

        case relatedSongsActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.data = action.data

            return state

        default:
            return state
    }

}