import React from 'react';

import { chordTypes, chordPreferenceTypes } from './actions'
import moment from 'moment'


export function chords(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case chordTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case chordTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state['data'] = action.data

            return state

        case chordPreferenceTypes.REQUEST:

            state = { ...state }
            state.isChordPrefFetching = true
            return state

        case chordPreferenceTypes.RECEIVE:

            state = { ...state }

            state.isChordPrefFetching = false
            state['chordPref'] = action.data

            return state

        default:
            return state
    }

}