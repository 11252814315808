import React from 'react';
import ReactDOM from 'react-dom/client';
import UserbarContainer from './userbar'

function initUserbarContainer(element) {
  var options = element.dataset;
  var root = ReactDOM.createRoot(element);
  root.render(React.createElement(UserbarContainer, options));
}

document.addEventListener("DOMContentLoaded", function() {
  var elements = document.querySelectorAll('[data-userbar]');
  elements.forEach(initUserbarContainer);
});

