import React from 'react';
import { updatePassword } from '../../api/password/actions';

class PasswordUpdate extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            isEditing: false,
            oldPassword: '',
            newPassword: '',
        }

        this.onChange = this.onChange.bind(this);
        this.updatePassword =  this.updatePassword.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (this.props.passwordUpdate.status !== prevProps.passwordUpdate.status) {
            if(this.props.passwordUpdate.status === "Password updated") {
                this.setState({
                    updateStatus: this.props.passwordUpdate.status,
                    oldPassword: '',
                    newPassword: '',
                })
            } else {
                this.setState({
                    updateStatus: this.props.passwordUpdate.status
                })
            }
        }
    }

    onChange(e) {
        const {id, value} = e.target;
        this.setState({
            [id]: value
        })
    }

    updatePassword(){
        this.props.updatePassword(this.state.oldPassword, this.state.newPassword)
    }

    render() {
        return (
            <>
                <div>
                    <label>{gettext("Old password")}:</label> 
                    <input type="password" id="oldPassword" value={this.state.oldPassword} onChange={this.onChange} />
                </div>
                <div>
                    <label>{gettext("New password")}:</label> 
                    <input type="password" id="newPassword" value={this.state.newPassword} onChange={this.onChange} />
                </div>
                <button onClick={this.updatePassword}>{gettext("Update password")}</button>
                {this.state.updateStatus && <div>{this.state.updateStatus}</div>}
            </>
        )
    }
}

export default PasswordUpdate;