import React from 'react';

import { searchActionTypes } from './actions'
import moment from 'moment'


export function search(state = {
    isFetching: false,
    results: [],
    touched: false,
}, action) {

    switch (action.type) {
        case searchActionTypes.REQUEST:
            
            state = { ...state }
            state.isFetching = true
            state.touched = true
            return state

        case searchActionTypes.CLEAR:
            state = {...state}
            state.touched = false,
            state.results = []
            return state

        case searchActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state.results = action.data.sort((a, b) => { 
                return +b._score - a._score;
            })
            state.query = action.query

            return state

        default:
            return state
    }

}