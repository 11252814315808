
import React from 'react';
import $ from 'jquery'
import { DEFAULT_INSTRUMENT } from '../../constants'

class InstrumentSelector extends React.Component {

    constructor(props, context) {
        super(props, context)
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if(this.props.darkMode !== prevProps.darkMode && this.props.darkMode) {
    //         $('body').addClass("dark-mode");
    //     }
    // }


    render() {
        let { instruments, instrumentPreference } = this.props
        return (
            <React.Fragment>
                <div className="form-container">
                    { instruments.map((i) => {
                        return (<div key={i.id} className="form-check">
                            <input className="form-check-input" onClick={this.props.onChange.bind(this)} type="radio" name="instrument" id={`instrument--${i.id}`} value={i.id} checked={i.id === instrumentPreference ? true : false} />
                            <label className="form-check-label" htmlFor={`instrument--${i.id}`}>{i.name} ({i.tuning})</label>
                        </div>)
                    })}
                </div>
            </React.Fragment>
        )
    }
}

InstrumentSelector.defaultProps = {
    instruments: [],
    instrumentPreference: DEFAULT_INSTRUMENT,
}

export default InstrumentSelector;


