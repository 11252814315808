import React from 'react';
import { connectWithStore } from '../../utils'
import { store } from '../../store'
import {SvgMinusBlack, SvgPlusBlack} from '../svg'

import {openModal} from '../../api/modals/actions'

export const KEYS_MAJOR = ["C", "C#", "D", "Eb", "E", "F", "F#", "G", "Ab", "A", "Bb", "B"]
export const KEYS_MINOR = ["Cm", "C#m", "Dm", "Ebm", "Em", "Fm", "F#m", "Gm", "Abm", "Am", "Bbm", "Bm"]

const trans = [
    gettext('Original key'),
    gettext("Key"),
]

export const calculateDistance = (key1, key2, capoFret) => {
    let keys = KEYS_MAJOR
    if (key1[key1.length-1] === "m") {
        keys = KEYS_MINOR
    }
    let d1 = keys.indexOf(key1)
    if (capoFret) {
        d1 = d1 + capoFret
    }
    let d2 = keys.indexOf(key2)
    let direction = (d2 - d1) % 12
    return direction
}
export const calculateCapoPositionForOriginalKey = (originalKey, playableKey, capoFret) => {
    let distance = calculateDistance(originalKey, playableKey, capoFret)
    if (distance < 0) {
        return Math.abs(distance)
    } else if (distance === 0) {
        return 0
    }
    else {
        return 12 - distance
    }
}

class Transpose extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            keys: [],
            subMenuOpen: false,
        }

        this.wrapperRef = React.createRef();
        this.btnRef = React.createRef();
        this.handleClickOutsideSubMenu = this.handleClickOutsideSubMenu.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideSubMenu);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currentKey !== this.props.currentKey || this.props.currentKey && this.state.keys.length === 0) {
            if (this.props.currentKey[this.props.currentKey.length-1] === "m") {
                this.setState({keys: KEYS_MINOR})
            } else {
                this.setState({keys: KEYS_MAJOR})
            }
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideSubMenu);
    }

    handleClickOutsideSubMenu(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target) && !this.btnRef.current.contains(event.target)) {
            this.setState({
                subMenuOpen: false,
            })
        }
    }

    transpose_up(id, currentKey){
        let pos = this.state.keys.indexOf(currentKey);
        if(pos == this.state.keys.lenght-1){
            pos = 0;
        }else{
            pos = pos + 1;
        }
        this.props.setKey(id, this.state.keys[pos], this.props.crId)
    }

    transpose_down(id, currentKey){
        let pos = this.state.keys.indexOf(currentKey);
        if(pos == 0){
            pos = this.state.keys.length-1;
        }else{
            pos = pos - 1;
        }
        this.props.setKey(id, this.state.keys[pos], this.props.crId)
    }

    transpose(id, key){
        this.props.setKey(id, key, this.props.crId)
        this.setState({subMenuOpen:!this.state.subMenuOpen})
    }

    toggleSubmenu(){
        if (!this.props.user.hasSubscription) {
            this.props.dispatch(openModal("modal-paywall", "transpose"))
        }
        else {
            this.setState({subMenuOpen:!this.state.subMenuOpen})
        }
    }

    render() {
        const { currentKey, originalKey, id, crId } = this.props
        let btnClasses = "btn btn-icon btn-tones-sub"
        if (currentKey !== originalKey) {
            btnClasses = `${btnClasses} transposed`
        }
        return (
            <>  
            {this.props.type == 'single-song' ?            
                <button ref={this.btnRef} className={btnClasses} onClick={() => this.toggleSubmenu()}>
                    <div className="svg-container">
                        <div className="transposer">
                            <div className="currentKey">{currentKey}</div>
                        </div>
                    </div>
                    <p className="d-none d-md-block">{gettext("Key")}</p>
                </button> : <button ref={this.btnRef} className={btnClasses} onClick={() => this.toggleSubmenu()}>{currentKey} </button>

            }
                <div ref={this.wrapperRef} className={`tones-sub ${this.state.subMenuOpen === true ? 'open' : ''}`}>
                    {this.state.keys.map((key)=>
                        <div key={key} className="col-4 p-0 my-1"><button title={key===originalKey?gettext('Original key'):''}className={`btn btn-outline btn-tones ${key===currentKey?'active':''} ${key===originalKey?'original':''}`} onClick={() => this.transpose(id, key)}>{key}</button></div>
                    )}
                </div>
            </>
        )
    }
}

Transpose.defaultProps = {
    currentKey: null,
    originalKey: null,
    heading: null,
}


export default Transpose;