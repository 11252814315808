import { withModal } from '../modal'

import React from 'react';
import Modal from 'react-modal'
import LanguageSelector from '../language-selector';
import StepRenderer from '../step-renderer';
import { setStep, initStepper, stepRenderTypes } from '../../api/step-renderer/actions'
import { closeModal } from '../../api/modals/actions'
import { store } from '../../store'
import { connectWithStore, stepIndicator } from '../../utils'
import { SvgArrowLeftGray } from '../svg'
import { SUBSCRIPTION_PDF_STEPS } from '../../constants';
import GuitarpartyModal from '../modal'
import ModalLogin from '../modal-login';
import { fetchPlans } from '../../api/plans/actions';


class ModalSubscription extends React.Component {
    constructor() {
        super();

        this.state = {
            forgotPasswordOpen: false,
        }

        this.onLogin = this.onLogin.bind(this)
        this.email = React.createRef();
        this.password = React.createRef();
    }

    onLogin(e) {
        e.preventDefault()
        this.props.onLogin(this.email.current.value, this.password.current.value)
    }


    componentDidMount() {
        this.props.dispatch(initStepper(SUBSCRIPTION_PDF_STEPS))

        this.props.dispatch(fetchPlans())

        animateLabels();
        slideLabelsUp();

    }

    componentDidUpdate(prevProps, prevState) {
        animateLabels();
        slideLabelsUp();
    }


    render() {

        let { stepper, index } = this.props.stepRenderer

        return (
            <>
                {stepper && 
                    <GuitarpartyModal extraClasses="modal-subscription" modalKey="modal-subscription" {...this.props}>
                        <div className="nav">
                            <LanguageSelector />
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal("modal-subscription"))}>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                        <StepRenderer context="buy-subscription" index={index} stepper={stepper} user={this.props.user} dispatch={this.props.dispatch} subscription={this.props.subscription} stepIndicator={stepIndicator(this.props.stepRenderer)} plans={this.props.plans}></StepRenderer>
                    </GuitarpartyModal>   
                }
            </>
        );
    }
}


function mapStateToProps(state) {
    var f = {
        dispatch: {},
        stepRenderer: {},
        subscription: {},
        user: {},
        plans: {},
    }
    f.dispatch = (state.dispatch === undefined) ? [] : state.dispatch
    f.stepRenderer = (state.stepRenderer === undefined) ? [] : state.stepRenderer
    f.subscription = (state.subscription === undefined) ? [] : state.subscription
    f.user = (state.user === undefined) ? [] : state.user
    f.plans = (state.plans === undefined) ? [] : state.plans
    return f;
}

export default connectWithStore(store, ModalSubscription, mapStateToProps);