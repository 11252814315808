import React from 'react'
import ReactDOM from 'react-dom/client'
import SubmitASongContainer from './submit-a-song'
import { store } from '../../store'
import { Provider } from 'react-redux'

function Wrapper(props) {
  return (
      <Provider store={store}>
          <SubmitASongContainer {...props}/>
      </Provider>
  )
}

function initSubmitASongContainer(element) {
var options = element.dataset;
var root = ReactDOM.createRoot(element);
root.render(React.createElement(Wrapper, options));
}

document.addEventListener("DOMContentLoaded", function() {
var elements = document.querySelectorAll('[data-submit-song-container]');
elements.forEach(initSubmitASongContainer);
});

