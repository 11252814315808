import React from 'react';

import { submitWishItemTypes, wishlistTypes, userWishlistTypes, upvoteItemTypes } from './actions'
import moment from 'moment'


export function wishlist(state = {
    isFetching: false,
}, action) {

    switch (action.type) {
        case submitWishItemTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case submitWishItemTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            state['status'] = action.data

            return state

        case wishlistTypes.REQUEST:

            state = { ...state }
            state.isFetchingWishlist = true
            return state

        case wishlistTypes.RECEIVE:

            state = { ...state }

            state.isFetchingWishlist = false
            state['wishlist'] = action.data.results

            return state

        case userWishlistTypes.REQUEST:

            state = { ...state }
            state.isFetchingWishlist = true
            return state

        case userWishlistTypes.RECEIVE:

            state = { ...state }

            state.isFetchingWishlist = false
            state['userWishlist'] = action.data

            return state

        case upvoteItemTypes.REQUEST:

            state = { ...state }
            state.isFetchingWishlist = true
            return state

        case upvoteItemTypes.RECEIVE:

            state = { ...state }

            state.isFetchingWishlist = false
            state['status'] = action.data

            return state

        default:
            return state
    }

}