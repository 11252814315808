import React from "react";
import ReactDOM from "react-dom";
import { connect } from 'react-redux'
import SongList from '../../components/song-list'


class FavouritesContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { favourites } = this.props
    return (
      <>
      {favourites.data.results !== undefined && favourites.data.results.length > 0 ? 
        <SongList list={favourites.data.results} allFavourites={true} showExcerpt={false} showFilterInfo={false}/>
      :
        <div></div>
      }
      </>
    )
  }
}

FavouritesContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    favourites: {},
  }
  f.favourites = (state.favourites === undefined) ? [] : state.favourites
  return f;
}

export default connect(mapStateToProps)(FavouritesContainer);