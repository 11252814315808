import React from 'react';
import { closeModal, openModal, setScreen } from '../../api/modals/actions';
import { userDjangoLogin, userDjangoRegister, userDjangoResetPassword, userDjangoSetNewPassword } from '../../api/user/actions';
import { store } from '../../store';
import { connectWithStore } from '../../utils';
import LanguageSelector from '../language-selector';
import GuitarpartyModal from '../modal';
import { SvgFbLogo, SvgGuitarPartyLogo } from '../svg';


let trans = [
    gettext('Email or username'),
    gettext('Account has been verified, please log in'),
    gettext("Close"),
    gettext('Log in'),
    gettext('Signup'),
    gettext('Username'),
    gettext('Email'),
    gettext('Password'),
    gettext('Password (again)'),
    gettext('I have read and agree to the <a href="/terms/">Terms of Service</a>'),
    gettext('Register'),
    gettext('Signup complete'),
    gettext("Your account has been created! Check your email to activate your account and start playing!"),
    gettext('Back to login'),
    gettext('Forgot password?'),
    gettext('Login with Facebook'),
    gettext('Login unsuccessful.'),
    gettext("Your password has been changed! Please login to start playing!"),
    gettext('Password reset complete'),
    gettext('New password (repeat)'),
    gettext('Reset my password'),
    gettext('Reset password'),
    gettext("A password reset request has been sent to the email you have registered with us"),
    gettext('Password and confirmation password do not match'),
]

class ModalLogin extends React.Component {
    constructor() {
        super();


        this.key = Math.round(Math.random() * 100000)
        this.email = React.createRef();
        this.password = React.createRef();
        this.reg_email = React.createRef();
        this.reg_username = React.createRef();
        this.reg_pass1 = React.createRef();
        this.reg_pass2 = React.createRef();
        this.reg_accept = React.createRef();
        this.reset_user = React.createRef();
        this.reset_pass1 = React.createRef();
        this.reset_pass2 = React.createRef();

        this.registerDisabled = this.registerDisabled.bind(this)
        this.onChangeRegister = this.onChangeRegister.bind(this)
        this.backtologin = this.backtologin.bind(this)
        this.onRegister = this.onRegister.bind(this)
        this.onReset = this.onReset.bind(this)
        this.onLogin = this.onLogin.bind(this)
        this.ForgotPasswordOpen = this.ForgotPasswordOpen.bind(this)
        this.RegisterOpen = this.RegisterOpen.bind(this)
        this.toggleVerifyMessage = this.toggleVerifyMessage.bind(this)
        this.getParamValue = this.getParamValue.bind(this)
        this.resetPasswordDisabled = this.resetPasswordDisabled.bind(this)
        this.resetPassword = this.resetPassword.bind(this)
    }

    onLogin(e) {
        e.preventDefault()
        this.props.dispatch(userDjangoLogin(this.email.current.value, this.password.current.value))
    }

    onRegister(e) {
        e.preventDefault()
        this.props.dispatch(userDjangoRegister(this.reg_username.current.value, this.reg_email.current.value, this.reg_pass1.current.value, this.reg_pass2.current.value))
    }

    onReset(e) {
        e.preventDefault()
        this.props.dispatch(userDjangoResetPassword(this.reset_user.current.value))
    }

    resetPassword(e) {
        e.preventDefault()
        let user_id = this.getParamValue("user_id")
        let timestamp = this.getParamValue("timestamp")
        let signature = this.getParamValue("signature")
        this.props.dispatch(userDjangoSetNewPassword(user_id, timestamp, signature, this.reset_pass1.current.value))
    }

    backtologin() {
        this.props.dispatch(setScreen("login"))
    }

    ForgotPasswordOpen() {
        this.props.dispatch(setScreen("forgot-password"))
    }

    RegisterOpen() {
        this.props.dispatch(setScreen("register"))
    }

    registerDisabled() {
        let disabled = true
        if (this.reg_username.current) {
            if (this.reg_username.current.value !== "" && this.reg_pass1.current.value !== "" && this.reg_pass1.current.value === this.reg_pass2.current.value && this.reg_accept.current.checked) {
                disabled = false
            }
        }
        return disabled
    }

    resetPasswordDisabled() {
        let disabled = true
        if (this.reset_pass1.current) {
            if (this.reset_pass1.current.value !== "" && this.reset_pass1.current.value === this.reset_pass2.current.value) {
                disabled = false
            }
        }
        return disabled
    }

    toggleVerifyMessage() {
        if (window.location.pathname == "/" && window.location.search.indexOf('verify=true') >= 0) {
            return <p>{gettext('Account has been verified, please log in')}</p>
        } else {
            return <></>
        }
    }

    getParamValue(val) {
        const params = new Map(location.search.slice(1).split('&').map(kv => kv.split('=')))
        return params.get(val)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.user.isAuthenticated != prevProps.user.isAuthenticated && this.props.user.isAuthenticated) {
            this.props.dispatch(closeModal("modal-login"))
        }
        animateLabels()
        slideLabelsUp()
    }

    componentDidMount() {
        if (window.location.pathname == "/" && window.location.search.indexOf('reset=true') >= 0) {
            this.props.dispatch(setScreen("reset-password"))
            this.props.dispatch(openModal("modal-login"))
        }
        animateLabels()
        slideLabelsUp()
    }

    onChangeRegister(e) {
        this.setState({
            [e.target.name]: e.target.value
        })
    }


    render() {
        if (this.props.modals["modal-login-screen"] === "register") {
            return (
                <GuitarpartyModal extraClasses="modal-login signup-container" modalKey="modal-login" {...this.props}>
                    <div className="nav signup">
                        <LanguageSelector />
                        <button type="button" className="close" data-dismiss="modal" aria-label={gettext("Close")} onClick={() => this.props.dispatch(closeModal("modal-login"))}>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <form onSubmit={this.onRegister} className="login signup">
                        <div className="modal-body">
                            <a className="logo-container" href="/">
                                <SvgGuitarPartyLogo />
                            </a>
                            <div className="button-row d-flex">
                                <button type="button" className="btn sign-in-btn active" onClick={this.backtologin}><h5 className="modal-title sign-in" id="exampleModalLabel">{gettext('Log in')}</h5></button>
                                <button type="button" className="btn" onClick={this.RegisterOpen}><h5 className="sign-up">{gettext('Signup')}</h5></button>
                            </div>
                            {/* <div className="title-row">
                                <h5 className="modal-title" id="exampleModalLabel">{gettext('Create a Guitarparty user')}</h5>
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="username-register">{gettext('Username')}</label>
                                <input placeholder="" ref={this.reg_username} type="text" className="form-control" id="username-register" name="username" onChange={this.onChangeRegister} />
                                {this.props.user.registrationError && this.props.user.registrationError.username ?
                                    <span className="alert alert-danger">{this.props.user.registrationError.username}</span> : <></>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="email-register">{gettext('Email')}</label>
                                <input placeholder="" ref={this.reg_email} type="email" className="form-control" id="email-register" name="email" onChange={this.onChangeRegister} />
                                {this.props.user.registrationError && this.props.user.registrationError.email ?
                                    <div className="alert alert-danger">{this.props.user.registrationError.email}</div> : <></>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="password-register">{gettext('Password')}</label>
                                <input placeholder="" ref={this.reg_pass1} type="password" className="form-control" id="password-register" name="password" onChange={this.onChangeRegister} />
                                {this.props.user.registrationError && this.props.user.registrationError.password ?
                                    <div className="alert alert-danger">{this.props.user.registrationError.password}</div> : <></>
                                }
                            </div>
                            <div className="form-group">
                                <label htmlFor="password2-register">{gettext('Password (again)')}</label>
                                <input placeholder="" ref={this.reg_pass2} type="password" className="form-control" id="password2-register" name="password2" onBlur={this.onChangeRegister} />
                                {this.reg_username.current && this.reg_pass1.current.value !== this.reg_pass2.current.value && this.reg_pass2.current.value !== "" && <div className="alert alert-danger">{gettext('Password and confirmation password do not match')}</div>}
                            </div>
                            <div className="form-group terms">
                                <label className="events" htmlFor="accept-register" dangerouslySetInnerHTML={{ __html: gettext('I have read and agree to the <a href="/terms/">Terms of Service</a>') }} />
                                <input placeholder="" ref={this.reg_accept} type="checkbox" className="form-control" id="accept-register" name="accept" onChange={this.onChangeRegister} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn" disabled={this.registerDisabled()}>{gettext('Register')}</button>
                        </div>
                    </form>
                </GuitarpartyModal>
            )
        } else if (this.props.modals["modal-login-screen"] === "signup-complete") {
            return (
                <GuitarpartyModal extraClasses="modal-login" modalKey="modal-login" {...this.props}>
                    <div className="nav signup signup-complete">
                        <LanguageSelector />
                        <button type="button" className="close" data-dismiss="modal" aria-label={gettext("Close")} onClick={() => this.props.dispatch(closeModal("modal-login"))}>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <div className="login signup-complete">
                        <div className="modal-body">
                            <a className="logo-container" href="/">
                                <SvgGuitarPartyLogo />
                            </a>
                            <h5 className="modal-title" id="exampleModalLabel">{gettext('Signup complete')}</h5>
                            <p>{gettext("Your account has been created! Check your email to activate your account and start playing!")}</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn" onClick={() => this.props.dispatch(closeModal("modal-login"))}>{gettext('Close')}</button>
                            <button type="button" className="btn" onClick={this.backtologin}>{gettext('Back to login')}</button>
                        </div>
                    </div>
                </GuitarpartyModal>
            )
        } else if (this.props.modals["modal-login-screen"] === "forgot-password") {
            return (
                <GuitarpartyModal extraClasses="modal-login forgot-pass-container" modalKey="modal-login" {...this.props}>
                    <div className="nav login forgot-pass">
                        <LanguageSelector />
                        <button type="button" className="close" data-dismiss="modal" aria-label={gettext("Close")} onClick={() => this.props.dispatch(closeModal("modal-login"))}>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <form onSubmit={this.onReset} className="login forgot-pass">
                        <div className="modal-body">
                            <a className="logo-container" href="/">
                                <SvgGuitarPartyLogo />
                            </a>
                            {this.props.user.resetData ?
                                <p className="mt-5">{gettext("A password reset request has been sent to the email you have registered with us")}</p>
                                :
                                <>
                                    <div className="button-row d-flex">
                                        <button type="button" className="btn sign-in-btn " onClick={this.backtologin}><h5 className="modal-title sign-in" id="exampleModalLabel">{gettext('Log in')}</h5></button>
                                        <button type="button" className="btn forgot-pass-btn active" ><h5 className="modal-title forgot-pass" >{gettext('Forgot password?')}</h5></button>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor={`email-forgot-${this.key}`}>{gettext('Email or username')}</label>
                                        <input placeholder="" name="login" ref={this.reset_user} type="text" className="form-control" id={`email-forgot-${this.key}`} />
                                    </div>
                                </>
                            }
                        </div>
                        <div className="modal-footer">
                            {this.props.user.resetData ?
                                <button type="button" className="btn" onClick={() => this.props.dispatch(closeModal("modal-login"))}>{gettext('Close')}</button>
                                :
                                <button type="submit" className="btn">{gettext('Reset my password')}</button>
                            }
                        </div>
                    </form>
                </GuitarpartyModal>
            )
        } else if (this.props.modals["modal-login-screen"] === "reset-password") {
            return (
                <GuitarpartyModal extraClasses="modal-login forgot-pass-container" modalKey="modal-login" {...this.props}>
                    <div className="nav">
                        <LanguageSelector />
                        <button type="button" className="close" data-dismiss="modal" aria-label={gettext("Close")} onClick={() => this.props.dispatch(closeModal("modal-login"))}>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <form onSubmit={this.resetPassword} className="login">
                        <div className="modal-body">
                            <a className="logo-container" href="/">
                                <SvgGuitarPartyLogo />
                            </a>
                            <div className="button-row d-flex">
                                <button type="button" className="btn sign-in-btn " onClick={this.backtologin}><h5 className="modal-title sign-in" id="exampleModalLabel">{gettext('Log in')}</h5></button>
                                <button type="button" className="btn forgot-pass-btn active" ><h5 className="modal-title forgot-pass" >{gettext('Reset password')}</h5></button>
                            </div>
                            <div className="form-group">
                                <label htmlFor={`reset-password-repeat-${this.key}`}>{gettext('New password')}</label>
                                <input placeholder="" name="password" ref={this.reset_pass1} type="password" className="form-control" id={`reset-password-repeat-${this.key}`} onChange={this.onChangeRegister} />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`reset-password2-repeat-${this.key}`}>{gettext('New password (repeat)')}</label>
                                <input placeholder="" name="password-repeat" ref={this.reset_pass2} type="password" className="form-control" id={`reset-password2-repeat-${this.key}`} onBlur={this.onChangeRegister} />
                                { this.reset_pass1.current && this.reset_pass2.current && (this.reset_pass1.current.value !== this.reset_pass2.current.value && this.reset_pass2.current.value !== "") && 
                                    <div className="alert alert-danger">{gettext('Password and confirmation password do not match')}</div>
                                }
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn" disabled={this.resetPasswordDisabled()}>{gettext('Reset my password')}</button>
                        </div>
                    </form>
                </GuitarpartyModal>
            )
        } else if (this.props.modals["modal-login-screen"] === "reset-password-complete") {
            return (
                <GuitarpartyModal extraClasses="modal-login" modalKey="modal-login" {...this.props}>
                    <div className="nav pass-complete">
                        <LanguageSelector />
                        <button type="button" className="close" data-dismiss="modal" aria-label={gettext("Close")} onClick={() => this.props.dispatch(closeModal("modal-login"))}>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <div className="login pass-complete">
                        <div className="modal-body">
                            <a className="logo-container" href="/">
                                <SvgGuitarPartyLogo />
                            </a>
                            <h5 className="modal-title" id="exampleModalLabel">{gettext('Password reset complete')}</h5>
                            {/* <p>{gettext("Your password has been changed! Please login to start playing!")}</p> */}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn" onClick={() => this.props.dispatch(closeModal("modal-login"))}>{gettext('Close')}</button>
                            <button type="button" className="btn" onClick={this.backtologin}>{gettext('Back to login')}</button>
                        </div>
                    </div>
                </GuitarpartyModal>
            )
        } else {
            return (
                <GuitarpartyModal extraClasses="modal-login login-container" modalKey="modal-login" {...this.props}>
                    <div className="nav">
                        <LanguageSelector />
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.dispatch(closeModal("modal-login"))}>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                    <div className="login">
                        <form onSubmit={this.onLogin}>
                        <div className="modal-body">
                            <a className="logo-container" href="/">
                                <SvgGuitarPartyLogo />
                            </a>
                            <div className="button-row d-flex">
                                <button type="button" className="btn sign-in-btn" onClick={this.backtologin}><h5 className="modal-title sign-in" id="exampleModalLabel">{gettext('Log in')}</h5></button>
                                <button type="button" className="btn" onClick={this.RegisterOpen}><h5 className="sign-up">{gettext('Signup')}</h5></button>
                            </div>
                            {this.toggleVerifyMessage()}

                            {this.props.user.failed_auth &&
                                <div className="alert alert-danger" role="alert">
                                    <strong>{gettext('Error!')}</strong> {this.props.user.failed_auth_reason}
                                </div>
                            }
                            {this.props.modals["modal-login-message"] &&
                                <div className="form-group">
                                    <p>{this.props.modals["modal-login-message"]}</p>
                                </div>
                            }
                            <div className="form-group">
                                <label htmlFor={`email-login-${this.key}`}>{gettext('Email or username')}</label>
                                <input placeholder="" ref={this.email} type="test" className="form-control" aria-describedby="emailHelp" id={`email-login-${this.key}`} />
                            </div>
                            <div className="form-group">
                                <label htmlFor={`password-login-${this.key}`}>{gettext('Password')}</label>
                                <input placeholder="" ref={this.password} type="password" className="form-control" id={`password-login-${this.key}`} />
                            </div>

                        </div>

                        <div className="modal-footer">
                            <button type="submit" className="btn" onClick={this.onLogin}>
                                {this.props.user.isAuthenticating && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>} 
                                {gettext('Continue')}
                            </button>
                            <div className="fb-login"><SvgFbLogo /><a href={window.FB_LOGIN_URL}>{gettext('Login with Facebook')}</a></div>
                            <button type="button" className="btn forgot-pass" onClick={this.ForgotPasswordOpen}>{gettext('Forgot password?')}</button>
                        </div>
                        </form>
                    </div>
                </GuitarpartyModal>
            );
        }
    }
}



// export default withModal(ModalSongbook);

function mapStateToProps(state) {
    var f = {
        modals: {},
        user: {},
    }

    f.modals = (state.modals === undefined) ? [] : state.modals
    f.user = (state.user === undefined) ? [] : state.user

    return f;
}

export default connectWithStore(store, ModalLogin, mapStateToProps);