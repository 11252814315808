import { lessonActionTypes } from './actions'


let _initialState = {
    isFetching: false,
    data: {
        results: [],
        // single_lesson : {},
    },

}


export function lessons(state = _initialState, action) {

    switch (action.type) {
        case lessonActionTypes.REQUEST:

            state = { ...state }
            state.isFetching = true

            return state

        case lessonActionTypes.RECEIVE:

            state = { ...state }

            state.isFetching = false
            
            // if (action.lessonId) {
            //     state.data.single_lesson = action.data
            // } else {
            //     state.data.results = action.data.results
            // }
            state.data.results = action.data.results

            return state
    
        default:
            return state
    }

}