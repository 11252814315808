import { createTypes } from 'redux-action-creator';
import { addAuthorizeHeader, getCookie, handleNetworkError, parseJSON } from '../../utils';

export const profileActionTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR', 'SUCCESS', 'SET_KEY',], 'PROFILE');


export const requestProfile = () => {
    return {
        type: profileActionTypes.REQUEST,
    };
};

export const receiveProfile = (response) => {
    return {
        type: profileActionTypes.RECEIVE,
        data: response,
    };
};

export const receiveProfileError = () => {
    return {
        type: profileActionTypes.ERROR,
    };
};

export const _saveProfile = (key, value) => {
    return {
        type: profileActionTypes.SET_KEY,
        key: key,
        value: value,
    };
};

export const saveProfile = (key, value, isAuthenticated) => {
    return dispatch => {
        dispatch(_saveProfile(key, value))
        if (isAuthenticated) {
            dispatch(updateProfile(key, value))
        }
    }
}

export const fetchProfile = () => {
    return dispatch => {

        dispatch(requestProfile());

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        })

        let url = `/api/v3/core/profile/current/`


        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {
            try {
                dispatch(receiveProfile(response));
            } catch (e) {
                dispatch(receiveProfileError(response))
            }
        })
        .catch(handleNetworkError);
    };
};


export const updateProfile = (key, value) => {
    return dispatch => {


        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/profile/update_ui/`

        let data = {}
        data[key] = value

        return fetch(url, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: headers,
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveProfile(response));
            } catch (e) {
                dispatch(receiveProfileError(response))
            }


        })
        .catch(handleNetworkError);
    };
};