import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError } from '../../utils'

export const songLangTypes = createTypes(['REQUEST', 'RECEIVE', 'ERROR'], 'SONG_LANG');


export const requestSongLang = () => {
    return {
        type: songLangTypes.REQUEST,
    };
};

export const receiveSongLang = (response, key) => {
    return {
        type: songLangTypes.RECEIVE,
        data: response,
    };
};

export const receiveSongLangError = () => {
    return {
        type: songLangTypes.ERROR,
    };
};

export const fetchSongLang = () => {
    return dispatch => {

        dispatch(requestSongLang());

        let headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }

        let url = `/api/v3/core/song_lang/`

        return fetch(url, {
            headers: headers
        })
        .then(parseJSON)
        .then(response => {

            try {
                dispatch(receiveSongLang(response));
            } catch (e) {
                dispatch(receiveSongLangError(response))
            }


        })
        .catch(handleNetworkError);
    };
};
