import React from 'react';

import { profileActionTypes } from './actions'


let defaultProfile = {
    chordColor: 'blue',
    fontSize: 2,
    showChords: true,
    showChordCradle: true,
    songLayout: '1col',
    darkMode: false,
    instrument_preference: 1,
    language_preference: "is,en"
}

export function profile(state = {
    isFetching: false,
    ui_preferences: defaultProfile,
}, action) {

    switch (action.type) {
        case profileActionTypes.REQUEST:

            state = { ...state }
            state.isFetching = true
            return state

        case profileActionTypes.SET_KEY:

            var preferences = { ...state.ui_preferences }
            var val = action.value
            if (action.key === 'instrument_preference') {
                val = +action.value
            }
            preferences[action.key] = val

            return {
                ...state,
                ui_preferences: preferences,
            }

        case profileActionTypes.RECEIVE:
            var prefs = Object.assign({}, defaultProfile, action.data.ui_preferences)
            prefs.instrument_preference = action.data.instrument_preference
            prefs.language_preference = action.data.language_preference
            var newState = {
                ...state,
                ui_preferences: prefs,
                isFetching: false
            }

            return newState

        default:
            return state
    }

}