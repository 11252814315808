import React from 'react';
import { connectWithStore } from '../../utils'
import { store } from '../../store'
import { connect } from 'react-redux'
import AddSongToSongbooks from '../song-add-to-songbook'
import { fetchSongbooks, createSongbook } from '../../api/songbooks/actions'

import Favourite from '../favourite';
import Adsense from '../adsense';


class SongList extends React.Component {

  constructor() {
    super()
    this.state = {
      checkedSongs: [],
      favedSongs: []
    }
  }

  componentDidUpdate(prevProps) {

    if ((JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) && this.props.user.isAuthenticated) {
      this.props.dispatch(fetchSongbooks(false))
    }

    // Favorites retrieved
    if (JSON.stringify(prevProps.favourites) !== JSON.stringify(this.props.favourites)) {
      let fav = this.props.favourites.data.results.map(song => song.id)
      this.setState({ favedSongs: fav })
    }
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated) {
      this.props.dispatch(fetchSongbooks(false))
    }
  }


  onChange(id, e) {
    let array = e.currentTarget.checked ?
      this.state.checkedSongs.concat(id) :
      this.state.checkedSongs.filter(function (song) {
        return song != id
      })
    this.setState({ checkedSongs: array });
  }

  clearCheckedSongs(){
    this.setState({
      checkedSongs: []
    })
  }


  render() {
    let { list, search, showExcerpt, showAuthor, type, toplistType } = this.props

    if (search.query) {
      var find = "(" + search.query + ")";
      var re = new RegExp(find, 'gi');
    }

    return (
      
      <>
        <div className="song-list favourites-list">
          {this.props.toplistType !== 'songbooks' &&
          <AddSongToSongbooks
            ids={this.state.checkedSongs}
            multipleSongs={true}
            clearCheckedSongs={this.clearCheckedSongs.bind(this)}
            disabled={!(this.state.checkedSongs.length > 0)}
          />
          }
          {this.props.showFilterInfo && <div className="song-list row justify-content-center filter-info">{this.props.listType == 'toplist' ? gettext('The toplist is filtered by your language preference. You can change your preferences') : gettext('The search result is filtered by your language preference. You can change your preferences')}&nbsp;<a href="/profile">{gettext('here')}</a> </div>}
          { list.map((el, i) => {
            return (
              <>
                { i%5 == 0 && this.props.user && !this.props.user.hasSubscription &&
                  <div className={"song-list row justify-content-center adsense-list"}>
                      <div className="item col-md-15 col-16">
                          <Adsense
                              client="ca-pub-3801256823535192"
                              slot="9497654875"
                              style={{ display: "block" }}
                              format="fluid"
                              layout_key="-h6-3+1f-3d+2z"
                          />
                      </div>
                  </div>
                }
                {this.props.toplistType == 'songbooks' &&
                <div className={"song-list row justify-content-center " + this.props.toplistType}>
                  <div className="item col-md-15 col-16">
                    <a href={`/songs/${el.song.slug}`}><div className="title" dangerouslySetInnerHTML={{ __html: "" + el.song.title.replace(re, '<span>$1</span>')}}></div></a>
                    <div className="chords">{el.song.chordstr}</div>
                  </div>

                </div>}
                {this.props.toplistType != 'songbooks' && 
                  <div className={"song-list row justify-content-center " + el.type}>
                    {type === "Songs" &&
                      <>
                        <div className="item col-md-15 col-16">
                          {el.type !== "Author" && el.type !== "Tag" &&
                            <>
                              {this.props.user.isAuthenticated &&
                                <>
                                  <input id={toplistType ? "item-checkbox-" + toplistType + "-" + i : "item-checkbox-" + i} checked={this.state.checkedSongs.indexOf(el.id) !== -1} type="checkbox" onChange={this.onChange.bind(this, el.id)} />
                                  <label htmlFor={toplistType ? "item-checkbox-" + toplistType + "-" + i : "item-checkbox-" + i}></label>
                                </>
                              }
                              <a href={el.url}><div className="title" dangerouslySetInnerHTML={{ __html: "" + el.title.replace(re, '<span>$1</span>') + " " + (el.author ? "(" + el.author.replace(re, '<span>$1</span>') + ")" : "") }}></div></a>
                              {showAuthor &&
                                <a href={el.url}><div className="author" dangerouslySetInnerHTML={{ __html: "" + el.author.replace(re, '<span>$1</span>') }}></div></a>
                              }
                              {showExcerpt &&
                                <div className="song-excerpt" dangerouslySetInnerHTML={{ __html: "" + (el.songstr !== undefined ? el.songstr.replace(re, '<span>$1</span>') : "") }}></div>
                              }
                              {this.props.user.isAuthenticated &&
                              <>
                                <div className="actions">
                                  <Favourite allFavourites={this.props.allFavourites} favouriteIds={this.state.favedSongs} id={el.id} user={this.props.user} dispatch={this.props.dispatch} />
                                </div>
                              </>
                              }
                            </>
                          }
                          {el.type === "Author" &&
                            <>
                              <a href={el.url}><div className="title" dangerouslySetInnerHTML={{ __html: "" + el.title.replace(re, '<span>$1</span>') + " - " + gettext("authors") + " - " + el.song_count + " " + (el.song_count === 1 ? gettext("song") : gettext("songs")) }}></div></a>
                            </>
                          }
                          {el.type === "Tag" &&
                            <>
                              <a href={el.url}><div className="title" dangerouslySetInnerHTML={{ __html: "" + el.title.replace(re, '<span>$1</span>') + " - " + gettext("tag") + " - " + el.song_count + " " + (el.song_count === 1 ? gettext("song") : gettext("songs")) }}></div></a>
                            </>
                          }
                        </div>
                      </>
                    }
                  </div>
                }
              </>
            )
          })}
        </div>
      </>
    )
  }
}

SongList.defaultProps = {
  showExcerpt: true,
  showImage: false,
  showAuthor: false,
  allFavourites: false,
  type: 'Songs'
}

function mapStateToProps(state) {

  var f = {
    search: {},
    songbooks: {},
    user: {},
    collectionRelation: {},
    favourites: {}

  }
  f.search = (state.search === undefined) ? [] : state.search
  f.songbooks = (state.songbooks === undefined) ? [] : state.songbooks
  f.user = (state.user === undefined) ? [] : state.user
  f.collectionRelation = (state.collectionRelation === undefined) ? [] : state.collectionRelation
  f.favourites = (state.favourites === undefined) ? [] : state.favourites
  return f;
}

export default connectWithStore(store, SongList, mapStateToProps);