import React from "react";
import { connect } from 'react-redux';
import { fetchUserChordPreference } from "../../api/chords/actions";
import { fetchInstruments } from '../../api/instrument/actions';
import { fetchProfile } from '../../api/profile/actions';
import { fetchSong } from '../../api/song/actions';
import { fetchUser } from "../../api/user/actions";
import Song from '../../components/song';


const getParamValue = (val) => {
  const params = new Map(window.location.search.slice(1).split('&').map(kv => kv.split('=')))
  return params.get(val)
}

class SongContainer extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let key = null
    let initialKey = getParamValue('key')


    Promise.resolve(this.props.dispatch(fetchProfile()))
      .then(() => {
        Promise.resolve(this.props.dispatch(fetchUser()))
          .then(() => {
            if (initialKey && this.props.user.hasSubscription) {
              key = initialKey
            }
            this.props.dispatch(fetchInstruments())
            this.props.dispatch(fetchSong(this.props.id, key, this.props.profile.instrument_preference))
            this.props.dispatch(fetchUserChordPreference(this.props.id))
          })
      })
  }

  render() {
    const { song, songbooks, favourites, instruments, profile } = this.props

    return (
      <Song song={song.data} songbooks={songbooks.privateSongbooks} favourites={favourites.data} {...profile.ui_preferences} instrument_preference={profile.ui_preferences.instrument_preference} instruments={instruments.data} isFetching={song.isFetching} user={this.props.user} dispatch={this.props.dispatch} />
    )
  }
}

SongContainer.defaultProps = {
}

function mapStateToProps(state) {

  var f = {
    song: {},
    songbooks: {},
    favourites: {},
    instruments: [],
    profile: {},
    user: {},
  }
  f.song = (state.song === undefined) ? {} : state.song
  f.songbooks = (state.songbooks === undefined) ? [] : state.songbooks
  f.instruments = (state.instruments === undefined) ? {} : state.instruments
  f.profile = (state.profile === undefined) ? {} : state.profile
  f.favourites = (state.favourites === undefined) ? [] : state.favourites
  f.user = (state.user === undefined) ? [] : state.user
  return f;
}

export default connect(mapStateToProps)(SongContainer);