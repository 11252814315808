import { createTypes, actionCreator } from 'redux-action-creator';
import { parseJSON, handleNetworkError, addAuthorizeHeader, getCookie } from '../../utils'
import { fetchSongbooks, fetchSongbookDetail } from '../songbooks/actions'

export const collectionRelationTypes = createTypes(['CREATED', 'DELETED'], 'RELATIONS');

export const addedSongToSongbook = (response) => {
    return {
        type: collectionRelationTypes.CREATED,
        data: response
    }
}

export const addedScrollSpeed = (response) => {
    return {
        type: collectionRelationTypes.RELATIONS,
        data: response
    }
}

export const addChosenSongToSongbook = (songbookId, songId, currentKey) => {
    return dispatch => {

        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'
        })

        let url = `/api/v3/core/collection_relations/`

        let data = {}
        data['songbook'] = songbookId
        data['song'] = songId
        if (currentKey !== undefined) {
            data['key'] = currentKey
        }

        return fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: headers,
        })
            .then(parseJSON)
            .then(response => {
                dispatch(addedSongToSongbook(response));
                dispatch(fetchSongbooks(songbookId));
            })
            .catch(handleNetworkError);
    };
};

export const removedSongFromSongbook = (response, data) => {
    return {
        type: collectionRelationTypes.DELETED,
        status: response,
        data: data,
    }
}

export const removeChosenSongFromSongbook = (crId, songbookId, songbookDetail, key) => {
    return dispatch => {
        let headers = addAuthorizeHeader({
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFToken': getCookie("guitarpartycsrftoken"),
            credentials: 'include'

        })

        let url = `/api/v3/core/songbooks/${songbookId}/song/?cr_id=${crId}`

        if (key) {
            url += `&key=${key}`
        }

        let data = {}
        data['songbook'] = songbookId


        return fetch(url, {
            method: 'DELETE',
            headers: headers,
        })
            .then(parseJSON)
            .then(response => {
                dispatch(removedSongFromSongbook(response, data));
                dispatch(fetchSongbooks(songbookId));
                if(songbookDetail) {
                    dispatch(fetchSongbookDetail(songbookId))
                }
            })
            .catch(handleNetworkError);
    }
};

